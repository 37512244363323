import React from 'react';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { faAdd, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputImagePreview from '../_base/input-image-preview';
import InputCnpj from '../_base/input-cnpj';
import InputTelefone from '../_base/input-telefone';
import CurrencyInput from 'react-currency-input';
import ModalCadastro from '../_base/modal-cadastro';
import FormGroupHeader from '../_base/form-group-header';
import Errors from '../_base/errors';
import Switch from 'react-switch';

import GeneralService from '../../services/general-service';
import RepresentadaService from '../../services/representada-service';

import lib from '../../helpers/lib';

export default function ModalCadastroRepresentada(props) {
  const { showModal, setShowModal, onClose, entity, opcoesCaixaria } = props;

  const formFields = {
    cnpj: {
      key: 'cnpj',
      required: true,
      disabled: entity.representadaId?.length > 0
    },
    razaoSocial: {
      key: 'razaoSocial',
      required: true,
      disabled: entity.representadaId?.length > 0
    },
    nomeFantasia: { key: 'nomeFantasia', required: true },
    ie: { key: 'ie', required: false },
    telefone: { key: 'telefone', required: false },
    email: { key: 'email', required: false },
    valorMinimo: { key: 'valorMinimo', required: false },
    //prazoPagamentos:{ key: "prazoPagamentos", required: false },
    contatoNome: { key: 'contatoNome', required: true },
    contatoTelefone: { key: 'contatoTelefone', required: true },
    contatoEmail: { key: 'contatoEmail', required: true },
    representadaPedidoQuantConfigId: {
      key: 'representadaPedidoQuantConfigId',
      required: true
    },
    ocultarProdutosSemPreco: {
      key: 'ocultarProdutosSemPreco',
      required: false
    }
  };

  const emptyRepresentada = React.useMemo(
    () => ({
      cnpj: '',
      razaoSocial: '',
      nomeFantasia: '',
      email: '',
      notificarPedidos: false,
      ie: '',
      redeId: '',
      rede: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      contatoNome: '',
      contatoTelefone: '',
      contatoEmail: '',
      valorMinimo: '',
      prazoPagamentos: [],
      telefone: '',
      representadaPedidoQuantConfigId: '',
      descontoSemLimites: false,
      ocultarProdutosSemPreco: false
    }),
    []
  );

  const [representada, setRepresentada] = React.useState({
    ...emptyRepresentada
  });

  const [opcoesCaixariaLocal, setOpcoesCaixariaLocal] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    setErrors({});
    setRepresentada(lib.isNullOrEmptyObject(entity) ? { ...emptyRepresentada } : { ...entity });
    if (opcoesCaixaria && opcoesCaixaria.length > 0) {
      setOpcoesCaixariaLocal(opcoesCaixaria);
    } else {
      getOpcoesQuantidadeAsync();
    }
  }, [entity, emptyRepresentada]);

  React.useEffect(() => {
    if (opcoesCaixaria && opcoesCaixaria.length > 0) {
      setOpcoesCaixariaLocal(opcoesCaixaria);
    } else {
      getOpcoesQuantidadeAsync();
    }
  }, []);

  function updateRepresentada(data) {
    setRepresentada({ ...representada, ...data });
  }

  async function getOpcoesQuantidadeAsync() {
    let response = await RepresentadaService.getOrderQuantConfigOptionsAsync();

    if (response && response.data && response.data.data && response.data.data)
      setOpcoesCaixariaLocal(response.data.data);
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm()) {
      window.hideLoader();
      return;
    }

    setErrors({});

    let prazoPagamentos = [];
    for (let p of representada.prazoPagamentos) {
      if (p.dias > 0 && prazoPagamentos.findIndex((x) => x.dias === p.dias) === -1) {
        prazoPagamentos.push(p);
      }
    }

    let model = {
      ...representada,
      prazoPagamentos: prazoPagamentos
    };

    let response = lib.isNullOrEmptyObject(model.representadaId)
      ? await RepresentadaService.addAsync(model)
      : await RepresentadaService.updateAsync(model);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseRepresentada = response.data.data;

      if (onClose) onClose(responseRepresentada);

      setRepresentada({ ...emptyRepresentada });

      setShowModal(false);

      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  async function getCnpjDados() {
    window.showLoader();

    let response = await GeneralService.getCNPJAsync(representada.cnpj);

    if (response.status === lib.statusCode.ok) {
      let data = {
        razaoSocial: response.data.data.razaoSocial ?? '',
        nomeFantasia: response.data.data.nomeFantasia ?? ''
      };

      updateRepresentada(data);
    }

    window.hideLoader();
  }

  function validateForm() {
    let errors = {};

    //required fields
    for (let prop in formFields) {
      let field = formFields[prop];
      if (!field.required || representada[field.key]?.toString().length) continue;

      errors[field.key] = [];
    }
    //--

    if (lib.isNullOrEmptyObject(errors)) return true;

    setErrors(errors);

    return false;
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="CADASTRAR NOVA REPRESENTADA"
    >
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-2">
              {/* CNPJ ------------------------------------------------------------------------ */}
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label className="form-label">CNPJ</label>
                  <InputCnpj
                    className={`form-control ${
                      lib.isNullOrEmptyObject(errors[formFields.cnpj.key]) ? '' : 'is-invalid'
                    }`}
                    disabled={formFields.cnpj.disabled}
                    value={representada.cnpj}
                    onChange={(value) => {
                      updateRepresentada({ cnpj: value });

                      representada.cnpj = value;
                      if (representada.cnpj.length === 18) getCnpjDados();
                    }}
                  />

                  <Errors errors={errors} keyName={formFields.cnpj.key} />
                </div>
              </div>

              {/* Razao Social ---------------------------------------------------------------- */}
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label className="form-label">Razão Social</label>
                  <input
                    type="text"
                    className={`form-control ${
                      lib.isNullOrEmptyObject(errors[formFields.razaoSocial.key])
                        ? ''
                        : 'is-invalid'
                    }`}
                    disabled={formFields.razaoSocial.disabled}
                    value={representada.razaoSocial}
                    onChange={(e) => updateRepresentada({ razaoSocial: e.target.value })}
                  />

                  <Errors errors={errors} keyName={formFields.razaoSocial.key} />
                </div>
              </div>

              {/* Nome FAntasia --------------------------------------------------------------- */}
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label className="form-label">Nome Fantasia</label>
                  <input
                    type="text"
                    className={`form-control ${
                      lib.isNullOrEmptyObject(errors[formFields.nomeFantasia.key])
                        ? ''
                        : 'is-invalid'
                    }`}
                    value={representada.nomeFantasia}
                    onChange={(e) => updateRepresentada({ nomeFantasia: e.target.value })}
                  />
                </div>
              </div>

              {/* Inscricao Estadual ---------------------------------------------------------- */}
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label className="form-label">Inscrição Estadual</label>
                  <input
                    type="text"
                    className={`form-control ${
                      lib.isNullOrEmptyObject(errors[formFields.ie.key]) ? '' : 'is-invalid'
                    }`}
                    value={representada.ie || ''}
                    onChange={(e) => updateRepresentada({ ie: e.target.value })}
                  />
                </div>
              </div>

              {/* Email ----------------------------------------------------------------------- */}
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label className="form-label">E-mail</label>
                  <input
                    type="text"
                    className={`form-control ${
                      lib.isNullOrEmptyObject(errors[formFields.email.key]) ? '' : 'is-invalid'
                    }`}
                    value={representada.email}
                    onChange={(e) => updateRepresentada({ email: e.target.value })}
                  />
                  <Errors errors={errors} keyName={formFields.email.key} />
                </div>
              </div>

              {/* NotificarPedidos ----------------------------------------------------------------------- */}
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label className="form-label">
                    <input
                      type="checkbox"
                      id={'notificarPedido'}
                      checked={representada.notificarPedidos || false}
                      value={representada.notificarPedidos || false}
                      onChange={(e) => {
                        updateRepresentada({
                          notificarPedidos: e.target.checked
                        });
                      }}
                    />
                    Notificar pedidos por email
                  </label>
                </div>
              </div>

              {/* Telefone -------------------------------------------------------------------- */}
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label className="form-label">Telefone</label>
                  <InputTelefone
                    className={`form-control ${
                      lib.isNullOrEmptyObject(errors[formFields.telefone.key]) ? '' : 'is-invalid'
                    }`}
                    value={representada.telefone}
                    onChange={(value) => updateRepresentada({ telefone: value })}
                  />
                  <Errors errors={errors} keyName={formFields.telefone.key} />
                </div>
              </div>

              {/* ValorMínimo -------------------------------------------------------------------- */}
              <div className="row mb-2">
                <div className="col-lg-12">
                  <label className="form-label">Valor Mínimo dos Pedidos</label>
                  <CurrencyInput
                    className={`form-control ${
                      lib.isNullOrEmptyObject(errors[formFields.valorMinimo.key])
                        ? ''
                        : 'is-invalid'
                    }`}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix="R$ "
                    selectAllOnFocus={true}
                    value={representada.valorMinimo}
                    onChangeEvent={(event, maskedvalue, floatvalue) => {
                      updateRepresentada({ valorMinimo: floatvalue });
                    }}
                  />
                  <Errors errors={errors} keyName={formFields.valorMinimo.key} />
                </div>
              </div>

              <div>
                <label className="form-label">Opções de Caixaria</label>
                <Select
                  className="select-form-control"
                  isClearable={true}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: lib.isNullOrEmptyObject(
                        errors[formFields.representadaPedidoQuantConfigId.key]
                      )
                        ? 'grey'
                        : 'red'
                    })
                  }}
                  options={opcoesCaixariaLocal}
                  placeholder="Escolha uma opção"
                  onChange={(option) =>
                    updateRepresentada({
                      representadaPedidoQuantConfigId: option?.value
                    })
                  }
                  value={opcoesCaixariaLocal.find(
                    (x) => x.value === representada?.representadaPedidoQuantConfigId
                  )}
                />
                <Errors errors={errors} keyName={formFields.representadaPedidoQuantConfigId.key} />
              </div>

              <div className="row my-3">
                <div className="col-lg-12">
                  <label className="form-label">
                    O que fazer com os pedidos com desconto acima do máximo permitido?
                  </label>

                  <div className="ps-4">
                    <label className="form-label text-black mb-2">
                      <input
                        type="radio"
                        checked={representada.descontoSemLimites}
                        onClick={() => {
                          updateRepresentada({
                            descontoSemLimites: true
                          });
                        }}
                      />{' '}
                      Usuário consegue finalizar o pedido, porem, o pedido será enviado para
                      aprovação do superior
                    </label>

                    <label className="form-label text-black">
                      <input
                        type="radio"
                        checked={!representada.descontoSemLimites}
                        onClick={() => {
                          updateRepresentada({
                            descontoSemLimites: false
                          });
                        }}
                      />{' '}
                      Usuário não consegue finalizar o pedido até que os valores fiquem dentro da
                      faixa de desconto permitido (pedido NÃO será enviado para aprovação)
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-12">
                  <label>
                    <input
                      type="checkbox"
                      id={'ocultarProdutosSemPreco'}
                      checked={representada.ocultarProdutosSemPreco || false}
                      value={representada.ocultarProdutosSemPreco || false}
                      onChange={(e) => {
                        updateRepresentada({
                          ocultarProdutosSemPreco: e.target.checked
                        });
                      }}
                    />{' '}
                    Ocultar os produtos sem preço?
                  </label>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-12">
                  <label>
                    <input
                      type="checkbox"
                      id={'possuiMultiploVenda'}
                      checked={representada.possuiMultiploVenda || false}
                      value={representada.possuiMultiploVenda || false}
                      onChange={(e) => {
                        updateRepresentada({
                          possuiMultiploVenda: e.target.checked
                        });
                      }}
                    />{' '}
                    Utiliza multiplo de venda?
                  </label>
                </div>
              </div>

              {/* Adicionar Prazo -------------------------------------------------------------------- */}
              <section name="Prazo de Pagamento">
                <FormGroupHeader className="mb-0" text="PRAZO DE PAGAMENTO" />

                <div className="row mb-4">
                  {representada?.prazoPagamentos?.map((ref, index) => (
                    <div className="col-lg-4 mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <NumberFormat
                            className={`form-control`}
                            value={ref.dias ?? ''}
                            decimalSeparator={false}
                            decimalScale={0}
                            allowLeadingZeros={true}
                            onValueChange={({ floatValue }) => {
                              ref.dias = floatValue;
                              setRepresentada({ ...representada });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 p-0 d-flex align-items-center">
                          <label className="form-label">Dias</label>
                          <button
                            type="button"
                            className="btn btn-link ms-2 p-0"
                            onClick={() => {
                              representada.prazoPagamentos.splice(index, 1);
                              setRepresentada({ ...representada });
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary margin-right"
                  onClick={() => {
                    representada.prazoPagamentos.push({
                      dias: ''
                    });
                    setRepresentada({ ...representada });
                  }}
                >
                  <FontAwesomeIcon icon={faAdd} /> Prazo de Pagamento
                </button>
              </section>
            </div>

            {/* Logo -------------------------------------------------------------------------- */}
            <div className="col-lg-6 mb-2 d-flex">
              <div className="row mb-2 flex-grow-1">
                <div className="col-lg-12 d-flex">
                  <InputImagePreview
                    selectedFile={representada.file}
                    setSelectedFile={(file) => updateRepresentada({ file: file })}
                    urlImage={representada.urlLogo}
                    text="Clique aqui para adicionar uma Logomarca"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <FormGroupHeader text="COMISSÃO" /> */}

          {/* Comissao ------------------------------------------------------------------------ */}
          {/* <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Comissão</label>
              <NumberFormat
                className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.comissao.key]) ? "" : "is-invalid"}`}
                value={representada.comissao ?? ""}
                decimalSeparator={","}
                suffix={" %"}
                allowLeadingZeros={true}
                onValueChange={({ floatValue }) => updateRepresentada({ comissao: floatValue })}
              />
              <Errors errors={errors} keyName={formFields.comissao.key} />
            </div>
          </div> */}

          <FormGroupHeader text="DADOS DE CONTATO" />

          {/* Nome ---------------------------------------------------------------------------- */}
          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="form-label">Nome</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.contatoNome.key]) ? '' : 'is-invalid'
                }`}
                value={representada.contatoNome}
                onChange={(e) => updateRepresentada({ contatoNome: e.target.value })}
                required
              />
              <Errors errors={errors} keyName={formFields.contatoNome.key} />
            </div>
          </div>

          <div className="row mb-2">
            {/* Telefone ---------------------------------------------------------------------- */}
            <div className="col-lg-6">
              <label className="form-label">Telefone</label>
              <InputTelefone
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.contatoTelefone.key])
                    ? ''
                    : 'is-invalid'
                }`}
                value={representada.contatoTelefone}
                onChange={(value) => updateRepresentada({ contatoTelefone: value })}
              />
              <Errors errors={errors} keyName={formFields.contatoTelefone.key} />
            </div>

            {/* Email ------------------------------------------------------------------------- */}
            <div className="col-lg-6">
              <label className="form-label">E-mail</label>
              <input
                type="text"
                className={`form-control ${
                  lib.isNullOrEmptyObject(errors[formFields.contatoEmail.key]) ? '' : 'is-invalid'
                }`}
                value={representada.contatoEmail}
                onChange={(e) => updateRepresentada({ contatoEmail: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.contatoEmail.key} />
            </div>
          </div>
        </form>
      </div>

      {/* Botao Salvar ------------------------------------------------------------------------ */}
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </ModalCadastro>
  );
}
