import React from 'react';
import { useMultipleSelection, useCombobox } from 'downshift';
import { useVirtualizer } from '@tanstack/react-virtual';

import './styles.scss';

function MultiSelectVirtual({
  list,
  initialSelectedItems,
  keyName,
  placeholder,
  height,
  onChange
}) {
  const [inputValue, setInputValue] = React.useState('');

  const [selectedItems, setSelectedItems] = React.useState(initialSelectedItems || []);

  function getFilteredItems(selectedItems, inputValue) {
    const lowerCasedInputValue = inputValue?.toLowerCase();

    return list.filter(function filterItem(item) {
      return (
        //!selectedItems.includes(item) &&
        item[keyName].toLowerCase().includes(lowerCasedInputValue)
      );
    });
  }

  const items = React.useMemo(
    () => getFilteredItems(selectedItems, inputValue),
    [selectedItems, inputValue, list]
  );

  const parentRef = React.useRef(null);

  const getRowHeight = (index) => {
    return height || 50;
  };

  const displayRow = (index) => {
    return (
      <label>
        {/* <input type="checkbox" checked={selectedItems.indexOf(items[index]) !== -1}
            onClick={e => {
              e.stopPropagation()
            }}
            onChange={e => {
              e.stopPropagation()
            }}
          />  */}

        {items[index][keyName]}
      </label>
    );
  };

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: getRowHeight,
    overscan: 5
  });

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          setSelectedItems(newSelectedItems);
          if (onChange) onChange(newSelectedItems);
          break;
        default:
          break;
      }
    }
  });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.title : '';
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: state.isOpen // keep the menu open after selection.
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          // changes._selectedItem = selectedItem;
          // changes.selectedItem = null;

          return {
            ...changes,
            isOpen: true // keep the menu open after selection.
            //highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange(changes) {
      let { inputValue: newInputValue, type, selectedItem: newSelectedItem } = changes;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            if (selectedItems.includes(newSelectedItem)) {
              removeSelectedItem(newSelectedItem);
            } else {
              let newSelectedItems = [...selectedItems, newSelectedItem];

              setSelectedItems(newSelectedItems);

              if (onChange) onChange(newSelectedItems);

              //setInputValue('')
            }
          }

          selectItem(null);

          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue);
          break;
        default:
          break;
      }
    }
  });

  function clearSelectedItems() {
    let newSelectedItems = [];

    setSelectedItems(newSelectedItems);

    if (onChange) onChange(newSelectedItems);
  }

  return (
    <>
      <div className="multi-select-virtual w-100 position-relative" style={{ fontSize: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
          {/* <label style={{ width: 'fit-content' }} {...getLabelProps()}>
              Pick some books:
            </label> */}
          <div
            style={{
              border: '1px solid lightgray',
              borderRadius: '4px',
              minHeight: '32px',
              backgroundColor: 'white',
              display: 'inline-flex'
            }}
            {...getToggleButtonProps()}
          >
            <div
              className="flex-grow-1 d-flex p-1 px-2 justify-content-between"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              <div
                className="d-flex gap-1"
                style={{
                  fontSize: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {selectedItems.length > 1 ? (
                  <div className="d-flex align-items-center">
                    {selectedItems.length} SELECIONADOS
                  </div>
                ) : (
                  selectedItems.map(function renderSelectedItem(selectedItemForRender, index) {
                    return (
                      <span
                        style={{
                          backgroundColor: '#f3f4f6',
                          borderRadius: '0.375rem',
                          padding: '0.25rem',
                          focus: { backgroundColor: '#f87171' },
                          textOverflow: 'ellipsis',
                          overflow: 'hidden'
                        }}
                        key={`selected-item-${index}`}
                        {...getSelectedItemProps({
                          selectedItem: selectedItemForRender,
                          index
                        })}
                      >
                        {selectedItemForRender[keyName]}
                        <span
                          style={{ padding: '0.25rem', cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            removeSelectedItem(selectedItemForRender);
                          }}
                        >
                          &#10005;
                        </span>
                      </span>
                    );
                  })
                )}
              </div>

              {selectedItems.length > 0 && (
                <button
                  type="button"
                  className="pointer"
                  aria-label=" "
                  style={{
                    background: 'none',
                    border: '0',
                    padding: '0',
                    display: 'flex',
                    color: '#aaaaaa'
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    clearSelectedItems();
                  }}
                >
                  <svg width="24" height="24" fill="none" stroke="currentColor" stroke-width="2">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              )}
            </div>

            <div className="d-flex align-items-center">
              <span
                style={{
                  alignSelf: 'stretch',
                  backgroundColor: 'hsl(0, 0%, 80%)',
                  marginBottom: '8px',
                  marginTop: '8px',
                  width: '1px',
                  boxSizing: 'border-box'
                }}
              ></span>

              <div
                aria-label="toggle menu"
                style={{
                  color: 'hsl(0, 0%, 80%)',
                  padding: '8px',
                  paddingTop: 0,
                  paddingBottom: 0
                }}
                type="button"
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  className="css-tj5bde-Svg"
                >
                  <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            width: 'inherit',
            backgroundColor: 'white',
            marginTop: '0.25rem',
            border: '1px solid lightgray',
            borderRadius: '4px',
            // maxHeight: '80%',
            padding: '0',
            zIndex: '10',
            display: !(isOpen && items.length) && 'none'
          }}
          {...getMenuProps()}
        >
          {
            <div className={isOpen ? 'd-block' : 'd-none'}>
              <input
                placeholder={placeholder}
                style={{
                  width: '100%',
                  border: 'none',
                  borderBottom: '1px solid lightgray',
                  borderRadius: '4px 4px 0 0',
                  padding: '0.5rem'
                }}
                {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
              />
            </div>
          }

          {isOpen && (
            <div
              ref={parentRef}
              className="List"
              style={{
                height: `200px`,
                width: `100%`,
                overflow: 'auto'
              }}
            >
              <div
                style={{
                  height: `${rowVirtualizer.getTotalSize()}px`,
                  width: '100%',
                  position: 'relative'
                }}
              >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  let item = items[virtualRow.index];
                  let index = virtualRow.index;

                  return (
                    <div
                      key={`${item.value}${index}`}
                      className={virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: `${getRowHeight(virtualRow.index)}px`,
                        transform: `translateY(${virtualRow.start}px)`,

                        backgroundColor: highlightedIndex === index ? '#93c5fd' : '',
                        fontWeight: selectedItem === item ? 'bold' : '',
                        padding: '0.5rem 0.75rem',
                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      {...getItemProps({ item, index })}
                    >
                      <input
                        type="checkbox"
                        className="me-2"
                        checked={selectedItems.includes(item)}
                        value={item}
                        onChange={() => null}
                      />
                      {displayRow(virtualRow.index)}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* {isOpen &&
              items.map((item, index) => (
                <li
                  style={{ backgroundColor: highlightedIndex === index ? '#93c5fd' : '', fontWeight: selectedItem === item ? 'bold' : '', padding: '0.5rem 0.75rem', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column' }}
                  key={`${item.value}${index}`}
                  {...getItemProps({ item, index })}
                >
                  <span>{item.title}</span>
                  <span style={{ fontSize: 'small', color: '#374151' }}>{item.author}</span>
                </li>
              ))} */}
        </div>
      </div>
    </>
  );
}

export default MultiSelectVirtual;
