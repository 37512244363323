import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

import './styles.scss';

export default function FaturamentoCliente({ source }) {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (!source?.representadas) return;

    let clientes = source.faturamentoClientes.map((x) => x.nome);
    let faturamento = source.faturamentoClientes.map((x) => x.valorFaturado);

    let chartData = {
      series: [
        {
          name: '', // Nome da série
          data: faturamento
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },

        dataLabels: {
          enabled: false
        },

        xaxis: {
          categories: clientes,
          labels: {
            formatter: function (val) {
              // Formate os valores do eixo X para milhares
              return val / 1000 + 'k';
            }
          }
        },

        tooltip: {
          y: {
            formatter: function (val) {
              // Formate os valores na tooltip para moeda brasileira (BRL)
              return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                val
              );
            }
          }
        },

        fill: {
          colors: ['#1244b7']
        }
      }
    };

    setChartData(chartData);
  }, [source]);

  if (!chartData) return null;

  return (
    <div id="chart">
      <h3 className="mb-2 text-gray-800">Faturamento Cliente</h3>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={500}
      />
    </div>
  );
}
