import ServiceClient from './service-client';

class ProdutoService {
  static async listPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get('/produto/list-paged', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addAsync(produto) {
    try {
      var result = await ServiceClient.FormPost('/produto/add', produto);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExtratoProdutos(filter) {
    try {
      var result = await ServiceClient.Post('/produto/get-extrato-em-bytes', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(produto) {
    try {
      console.log(produto);
      var result = await ServiceClient.FormPost('/produto/update', produto);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateStatusAsync(produto) {
    try {
      var result = await ServiceClient.Post('/produto/update-status', {
        produtoId: produto.produtoId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listPorUsuario() {
    try {
      var result = await ServiceClient.Get('/produto/list-por-usuario');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listCategoriasAsync() {
    try {
      var result = await ServiceClient.Get('/produto/list-categorias');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listMarcasAsync() {
    try {
      var result = await ServiceClient.Get('/produto/list-marcas');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listUnidadesAsync() {
    try {
      var result = await ServiceClient.Get('/produto/list-unidades');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async selectListRepresentadasAsync() {
    try {
      var result = await ServiceClient.Get('/produto/select-list-representadas');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listTabelaPrecosAsync(produtoId) {
    try {
      var result = await ServiceClient.Get('/produto/list-tabela-precos', { produtoId: produtoId });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default ProdutoService;
