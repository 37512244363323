import ServiceClient from './service-client';
import { service_url } from './service-constants';

class LoginService {
  static async loginAsync(bodyData) {
    try {
      var result = await ServiceClient.Post(service_url.login_login, bodyData);
      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async logoutAsync() {
    try {
      var result = await ServiceClient.Get(service_url.login_logout);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async checkUser() {
    try {
      var result = await ServiceClient.Post(service_url.login_checkuser);
      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async recuperarSenhaAsync(bodyData) {
    try {
      var result = await ServiceClient.Post(service_url.login_recuperarSenha, bodyData);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async novaSenhaAsync(bodyData) {
    try {
      var result = await ServiceClient.Post(service_url.login_novaSenha, bodyData);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default LoginService;
