import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';

const GraficoReceitaBruta = ({ chartOptions, series, seriesData }) => {
  return (
    <div className="dashboard-bar-chart">
      <div id="bar-chart">
        <div className="grafico-titulo text-center ">
          <h4>Receita Bruta</h4>
        </div>

        {series ? (
          <ReactApexChart
            height="400"
            type="bar"
            options={chartOptions}
            series={seriesData(series, 'receitaBruta')}
          />
        ) : (
          <Skeleton count={15} width={'100%'} height={20} />
        )}
      </div>
    </div>
  );
};

export default GraficoReceitaBruta;
