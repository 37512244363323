import React from 'react';
import Select, { components, createFilter } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { matchSorter } from 'match-sorter';

import './styles.scss';

const customStyles = {
  control: (styles) => ({
    ...styles,
    border: 'none',
    borderBottom: '1px solid #919191',
    borderRadius: 0,
    height: '55px'
  })
};

const formatOptionLabel = (option) => (
  <div className="format-option-loja">
    <div>
      <span className={'fantasia' + (option.inadimplente ? '-danger' : '')}>
        {option.fantasia} {option.codigo ? <>({option.codigo})</> : ''}{' '}
        {option.inadimplente ? ' - Bloqueado' : ''}
      </span>
      <br />
      <span className="cnpj">
        {option.razaoSocial} - {option.cnpj}
      </span>
      <br />
      <span className="cnpj">
        {option.endereco} - {option.bairro} - {option.cidade}
      </span>
    </div>
  </div>
);

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    &nbsp;
    <FontAwesomeIcon icon={faStore} /> {children}
  </components.Control>
);

const SelectLoja = (props) => {
  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: (option) => `${option.label}`,
    trim: true
  };

  return (
    <>
      <Select
        {...props}
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
        components={{ Control }}
        isClearable={true}
        placeholder="Selecione uma loja"
        noOptionsMessage={() => 'Sem opções'}
        filterOption={createFilter(filterConfig)}
      />
    </>
  );
};

export default SelectLoja;
