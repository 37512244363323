import React, { useEffect, useState } from 'react';

import Layout from 'components/layout/base';
import PageTitle from 'components/page-title';

import Filters from './filters';
import Cards from './Cards';
import GraficoReceitaBruta from './Graficos/receita-bruta';
import GraficoVolume from './Graficos/volume';
import GraficoPreco from './Graficos/preco';
import GraficoClientesAtivos from './Graficos/clientes-ativos';
import GraficoClientesCadastrados from './Graficos/clientes-cadastrados';
import TableRedes from './Tabelas/redes';

import DashboardService from 'services/dashboard-service';
import lib from 'helpers/lib';

import './styles.scss';
import { flushSync } from 'react-dom';

function Dashboard() {
  const today = new Date();
  const [isLoading, setIsLoading] = useState(true);

  const chartOptions = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        }
      }
    },
    colors: ['#1244b7', '#4ebcec'],
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#000000']
      },
      background: {
        enabled: true
      },
      formatter: function (val, opts, seriesIndex) {
        let seriesIndexT = opts.seriesIndex;
        let seriesData = opts.w.config.series[seriesIndexT].data;
        let dataValue = seriesData[opts.dataPointIndex];

        // return `${dataValue.isCurrency ? lib.formatToCurrencyText(val) : (dataValue.isVolume ? val + " Kg" : val)}`;
        return `${simplificarValor(val)}`;
      }
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '18px',
      fontFamily: 'Helvetica, Arial',
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '18px',
        itemMargin: {
          horizontal: 15,
          vertical: 0
        }
      },
      itemMargin: {
        horizontal: 15,
        vertical: 0
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let item = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        return `<div class="arrow_box">
          <p>Ano Passado: ${
            item.isCurrency
              ? lib.formatToCurrencyText(item.valorAnoAnterior)
              : item.isVolume
                ? item.valorAnoAnterior + ' Kg'
                : item.valorAnoAnterior
          }</p>
          <p>Ano Atual: ${
            item.isCurrency
              ? lib.formatToCurrencyText(item.valorAnoAtual)
              : item.isVolume
                ? item.valorAnoAtual + ' Kg'
                : item.valorAnoAtual
          }</p>
          <p>Diferença: ${
            item.diferenca
            // item.diferenca > 0 ?
            //   <span className="text-green">{item.diferenca.toLocaleString('pt-BR')}</span>
            //   :
            //   <span className="text-red">{item.diferenca.toLocaleString('pt-BR')}</span>
          }%</p>
        </div>`;
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '70%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    stroke: {
      width: [4, 0, 0]
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8
      }
    },
    xaxis: {
      categories: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ]
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return `${simplificarValor(value)}`;
        }
      }
    },
    labels: {
      formatter: (value) => {
        return `${value}`;
      }
    }
  };

  const filtroInitialValue = {
    mes: today.getMonth() + 1,
    ano: today.getFullYear(),
    categoriaId: '',
    categoria: null,
    produto: null,
    produtoId: '',
    equipeId: null, // vendedor não utiliza esse filtro
    equipe: null,
    vendedor: null,
    vendedorId: null, // apenas supervisor
    redeId: null,
    rede: null,
    uf: '',
    ufOption: null
  };

  const [filtro, setFiltro] = useState(filtroInitialValue);
  const [relatorios, setRelatorios] = useState({
    receitaBruta: null,
    volume: null,
    preco: null,
    clientesAtivos: null,
    clientesCadastrados: null
  });
  const [tabelaRedes, setTabelaRedes] = useState([]);
  const [cardsData, setCardsData] = useState({
    receita: [],
    volume: [],
    preco: [],
    cliente: []
  });

  function simplificarValor(valor) {
    if (valor != 0) {
      if (valor > 999 && valor <= 999999) {
        valor = parseFloat((valor / 1000).toFixed(1)) + 'mil';
      } else if (valor > 999999 && valor <= 999999999) {
        valor = parseFloat((valor / 1000000).toFixed(1)) + 'M';
      } else if (valor > 999999999) {
        valor = parseFloat((valor / 1000000000).toFixed(1)) + 'B';
      }
    }

    return valor;
  }

  async function getRelatoriosGraficos() {
    const response = await DashboardService.getRelatoriosGraficos(filtro);

    response.data &&
      setRelatorios({
        receitaBruta: response.data.data.find((p) => p.nomeRelatorio.includes('Receita Bruta')),
        volume: response.data.data.find((p) => p.nomeRelatorio.includes('Volume')),
        preco: response.data.data.find((p) => p.nomeRelatorio.includes('Preço')),
        clientesAtivos: response.data.data.find((p) => p.nomeRelatorio.includes('Clientes Ativos')),
        clientesCadastrados: response.data.data.find((p) =>
          p.nomeRelatorio.includes('Clientes Cadastrados')
        )
      });
  }

  async function getRelatoriosRedes() {
    const response = await DashboardService.getRelatoriosRedes(filtro);

    response.data.data && setTabelaRedes(response.data.data);
  }

  function seriesData(item, dataType) {
    let series = [];

    if (item) {
      series = [
        {
          name: filtro.ano - 1,
          type: 'column',
          data: item?.relatoriosMensais?.map((p) => ({
            x: lib.selectListMeses.find((x) => x.value == p.mes).label,
            y: p.valorAnoAnterior,
            diferenca: p.porcentagemDiferencaAnual,
            valorAnoAtual: p.valorAnoAtual,
            valorAnoAnterior: p.valorAnoAnterior,
            isCurrency: !['clientesAtivos', 'clientesCadastrados', 'volume'].includes(dataType),
            isVolume: dataType === 'volume'
          }))
        },
        {
          name: filtro.ano,
          type: 'column',
          data: item?.relatoriosMensais?.map((p) => ({
            x: lib.selectListMeses.find((x) => x.value == p.mes).label,
            y: p.valorAnoAtual,
            diferenca: p.porcentagemDiferencaAnual,
            valorAnoAtual: p.valorAnoAtual,
            valorAnoAnterior: p.valorAnoAnterior,
            isCurrency: !['clientesAtivos', 'clientesCadastrados', 'volume'].includes(dataType),
            isVolume: dataType === 'volume'
          }))
        }
      ];
    }

    return series;
  }

  async function getRelatoriosCards() {
    let response = await DashboardService.getRelatoriosCards(filtro);

    response.data &&
      setCardsData({
        receita: response.data.data.find((p) => p.nomeCard.includes('Receita')),
        volume: response.data.data.find((p) => p.nomeCard.includes('Volume')),
        preco: response.data.data.find((p) => p.nomeCard.includes('Preco')),
        cliente: response.data.data.find((p) => p.nomeCard.includes('Cliente'))
      });
  }

  async function getRelatoriosNovoDashboard() {
    const response = await DashboardService.getRelatoriosNovoDashboard(filtro);

    if (response.data) {
      setRelatorios({
        receitaBruta: response.data.data.graficos.find((p) =>
          p.nomeRelatorio.includes('Receita Bruta')
        ),
        volume: response.data.data.graficos.find((p) => p.nomeRelatorio.includes('Volume')),
        preco: response.data.data.graficos.find((p) => p.nomeRelatorio.includes('Preço')),
        clientesAtivos: response.data.data.graficos.find((p) =>
          p.nomeRelatorio.includes('Clientes Ativos')
        ),
        clientesCadastrados: response.data.data.graficos.find((p) =>
          p.nomeRelatorio.includes('Clientes Cadastrados')
        )
      });

      setTabelaRedes(response.data.data.redes);
    }
  }

  useEffect(async () => {
    await getRelatoriosNovoDashboard();
    await getRelatoriosCards();
    setIsLoading(false);
  }, []);

  return (
    <Layout>
      <div className="dashboard-new">
        <PageTitle title="Dashboard" />

        {/* Relatorio */}
        <Filters
          filtro={filtro}
          setFiltro={setFiltro}
          cleanFiltro={() => setFiltro(filtroInitialValue)}
          isLoading={isLoading}
          onClick={async () => {
            flushSync(() => {
              setIsLoading(true);
              setRelatorios({
                receitaBruta: null,
                volume: null,
                preco: null,
                clientesAtivos: null,
                clientesCadastrados: null
              });
              setCardsData({
                receita: [],
                volume: [],
                preco: [],
                cliente: []
              });
              setTabelaRedes([]);
            });

            await getRelatoriosNovoDashboard();
            await getRelatoriosCards();

            setIsLoading(false);
          }}
        />

        <div className="container-fluid">
          <div className="row shadow p-3 mb-5 bg-white rounded border-radius: 150px">
            <Cards cards={cardsData} />
          </div>
          <div className="row g-3 dashboard-new-content">
            <div className="col-12 shadow p-3 bg-white rounded">
              <GraficoReceitaBruta
                chartOptions={chartOptions}
                series={relatorios.receitaBruta}
                seriesData={seriesData}
              />
            </div>

            <div className="col-lg-12">
              <div className="dashboard-bar-chart shadow p-3 bg-white rounded">
                <GraficoVolume
                  chartOptions={chartOptions}
                  series={relatorios.volume}
                  seriesData={seriesData}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="dashboard-bar-chart shadow p-3 bg-white rounded">
                <GraficoPreco
                  chartOptions={chartOptions}
                  series={relatorios.preco}
                  seriesData={seriesData}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="dashboard-bar-chart shadow p-3 bg-white rounded">
                <GraficoClientesAtivos
                  chartOptions={chartOptions}
                  series={relatorios.clientesAtivos}
                  seriesData={seriesData}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="dashboard-bar-chart shadow p-3 bg-white rounded">
                <GraficoClientesCadastrados
                  chartOptions={chartOptions}
                  series={relatorios.clientesCadastrados}
                  seriesData={seriesData}
                />
              </div>
            </div>

            <div className="col-12 shadow p-3 bg-white rounded">
              <TableRedes redes={tabelaRedes} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
