import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';

const GraficoClientesCadastrados = ({ chartOptions, series, seriesData }) => {
  return (
    <div id="bar-chart">
      <div className="grafico-titulo text-center ">
        <h4>Número de Clientes Cadastrados</h4>
      </div>

      {series ? (
        <ReactApexChart
          height="400"
          type="bar"
          options={chartOptions}
          series={seriesData(series, 'clientesCadastrados')}
        />
      ) : (
        <Skeleton count={15} width={'100%'} height={20} />
      )}
    </div>
  );
};

export default GraficoClientesCadastrados;
