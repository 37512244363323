import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

import { LoggedUserContext } from '../../contexts/logged-user-context';
import ModalSelecionarEquipe from '../../components/modal-selecionar-equipe';

import LoginService from '../../services/login-service';

import './styles.scss';

export default function Header({ lock, forceSelecionarEquipe }) {
  const [userData, _] = React.useContext(LoggedUserContext);
  const [showModal, setShowModal] = React.useState(false);

  const onClickToggler = () => {
    window.changeMenuState();
  };

  async function logout() {
    window.showLoader();

    localStorage.clear();

    await LoginService.logoutAsync();

    window.location = '/login';

    window.hideLoader();
  }

  return (
    <header className="header-nav">
      <nav className=" navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <div className="row w-100 header-main">
            <div className="col-sm-12 col-md-auto">
              {/* <div className='row'>
								<div className='col-auto'></div>
								<div className='col text-end'>
									<div className='d-inline d-md-none'>
										<ul className='navbar-nav ms-auto '>
											<li className='nav-item'>
												<button
													type='button'
													className='btn btn-link text-white'
													onClick={logout}
												>
													<FontAwesomeIcon
														icon={faSignOut}
													/>
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div> */}
            </div>

            {lock ? (
              <></>
            ) : (
              <div className="col">
                <div className="header-main">
                  <div className="">
                    <div className="header-nome">
                      <span>{userData?.nome}</span>
                      {userData?.equipes?.length > 0 && (
                        <small onClick={() => setShowModal(true)}>Acessar o time</small>
                      )}
                    </div>

                    {userData?.equipes?.length > 0 && userData?.equipe?.nome && (
                      <span> Acessando {userData?.equipe?.nome}</span>
                    )}
                  </div>
                  <div className="logout-btn">
                    <button type="button" className="btn " onClick={logout}>
                      <span>Sair</span>
                      <FontAwesomeIcon icon={faSignOut} />{' '}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>

      <ModalSelecionarEquipe
        showModal={showModal || forceSelecionarEquipe}
        setShowModal={setShowModal}
      />
    </header>
  );
}
