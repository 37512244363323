import React from 'react';
import Errors from '../_base/errors';
import FormGroupHeader from '../_base/form-group-header';
import InputPreco from './_input-preco';

import ProdutoService from '../../services/produto-service';

import lib from '../../helpers/lib';

export default function TabelaPrecos({ produto, updateProduto, errors }) {
  const [tabelaPrecos, setTabelaPrecos] = React.useState([]);

  React.useEffect(() => {
    if (produto.tabelaPrecos) {
      setTabelaPrecos(produto.tabelaPrecos);
      return;
    }

    async function getListProdutoCodigo() {
      window.showLoader();

      let response = await ProdutoService.listTabelaPrecosAsync(produto?.produtoId);

      if (response.status === lib.statusCode.ok) {
        let tabelaPrecos = response.data.data || [];

        updateProduto({ tabelaPrecos: tabelaPrecos });
        setTabelaPrecos(tabelaPrecos);
      }

      window.hideLoader();
    }

    getListProdutoCodigo();
  }, [produto, updateProduto]);

  if (!produto.tabelaPrecos?.length) return null;

  return (
    <>
      <FormGroupHeader text="TABELA DE PREÇOS" />
      <div className="row mb-2">
        <div className="col-lg-12">
          <table className="table table-cadastro">
            <thead>
              <tr>
                <th>Tabela</th>
                <th>Preço Mínimo</th>
                <th>Preço Máximo</th>
              </tr>
            </thead>
            <tbody>
              {(tabelaPrecos || []).map((tabelaPreco) => {
                let errorKey = `tabelaPrecoId_${tabelaPreco.tabelaPrecoId}`;
                let invalid = errors && !lib.isNullOrEmptyObject(errors[errorKey]);

                return (
                  <React.Fragment key={tabelaPreco.tabelaPrecoId}>
                    <tr className="border-bottom-0">
                      <td rowSpan={2}>{tabelaPreco.nome}</td>
                      <td className="border-bottom-0">
                        <InputPreco
                          tabelaPreco={tabelaPreco}
                          keyName="precoMinimo"
                          className={invalid ? 'is-invalid' : ''}
                        />
                      </td>
                      <td className="border-bottom-0">
                        <InputPreco
                          tabelaPreco={tabelaPreco}
                          keyName="precoMaximo"
                          className={invalid ? 'is-invalid' : ''}
                        />
                      </td>
                    </tr>
                    <tr>
                      {invalid ? (
                        <td colSpan={2}>
                          <Errors
                            errors={errors}
                            keyName={errorKey}
                            style={{ marginTop: '-15px' }}
                          />
                        </td>
                      ) : null}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {/* <Errors errors={errors} keyName={formFields.contatoTelefone.key} /> */}
        </div>
      </div>
    </>
  );
}
