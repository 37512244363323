import React from 'react';
import { Modal } from 'react-bootstrap';

export default function ModalConfirmacao(props) {
  const { showModal, setShowModal, onClose, onConfirm, onCancel } = props;

  const [headerText, setHeaderText] = React.useState(
    props.headerText ? props.headerText : 'Atenção!'
  );
  const [btnYesText, setBtnYesText] = React.useState(props.btnYesText ? props.btnYesText : 'Sim');
  const [btnNoText, setBtnNoText] = React.useState(props.btnNoText ? props.btnNoText : 'Não');

  function close(result) {
    setShowModal(false);

    if (result === 'yes' && onConfirm) {
      onConfirm();
      return;
    }

    if (result === 'no' && onCancel) {
      onCancel();
      return;
    }

    if (onClose) onClose(result);
  }

  return (
    <Modal
      className="modal"
      show={showModal}
      onHide={() => {
        close('no');
      }}
      tabIndex="-1"
      centered
    >
      {/* <div className="modal-dialog">  */}
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{headerText}</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              close('no');
            }}
          ></button>
        </div>

        <div className="modal-body">{props.children}</div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              close('yes');
            }}
          >
            {btnYesText}
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              close('no');
            }}
          >
            {btnNoText}
          </button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}
