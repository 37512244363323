import React from 'react';
import { matchSorter } from 'match-sorter';
import FormGroupHeader from '../_base/form-group-header';
import InputPreco from './_input-preco';

import TabelaPrecoService from '../../services/tabela-preco-service';

import lib from '../../helpers/lib';
import Errors from '../_base/errors';
import CurrencyInput from 'react-currency-input';
import TabelaInputPorcentagem from './_input-porcetagem';

export default function TabelaPrecoProdutos({ tabelaPreco, errors }) {
  const [produtos, setProdutos] = React.useState([]);
  const [matchProdutos, setMatchProdutos] = React.useState([]);

  React.useEffect(() => {
    if (!(tabelaPreco.representadaId?.length > 0)) return;

    if (tabelaPreco.produtos) return;

    async function getListProdutoCodigo() {
      window.showLoader();

      let response = await TabelaPrecoService.listProdutosAsync(tabelaPreco);

      if (response.status === lib.statusCode.ok) {
        tabelaPreco.produtos = response.data.data || [];
        setProdutos(tabelaPreco.produtos);
        setMatchProdutos(tabelaPreco.produtos);
      }

      window.hideLoader();
    }

    getListProdutoCodigo();
  }, [tabelaPreco]);

  if (!produtos?.length) return null;

  return (
    <>
      <FormGroupHeader text="PRODUTOS" />
      <div className="row mb-2">
        <div className="col-lg-12">
          <label className="form-label">Código do Produto</label>
          <input
            type="text"
            className={`form-control`}
            onChange={(e) => {
              if (e.target.value?.length > 0) {
                setMatchProdutos(
                  matchSorter(produtos, e.target.value, {
                    keys: ['codigo'],
                    threshold: matchSorter.rankings.EQUAL
                  })
                );
              } else {
                setMatchProdutos(produtos);
              }
            }}
          />
        </div>
        <div className="col-lg-12">
          <label className="form-label">Nome do Produto</label>
          <input
            type="text"
            className={`form-control`}
            onChange={(e) => {
              if (e.target.value?.length > 0) {
                setMatchProdutos(matchSorter(produtos, e.target.value, { keys: ['nome'] }));
              } else {
                setMatchProdutos(produtos);
              }
            }}
          />
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-lg-12">
          <table className="table table-cadastro">
            <thead>
              <tr>
                <th>Código</th>
                <th>Produto</th>
                <th>Unid. de Medida</th>
                <th>Preço Mínimo</th>
                <th>Preço Máximo</th>
                <th>Teto Desconto %</th>
              </tr>
            </thead>
            <tbody>
              {(matchProdutos || []).map((produto) => {
                let errorKey = `produtoId_${produto.produtoId}`;
                let invalid = errors && !lib.isNullOrEmptyObject(errors[errorKey]);

                return (
                  <React.Fragment key={produto.produtoId}>
                    <tr className="border-bottom-0">
                      <td rowSpan={2}>{produto.codigo}</td>
                      <td rowSpan={2}>{produto.nome}</td>
                      <td rowSpan={2}>{produto.unidade}</td>
                      <td className="border-bottom-0">
                        <InputPreco
                          produto={produto}
                          keyName="precoMinimo"
                          className={invalid ? 'is-invalid' : ''}
                        />
                      </td>
                      <td className="border-bottom-0">
                        <InputPreco
                          produto={produto}
                          keyName="precoMaximo"
                          className={invalid ? 'is-invalid' : ''}
                        />
                      </td>

                      <td className="border-bottom-0">
                        <TabelaInputPorcentagem
                          produto={produto}
                          keyName="percentualTetoDescontoProduto"
                          className={invalid ? 'is-invalid' : ''}
                        />
                      </td>
                    </tr>
                    <tr>
                      {invalid ? (
                        <td colSpan={2}>
                          <Errors
                            errors={errors}
                            keyName={errorKey}
                            style={{ marginTop: '-15px' }}
                          />
                        </td>
                      ) : null}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {/* <Errors errors={errors} keyName={formFields.contatoTelefone.key} /> */}
        </div>
      </div>
    </>
  );
}
