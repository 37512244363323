import React, { useEffect, useRef, useState } from 'react';

import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faFilter } from '@fortawesome/free-solid-svg-icons';

import DasboardBarChart from '../../dashboard-app/components/bar-chart/index';
import SelectMulti from 'components/_base/multi-select';

import lib from 'helpers/lib';
import DashboardService from 'services/dashboard-service';
import CardPanel from 'pages/dashboard-app/components/big-number';
import { flushSync } from 'react-dom';

const Pedidos = ({ representadas, redes }) => {
  const data = new Date();

  const [filter, setFilter] = useState({
    representadas: [],
    redes: [],
    ano: data.getFullYear(),
    mes: data.getMonth() + 1
  });

  const [metaContratante, setMetaContratante] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  function getFilterParams() {
    return {
      representadaIds: filter.representadas.map((x) => x.value),
      redeIds: filter.redes.map((x) => x.value),
      mes: filter.mes,
      ano: filter.ano
    };
  }

  async function getMetaCotratante(representada = filter.representada) {
    const params = getFilterParams();

    const response = await DashboardService.getMetaContratanteAsync(params);

    setMetaContratante(response.data.data);
    setIsLoading(false);
  }

  useEffect(async () => {
    await getMetaCotratante();
    setIsLoading(false);
  }, [representadas]);

  function limpar() {
    setFilter({
      representadas: [],
      redes: [],
      ano: data.getFullYear(),
      mes: data.getMonth() + 1
    });
  }

  return (
    <div className="m-0">
      <div className="col-lg-12 pt-3">
        <div>
          <div className="row">
            {/* Representada ---------------------------------------------------------------------- */}
            <div className="col-lg-3" disabled>
              <label>Representada</label>
              <SelectMulti
                options={representadas}
                value={filter.representadas}
                onChange={(options) => setFilter({ ...filter, representadas: options })}
                //filterOptions={search}
              />
            </div>

            <div className="col-lg-3" disabled>
              <label>Rede</label>
              <SelectMulti
                options={redes}
                value={filter.redes}
                onChange={(options) => setFilter({ ...filter, redes: options })}
              />
            </div>

            <div className="col-lg-3">
              <label>Mês</label>
              <Select
                className="select-form-control"
                value={lib.selectListMeses?.find((item) => item.value === filter.mes)}
                placeholder="Selecione..."
                options={lib.selectListMeses}
                isClearable={false}
                onChange={(item) => {
                  setFilter({ ...filter, mes: item.value });
                }}
              />
            </div>

            <div className="col-lg-3">
              <label>Ano</label>
              <Select
                className="select-form-control"
                value={lib.selectListAnos?.find((item) => item.value === filter.ano)}
                options={lib.selectListAnos}
                placeholder="Selecione..."
                isClearable={false}
                onChange={(item) => {
                  setFilter({ ...filter, ano: item.value });
                }}
              />
            </div>
          </div>

          <div className="row mt-3 justify-content-end">
            <div className="col-3 text-center">
              <button
                type="button"
                className="btn btn-secondary btn-action w-100"
                onClick={() => limpar()}
              >
                <FontAwesomeIcon icon={faEraser} /> Limpar
              </button>
            </div>

            <div className="col-3 text-center">
              <button
                type="button"
                className="btn btn-secondary btn-action w-100"
                onClick={() => {
                  flushSync(() => {
                    setIsLoading(true);
                  });

                  getMetaCotratante();
                }}
                disabled={isLoading}
              >
                <FontAwesomeIcon icon={faFilter} /> Filtrar
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div className="row m-0 mt-3">
          <div className="col-lg-12 shadow p-3 mb-5 bg-white rounded border-radius: 150px">
            <CardPanel source={metaContratante} isLoading={isLoading} />
          </div>
        </div>

        {!isLoading ? <DasboardBarChart source={metaContratante} /> : <Skeleton count={10} />}
      </div>
    </div>
  );
};

export default Pedidos;
