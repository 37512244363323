import React from 'react';
import ModalCadastro from '../_base/modal-cadastro';
import Select from 'react-select';
import lib from '../../helpers/lib';
import ProdutoService from '../../services/produto-service';
import RedeService from '../../services/rede-service';
import AcordoService from '../../services/acordo-service';
import SelectRepresentadaCadastro from '../selects/select-representada-cadastro';
import Errors from '../_base/errors';
import CurrencyInput from 'react-currency-input';
import DatePickerCustom from '../_base/DatePicker';

export default function ModalCadastroAcordo(props) {
  const { showModal, setShowModal, onClose, entity } = props;
  const [dataFormatada, setDataFormatada] = React.useState('');
  const [selectListRepresentadas, setSelectListRepresentadas] = React.useState([]);

  const [redes, setRedes] = React.useState([]);

  const [formasPagamento, setFormasPagamento] = React.useState([]);

  const emptyAcordo = {
    acordoId: '',
    representadaId: '',
    redeId: 0,
    data: new Date(),
    valor: 0,
    numeroAcordo: '',
    acordoFormaPagamentoId: 0,
    motivo: ''
  };

  const formFields = {
    representadaId: {
      key: 'representadaId',
      required: true,
      validate: () =>
        selectListRepresentadas.findIndex((item) => item.value === acordo.representadaId) !== -1
    },
    redeId: {
      key: 'redeId',
      required: true,
      validate: () => redes.findIndex((rede) => rede.redeId === acordo.redeId) !== -1
    },
    acordoFormaPagamentoId: {
      key: 'acordoFormaPagamentoId',
      required: true,
      validate: () =>
        formasPagamento.findIndex((forma) => forma.id === acordo.acordoFormaPagamentoId) !== -1
    },
    valor: {
      key: 'valor',
      required: true,
      validate: () => acordo.valor > 0
    },
    numeroAcordo: {
      key: 'numeroAcordo',
      required: true,
      validate: () => acordo.numeroAcordo != ''
    },
    data: {
      key: 'data',
      required: true,
      validate: () => acordo.data != ''
    },
    motivo: { key: 'motivo', required: true }
  };

  const [acordo, setAcordo] = React.useState({ ...emptyAcordo });

  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    getRepresentadas();
    getSelectListRede();
    getFormasPagamento();
  }, []);

  React.useEffect(() => {
    const temp = lib.getDateFromStringJson(new Date());
    setDataFormatada(temp);
    setErrors({});
    setAcordo(lib.isNullOrEmptyObject(entity) ? { ...emptyAcordo } : { ...entity });

    if (!lib.isNullOrEmptyObject(entity)) {
      if (entity.data) {
        const rawDate = lib.getDateFromStringJson(entity.data);
        setDataFormatada(rawDate);
      }
    }
  }, [entity]);

  async function getRepresentadas() {
    let response = await ProdutoService.selectListRepresentadasAsync();
    let selectListRepresentadas = response.data.data;

    setSelectListRepresentadas(selectListRepresentadas);
  }

  async function getFormasPagamento() {
    let response = await AcordoService.getFormasPagamentoAsync();
    let selectListFormas = response.data.data;

    setFormasPagamento(selectListFormas);
  }

  async function getSelectListRede() {
    let redes = await RedeService.getSelectList();
    if (redes.data) setRedes(redes.data.data);
  }

  function updateAcordo(data) {
    setAcordo({ ...acordo, ...data });
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm()) {
      window.hideLoader();
      return;
    }

    setErrors({});

    let response =
      !acordo.acordoId || acordo.acordoId === 0
        ? await AcordoService.addAsync(acordo)
        : await AcordoService.updateAsync(acordo);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseAcordo = response.data.data;

      if (onClose) onClose(responseAcordo);

      setAcordo({ ...emptyAcordo });

      setShowModal(false);

      //reload categorias e marcas
      getRepresentadas();
      getRedes();
      getSelectListRede();

      return;
    }

    setErrors(response.data.errors);

    window.hideLoader();
  }

  function validateForm() {
    let errors = {};

    for (let prop in formFields) {
      let field = formFields[prop];

      if (formFields[field.key].validate && formFields[field.key].validate()) continue;
      else if (!field.required || acordo[field.key]?.length || acordo[field.key] > 0) continue;

      errors[field.key] = [];
    }
    //--

    if (lib.isNullOrEmptyObject(errors)) return true;

    setErrors(errors);

    return false;
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="CADASTRAR NOVO ACORDO"
    >
      <div className="modal-body">
        <div className="row justify-content-center">
          <div className="col-6">
            <label className="form-label">Representada*</label>
            <SelectRepresentadaCadastro
              className={`${
                lib.isNullOrEmptyObject(errors[formFields.representadaId.key]) ? '' : 'is-invalid'
              }`}
              placeholder="Selecione uma Representada"
              options={selectListRepresentadas}
              value={selectListRepresentadas.find((x) => x.value === acordo.representadaId) || null}
              onChange={(selectedValue) => updateAcordo({ representadaId: selectedValue?.value })}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-6">
            <label className="form-label">Cliente*</label>
            <Select
              className={`${
                lib.isNullOrEmptyObject(errors[formFields.redeId.key]) ? '' : 'is-invalid'
              }`}
              options={redes}
              value={redes.find((x) => x.value == acordo.redeId) || null}
              onChange={(selectedValue) => updateAcordo({ redeId: selectedValue?.value })}
              classNamePrefix="select-cadastro"
              isClearable={true}
              placeholder="Selecione um Cliente"
              noOptionsMessage={() => 'Sem opções'}
            />
          </div>
        </div>
        <div className="row justify-content-center" style={{ marginBottom: '10px' }}>
          <div className="col-6">
            <label className="form-label">Data acordo*</label>
            <DatePickerCustom
              className="form-control"
              name="dataFinal"
              selected={dataFormatada || new Date()}
              onChange={(value) => {
                updateAcordo({
                  data: value
                });
                setDataFormatada(value);
              }}
            />
            <Errors errors={errors} keyName={formFields.data.key} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-6">
            <label className="form-label">Número Acordo*</label>
            <input
              type="text"
              className={`form-control ${
                lib.isNullOrEmptyObject(errors[formFields.numeroAcordo.key]) ? '' : 'is-invalid'
              }`}
              value={acordo.numeroAcordo}
              onChange={(e) => updateAcordo({ numeroAcordo: e.target.value })}
            />
            <Errors errors={errors} keyName={formFields.numeroAcordo.key} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-6">
            <label className="form-label">Valor</label>
            <CurrencyInput
              className={`form-control ${
                lib.isNullOrEmptyObject(errors[formFields.valor.key]) ? '' : 'is-invalid'
              }`}
              decimalSeparator=","
              thousandSeparator="."
              prefix="R$ "
              selectAllOnFocus={true}
              value={acordo.valor || 0}
              onChangeEvent={(event, maskedvalue, floatvalue) => {
                updateAcordo({ valor: floatvalue });
              }}
            />

            <Errors errors={errors} keyName={formFields.valor.key} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-6">
            <label className="form-label">Forma Pagamento*</label>
            <Select
              className={`${
                lib.isNullOrEmptyObject(errors[formFields.acordoFormaPagamentoId.key])
                  ? ''
                  : 'is-invalid'
              }`}
              options={formasPagamento}
              value={formasPagamento.find((x) => x.value === acordo.acordoFormaPagamentoId) || null}
              onChange={(selectedValue) =>
                updateAcordo({ acordoFormaPagamentoId: selectedValue?.value })
              }
              classNamePrefix="select-cadastro"
              isClearable={true}
              placeholder="Selecione uma forma de pagamento"
              noOptionsMessage={() => 'Sem opções'}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-6">
            <label className="form-label">Motivo*</label>
            <input
              type="text"
              className={`form-control ${
                lib.isNullOrEmptyObject(errors[formFields.motivo.key]) ? '' : 'is-invalid'
              }`}
              value={acordo.motivo}
              onChange={(e) => updateAcordo({ motivo: e.target.value })}
            />
            <Errors errors={errors} keyName={formFields.motivo.key} />
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          Criar
        </button>
      </div>
    </ModalCadastro>
  );
}
