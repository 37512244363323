import ServiceClient from './service-client';

class EquipeService {
  static async getAsync(id) {
    try {
      let result = await ServiceClient.Get('/equipe/get', { equipeId: id });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listOwnerTeamsPagedAsync(filter) {
    try {
      let result = await ServiceClient.Get('/equipe/list-owner-teams-paged', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listRepresentantesAsync(filter) {
    try {
      let result = await ServiceClient.Get('/equipe/list-representante', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listMemberTeamsPagedAsync(filter) {
    try {
      let result = await ServiceClient.Get('/equipe/list-member-teams-paged', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listInvitationsAsync() {
    try {
      let result = await ServiceClient.Get('/equipe/list-invitations');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listEquipesSupervisionadasAsync() {
    try {
      let result = await ServiceClient.Get('/equipe/list-equipes-supervisionadas');
      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addAsync(equipe) {
    try {
      let result = await ServiceClient.Post('/equipe/add', equipe);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(equipe) {
    try {
      let result = await ServiceClient.Post('/equipe/update', equipe);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async inativateAsync(equipe) {
    try {
      equipe.ativo = false;
      let result = await ServiceClient.Post('/equipe/update', equipe);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async sendInviteAsync(model) {
    try {
      let result = await ServiceClient.Post('/equipe/send-invite', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async resendInviteAsync(model) {
    try {
      let result = await ServiceClient.Post('/equipe/resend-invite', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async invitationAsync(token) {
    try {
      let result = await ServiceClient.Get('/equipe/invitation', {
        token: token
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async acceptWithTokenAsync(model) {
    try {
      let result = await ServiceClient.Post('/equipe/accept-with-token', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async refuseWithTokenAsync(token) {
    try {
      let result = await ServiceClient.Post('/equipe/refuse-with-token', {
        token: token
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async removeUser(model) {
    try {
      let result = await ServiceClient.Post('/equipe/remove-user', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async acceptAsync(equipeId) {
    try {
      let result = await ServiceClient.Post(`/equipe/accept/${equipeId}`);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async refuseAsync(equipeId) {
    try {
      let result = await ServiceClient.Post(`/equipe/refuse/${equipeId}`);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async leaveAsync(equipeId) {
    try {
      let result = await ServiceClient.Post(`/equipe/leave/${equipeId}`);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCarteiraAsync() {
    try {
      let result = await ServiceClient.Get('/equipe/get-carteira');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCarteiraEquipeAsync(equipeId) {
    try {
      let result = await ServiceClient.Get('/equipe/get-carteira-equipe', {
        equipeId: equipeId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCarteiraEquipeUsuarioAsync({ equipe, usuario }) {
    try {
      let result = await ServiceClient.Get('/equipe/get-carteira-equipe-usuario', {
        equipeId: equipe?.equipeId,
        usuarioId: usuario?.usuarioId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listClientesAsync() {
    try {
      let result = await ServiceClient.Get('/equipe/list-clientes');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listRepresentadasAsync() {
    try {
      let result = await ServiceClient.Get('/equipe/list-representadas');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveCarteiraAsync(model) {
    try {
      let result = await ServiceClient.Post('/equipe/save-carteira', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getDefaultEquipePermissoesAsync({ equipe }) {
    try {
      let result = await ServiceClient.Get('/equipe/get-equipe-permissoes', {
        equipeId: equipe?.equipeId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveDefaultEquipePermissoesAsync(model) {
    try {
      let result = await ServiceClient.Post('/equipe/save-equipe-permissoes', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getUsuarioPermissoesAsync({ equipe, usuario }) {
    try {
      let result = await ServiceClient.Get('/equipe/get-usuario-permissoes', {
        equipeId: equipe?.equipeId,
        usuarioId: usuario?.usuarioId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listPermissoesAsync() {
    try {
      let result = await ServiceClient.Get('/equipe/list-permissoes');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveUsuarioPermissoesAsync(model) {
    try {
      let result = await ServiceClient.Post('/equipe/save-usuario-permissoes', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getUsuarioResponsavelAsync(model) {
    try {
      let result = await ServiceClient.Get('/equipe/get-usuario-responsavel');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default EquipeService;
