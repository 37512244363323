import React, { useState, useEffect } from 'react';

import Layout from '../../components/layout/base/index';
import PageTitle from '../../components/page-title/index';
import AlertPedidosAvaliacao from '../../components/alert-pedidos-avaliacao';
import Dashboard from '../dashboard-app';

import './styles.scss';

function DashboardOld() {
  return (
    <Layout>
      <AlertPedidosAvaliacao />

      <div className="home-web d-flex">
        <Dashboard />
      </div>
    </Layout>
  );
}

export default DashboardOld;
