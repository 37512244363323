import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import lib from 'helpers/lib';
import { useDebouncedEffect } from 'helpers/useDebouncedEffect';

const TableRedes = ({ redes }) => {
  const [filtroRede, setFiltroRede] = useState('');
  const [redeFiltrada, setRedeFiltrada] = useState([]);

  useEffect(() => {
    setRedeFiltrada(redes);
    setFiltroRede('');
  }, [redes]);

  useDebouncedEffect(
    () => {
      if (filtroRede) {
        const redeFiltrada = redes.filter((rede) =>
          rede.nomeRede.toLowerCase().includes(filtroRede.toLowerCase())
        );
        setRedeFiltrada(redeFiltrada);
      } else if (filtroRede.trim().length === 0) {
        setRedeFiltrada(redes);
      } else {
        setRedeFiltrada(redes);
      }
    },
    [filtroRede],
    700
  );

  const limparFiltroRede = () => {
    setFiltroRede('');
    setRedeFiltrada([]);
  };

  return (
    <>
      <h4 className="text-center p-2">Venda por Rede</h4>
      {redes.length ? (
        <>
          <Table striped bordered width="100%" className="table-vendas">
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: '#7a7a7a',
                    color: '#fff',
                    fontSize: '1rem',
                    borderRight: '2px solid #7a7a7a'
                  }}
                >
                  REDE
                  <div class="input-group">
                    <input
                      type="text"
                      className={`form-control`}
                      placeholder="Filtrar Rede"
                      value={filtroRede || ''}
                      onChange={(e) => setFiltroRede(e.target.value)}
                    />
                    <span
                      class="input-group-text"
                      id="basic-addon2"
                      style={{
                        cursor: 'pointer'
                      }}
                      onClick={() => limparFiltroSetor()}
                    >
                      <FontAwesomeIcon icon={faTimes} color="#7a7a7a" />
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    backgroundColor: '#7a7a7a',
                    color: '#fff',
                    fontSize: '1rem',
                    borderRight: '2px solid #7a7a7a'
                  }}
                >
                  MAX
                </th>
                <th
                  style={{
                    backgroundColor: '#7a7a7a',
                    color: '#fff',
                    fontSize: '1rem',
                    borderRight: '2px solid #7a7a7a'
                  }}
                >
                  MEDIA
                </th>
                <th
                  style={{
                    backgroundColor: '#7a7a7a',
                    color: '#fff',
                    fontSize: '1rem',
                    borderRight: '2px solid #7a7a7a'
                  }}
                >
                  FAT
                </th>
              </tr>
            </thead>
            <tbody>
              {(redeFiltrada.length ? redeFiltrada : redes).map((rede, index) => (
                <tr key={index}>
                  <td style={{ borderRight: '2px solid #7a7a7a' }}>{rede.nomeRede}</td>
                  <td style={{ borderRight: '2px solid #7a7a7a' }}>
                    {lib.formatToCurrencyText(rede.valorMax)}
                  </td>
                  <td style={{ borderRight: '2px solid #7a7a7a' }}>
                    {lib.formatToCurrencyText(rede.valorMedia)}
                  </td>
                  <td style={{ borderRight: '2px solid #7a7a7a' }}>
                    {lib.formatToCurrencyText(rede.valorFaturado)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>{' '}
        </>
      ) : (
        <Skeleton count={10} />
      )}
    </>
  );
};

export default TableRedes;
