import { faEdit, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

import Layout from '../../components/layout/base/index';
import ModalCadastroAcordo from '../../components/modal-cadastro-acordo';
import PageTitle from '../../components/page-title';
import TablePagination from '../../components/table-pagination';
import lib from '../../helpers/lib';
import AcordoService from '../../services/acordo-service';
import RedeService from '../../services/rede-service';
import RepresentadaService from '../../services/representada-service';
export default function Acordo() {
  const [acordo, setAcordo] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [representadas, setRepresentadas] = React.useState();
  const [representadaSelecionada, setRepresentadaSelecionada] = React.useState();
  const [redes, setRedes] = React.useState();
  const [redeSelecionada, setRedeSelecionada] = React.useState();
  const [filter, setFilter] = React.useState({
    motivo: '',
    cliente: 0,
    representada: ''
  });
  const [pageData, setPageData] = React.useState({});

  React.useEffect(() => {
    //filterProdutos();
    getSelectListRepresentada();
    getSelectListRede();
    filterAcordos();
  }, []);

  async function filterAcordos(page) {
    window.showLoader();

    let response = await AcordoService.listPagedAsync({
      ...filter,
      pageNumber: page
    });

    setPageData(response.data.data);

    window.hideLoader();
  }

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAsync();
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getSelectListRede() {
    let redes = await RedeService.getSelectList();
    if (redes.data) setRedes(redes.data.data); //([...[{}], ...redes.data.data]);
  }

  function add() {
    setAcordo({});
    setShowModal(true);
  }

  function update(acordo) {
    setAcordo(acordo);
    setShowModal(true);
  }

  function onCloseModal() {
    setAcordo({});
    filterAcordos();
  }

  return (
    <Layout>
      <PageTitle title="Acordos" />
      <div className="row mb-4">
        <div className="col-lg-12">
          <button type="button" className="btn btn-secondary" onClick={add}>
            + Novo Acordo
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3">
          <label>Motivo</label>
          <input
            type="text"
            className="form-control"
            value={filter.motivo}
            onChange={(e) => setFilter({ ...filter, motivo: e.target.value })}
          />
        </div>
        <div className="col-lg-3">
          <label>Rede</label>
          <Select
            styles={customControlStyles}
            isClearable
            clearValue={() => {
              setRedeSelecionada({});
            }}
            placeholder="Selecione..."
            options={redes}
            value={redes?.find((x) => x.value === redeSelecionada)}
            onChange={(selectedValue) => {
              setRedeSelecionada(selectedValue?.nome ?? {});
              setFilter({ ...filter, cliente: selectedValue?.value });
            }}
          />
        </div>
        <div className="col-lg-3" disabled>
          <label>Representada</label>
          <Select
            isClearable
            clearValue={() => {
              setRepresentadaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={representadas}
            value={representadas?.find((x) => x.value === representadaSelecionada)}
            onChange={(selectedValue) => {
              setFilter({ ...filter, representada: selectedValue?.value });
              setRepresentadaSelecionada(selectedValue?.value);
            }}
          />
        </div>

        <div className="col-lg-2 d-flex align-items-end">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => filterAcordos()}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>
        </div>
      </div>

      {pageData.data && (
        <>
          <table className="table table-cadastro" style={{ marginTop: '20px' }}>
            <thead>
              <tr>
                <th>Representada</th>
                <th>Cliente</th>
                <th>Data Acordo</th>
                <th>Valor</th>
                <th>Nº Acordo</th>
                <th>Forma Pagto</th>
                <th>Motivo</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {(pageData?.data || []).map((acordo, index) => (
                <tr key={index}>
                  <td>{acordo.representada}</td>
                  <td>{acordo.rede}</td>
                  <td>{lib.getStringFromDateMinute(acordo.data)}</td>
                  <td>
                    <NumberFormat
                      value={acordo.valor}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={'R$ '}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>{acordo.numeroAcordo}</td>
                  <td>{acordo.acordoFormaPagamento}</td>
                  <td>{acordo.motivo}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm me-1"
                      title="Editar"
                      onClick={() => update(acordo)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <TablePagination pageData={pageData} onChangePage={(page) => filterLojas(page)} />
        </>
      )}
      <ModalCadastroAcordo
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={onCloseModal}
        entity={acordo}
      />
    </Layout>
  );
}
