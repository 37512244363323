import React from 'react';
import InputDocumento from '../_base/input-documento';
import InputTelefone from '../_base/input-telefone';
import ModalCadastro from '../_base/modal-cadastro';
import Errors from '../_base/errors';

import EquipeService from '../../services/equipe-service';

import lib from '../../helpers/lib';

export default function ModalCompletarCadastroUsuario(props) {
  const { showModal, setShowModal, onClose, invitation } = props;

  const formFields = {
    documento: { key: 'documento', required: true },
    nome: { key: 'nome', required: true },
    telefone: { key: 'telefone', required: true },
    senha: { key: 'senha', required: true },
    confirmarSenha: {
      key: 'confirmarSenha',
      validate: () => model.confirmarSenha?.length > 0 && model.senha === model.confirmarSenha
    }
  };

  const emptyModel = {
    documento: '',
    nome: '',
    telefone: '',
    senha: '',
    confirmarSenha: ''
  };

  const [model, setModel] = React.useState({ ...emptyModel });
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    setErrors({});

    setModel(
      lib.isNullOrEmptyObject(invitation)
        ? { ...emptyModel }
        : {
            ...emptyModel,
            email: invitation.email
          }
    );
  }, [invitation]);

  function updateModel(data) {
    setModel({ ...model, ...data });
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm()) {
      window.hideLoader();
      return;
    }

    setErrors({});

    let requestModel = {
      token: invitation?.token,
      usuario: model
    };

    let response = await EquipeService.acceptWithTokenAsync(requestModel);

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {
      if (onClose) onClose();

      setModel({ ...emptyModel });

      setShowModal(false);

      window.location = '/equipe';
      return;
    }

    setErrors(response.data.errors);
  }

  function validateForm() {
    let errors = {};

    //required fields
    for (let prop in formFields) {
      let field = formFields[prop];
      if (!field.required || model[field.key]?.length) continue;

      errors[field.key] = [];
    }
    //--

    if (lib.isNullOrEmptyObject(errors)) return true;

    setErrors(errors);

    return false;
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="COMPLETAR CADASTRO"
    >
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-lg-12 mb-2">
              <InputDocumento
                className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.documento.key]) ? '' : 'is-invalid'}`}
                placeholder="CNPJ ou CPF"
                value={model.documento}
                onChange={(value) => updateModel({ documento: value })}
              />
              <Errors errors={errors} keyName={formFields.documento.key} />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-2">
              <input
                type="text"
                className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.nome.key]) ? '' : 'is-invalid'}`}
                placeholder="Nome do Usuário"
                value={model.nome}
                onChange={(e) => updateModel({ nome: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.nome.key} />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-2">
              <InputTelefone
                className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.telefone.key]) ? '' : 'is-invalid'}`}
                placeholder="Telefone"
                value={model.telefone}
                onChange={(value) => updateModel({ telefone: value })}
              />
              <Errors errors={errors} keyName={formFields.telefone.key} />
            </div>

            <div className="col-lg-6 mb-2">
              <input
                type="email"
                className={`form-control`}
                placeholder="Email"
                value={model.email}
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-2">
              <input
                type="password"
                className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.senha.key]) ? '' : 'is-invalid'}`}
                placeholder="Senha"
                value={model.senha}
                onChange={(e) => updateModel({ senha: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.senha.key} />
            </div>

            <div className="col-lg-6 mb-2">
              <input
                type="password"
                className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.confirmarSenha.key]) ? '' : 'is-invalid'}`}
                placeholder="Confirme sua senha"
                value={model.confirmarSenha}
                onChange={(e) => updateModel({ confirmarSenha: e.target.value })}
              />
              <Errors errors={errors} keyName={formFields.confirmarSenha.key} />
            </div>
          </div>
        </form>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </ModalCadastro>
  );
}
