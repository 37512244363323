import React from 'react';
import Header from '../../header/index';
import Footer from '../../footer/index';
import Sidebar from '../../sidebar/index';

import { LoggedUserContext } from '../../../contexts/logged-user-context';

import lib from '../../../helpers/lib';

import './styles.scss';
import Invitations from '../../../pages/equipe/invitations';

const Layout = (props) => {
  const [userData, _] = React.useContext(LoggedUserContext);

  if (lib.isNullOrEmptyObject(userData)) return null;

  if (userData.convitesPendentes)
    return (
      <>
        <div id="layoutMain" className="layout-content d-flex flex-column h-100">
          <Sidebar lock={true}></Sidebar>
          <Header lock={true} />
          <main className="mb-auto container-fluid">
            <Invitations />
          </main>

          <Footer />
        </div>
      </>
    );

  if (!userData.planoAtivo && !userData.equipe?.equipeId)
    return (
      <>
        <div id="layoutMain" className="layout-content d-flex flex-column h-100">
          <Sidebar lock={true}></Sidebar>
          <Header lock={true} forceSelecionarEquipe={true} />
          <main className=" container-fluid"></main>

          <Footer />
        </div>
      </>
    );

  return (
    <>
      <div id="layoutMain" className="layout-content d-flex flex-column h-100">
        <Sidebar></Sidebar>
        <Header />
        <main className="mb-auto container-fluid">{props.children}</main>

        <Footer />
      </div>
    </>
  );
};

export default Layout;
