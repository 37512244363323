import React, { useEffect, useState, useRef } from 'react';
import { flushSync } from 'react-dom';
import { Tab, Tabs } from 'react-bootstrap';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import BigNumberMetaContratante from 'components/relatorios/big-number-meta-contratante';
import TableVendas from './table';
import DashboardService from 'services/dashboard-service';

import lib from 'helpers/lib';

export default function Vendas({ representadas }) {
  const data = new Date();

  const refFilter = useRef({});

  const [metaEquipe, setMetaEquipe] = useState(null);
  const [faturamentoEquipe, setFaturamentoEquipe] = useState([]);
  const [erroFaturamento, setErroFaturamento] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  // const [representadas, setRepresentadas] = useState();
  // data.setMonth(data.getMonth() - 1);

  const [filter, setFilter] = useState({
    representada: '',
    ano: data.getFullYear(),
    mes: data.getMonth() + 1
  });

  useEffect(async () => {
    const firstRepresentada = representadas ? representadas[0] : null;

    if (firstRepresentada) {
      setFilter({ ...filter, representada: firstRepresentada });
      await getRelatorio({ representada: firstRepresentada });
      setIsLoading(false);
    }
  }, [representadas]);

  async function getRelatorio({ representada = filter.representada }) {
    refFilter.current = getFilterParams();
    await Promise.all([getFaturamentoPorEquipe(representada), getCardsMensais(representada)]);
  }

  async function getFaturamentoPorEquipe(representada) {
    let response = await DashboardService.getFaturamentoPorEquipeAsync(
      getFilterParams(representada)
    );

    setFaturamentoEquipe(response.data.data);
    if (response.data.errors) {
      flushSync(() => setFaturamentoEquipe([]));
      setErroFaturamento(response.data.errors);
    } else {
      setErroFaturamento([]);
    }
  }

  async function getMetaEquipe(representada) {
    let response = await DashboardService.getMetaEquipeAsync(getFilterParams(representada));

    response.data && setMetaEquipe(response.data.data);
  }

  async function getCardsMensais(representada) {
    let response = await DashboardService.getCardsMensais(getFilterParams(representada));

    response.data && setMetaEquipe(response.data.data);
  }

  function getFilterParams(representada) {
    return {
      representadaId: representada ? representada.value : '',
      mes: filter.mes,
      ano: filter.ano
    };
  }

  function limpar() {
    setFilter({ representada: '', ano: data.getFullYear(), mes: data.getMonth() + 1 });
  }

  return (
    <div className="dashboard-page">
      <div className="col-12 relatorio-home">
        <div className="form-clientes pt-3">
          <div className="row align-items-end">
            {/* Representada ---------------------------------------------------------------------- */}
            <div className="col-lg-3 mb-3" disabled>
              <label>Representada</label>
              <Select
                className="select-form-control z-3"
                classNamePrefix="react-select-index"
                options={representadas}
                value={filter.representada}
                isClearable={false}
                onChange={(options) => setFilter({ ...filter, representada: options })}
                placeholder="Selecione..."
              />
            </div>

            <div className="col-lg-3 mb-3">
              <label>Mês</label>
              <Select
                className="select-form-control z-3"
                classNamePrefix="react-select-index"
                value={lib.selectListMeses?.find((item) => item.value === filter.mes)}
                options={lib.selectListMeses}
                isClearable={false}
                onChange={(item) => {
                  setFilter({ ...filter, mes: item.value });
                }}
                placeholder="Selecione..."
              />
            </div>

            <div className="col-lg-3 mb-3">
              <label>Ano</label>
              <Select
                className="select-form-control z-3"
                classNamePrefix="react-select-index"
                value={lib.selectListAnos?.find((item) => item.value === filter.ano)}
                options={lib.selectListAnos}
                isClearable={false}
                onChange={(item) => {
                  setFilter({ ...filter, ano: item.value });
                }}
                placeholder="Selecione..."
              />
            </div>

            <div className="col-lg-3 text-center align-items-center pt-3 pt-lg-0  mb-3">
              <button
                type="button"
                className="btn btn-secondary btn-action w-100"
                onClick={async () => {
                  flushSync(() => {
                    setIsLoading(true);
                    setFaturamentoEquipe([]);
                    setErroFaturamento([]);
                    setMetaEquipe(null);
                  });
                  await getRelatorio({ representada: filter.representada });
                  setIsLoading(false);
                }}
                disabled={isLoading}
              >
                <FontAwesomeIcon icon={faFilter} /> Filtrar
              </button>
            </div>
          </div>
        </div>

        <hr />
      </div>

      <div className="row m-0 pt-3" style={{ position: 'relative' }}>
        {!lib.isNullOrEmptyObject(erroFaturamento['SemMeta']) && (
          <div
            className="z-2 position-absolute error-sem-meta"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: '800px',
              height: 'fit-content',
              margin: 'auto',
              textAlign: 'center',
              background: '#c4c4c48c',
              padding: '40px',
              borderRadius: '20px',
              color: '#000000'
            }}
          >
            <h1 className="text-uppercase">
              {erroFaturamento['SemMeta'].map((descricao, index) => (
                <div key={index}>{descricao}</div>
              ))}
            </h1>
          </div>
        )}
        {/* {true && (
          <div
            className="z-2 position-absolute error-sem-meta"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: '800px',
              height: 'fit-content',
              margin: 'auto',
              textAlign: 'center',
              background: '#c4c4c48c',
              padding: '40px',
              borderRadius: '20px',
              color: '#000000'
            }}
          >
            <h1 className="text-uppercase">Erro ao carregar o relatório</h1>
          </div>
        )} */}

        <div className="col-lg-12 shadow p-3 mb-5 bg-white rounded border-radius: 150px">
          <BigNumberMetaContratante source={metaEquipe} />
        </div>
        <div className="col-lg-12 shadow p-3 mb-5 bg-white rounded border-radius: 150px">
          <div className="table-responsive">
            {faturamentoEquipe.faturamentos &&
            faturamentoEquipe.faturamentos.length > 0 &&
            !faturamentoEquipe.isSupervisor ? (
              faturamentoEquipe.faturamentos.map((equipe) => (
                <TableVendas equipe={equipe} key={equipe.equipeId} />
              ))
            ) : faturamentoEquipe.faturamentos && faturamentoEquipe.faturamentos.length > 0 ? (
              <Tabs
                defaultActiveKey={faturamentoEquipe.faturamentos[0].equipeNome}
                id="tabs-relatorio"
                className="mb-3"
              >
                {faturamentoEquipe.faturamentos.map((equipe, idx) => (
                  <Tab
                    eventKey={equipe.equipeNome}
                    title={equipe.equipeNome}
                    key={`${equipe.equipeId}-${idx}`}
                  >
                    {Object.hasOwn(equipe, 'categoriaRelatorio') && (
                      <TableVendas equipe={equipe} key={equipe.equipeId} />
                    )}
                    {Object.hasOwn(equipe, 'faturamentoUsuarios') &&
                      equipe.faturamentoUsuarios.length > 0 &&
                      equipe.faturamentoUsuarios.map((vendedor, index) => (
                        <TableVendas equipe={vendedor} key={index} />
                      ))}
                  </Tab>
                ))}
              </Tabs>
            ) : (
              <Skeleton count={10} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
