import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';

export default function PoliticaPrivacidade() {
  return (
    // <>termos</>
    <>
      <Header />
      <div id="layoutMain" className="layout-content d-flex flex-column h-100">
        <main className="mb-auto container-fluid">
          <p>
            <b>TERMOS E CONDIÇÕES DE USO DE APLICATIVO</b>
          </p>
          <p>
            Estes “Termos e Condições de Uso do Aplicativo” (“T&C”) é um acordo legal entre o
            licenciado (pessoa física ou jurídica) (o “LICENCIADO”) e a PRATICOLAB SISTEMAS LTDA ,
            pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 47.428.815/0001-30, com
            sede na cidade de São Paulo, Estado de São Paulo, na Av Conselheiro Carrão, 2620 –
            conjunto 414, Bairro Vila Carrão, CEP: 03402-002, doravante denominada simplesmente
            “PRÁTICO VENDAS”, única e exclusiva proprietária do Portal com foco em disponibilização
            de um Sistema de Pedidos (ora em diante simplesmente “Aplicativo”), das marcas, nomes e
            do domínio associado à marca PRÁTICO VENDAS.
          </p>
          <p>
            PRATICOLAB SISTEMAS LTDA, sociedade limitada, inscrita no CNPJ/MF sob nº.
            47.428.815/0001-30, com sede na cidade de São Paulo, Estado de São Paulo, na Av
            Conselheiro Carrão, 2620 – conjunto 414, Bairro Vila Carrão, CEP: 03402-002
          </p>
          <p>
            <b>DAS DEFINIÇÕES</b>
          </p>
          <p>
            Os termos a seguir constantes neste T&C terão o significado estabelecido abaixo, seja no
            plural ou no singular, sempre que usados em letras maiúsculas: Portal: significa o local
            onde o Aplicativo será disponibilizado para acesso do LICENCIADO, cujo endereço
            eletrônico é o seguinte: www.praticovendas.com.br;
          </p>
          <p>
            Aplicativo: significa o software de propriedade exclusiva da PRÁTICO VENDAS por meio do
            qual o LICENCIADO poderá gerir, controlar e emitir pedidos de venda.
          </p>
          <p>
            Informações de Cadastro: Conjunto de informações disponibilizadas pelo LICENCIADO que o
            identifique, tais como: nome, endereço, data de nascimento, número de telefone, fax,
            e-mail, número de documentos.
          </p>
          <p>
            Informações: Entenda-se o conjunto de dados operacionais, de natureza dinâmica e
            intrínseco às atividades comerciais do LICENCIADO, que constituirá seu banco de dados,
            para entre outros, gerir, controlar e emitir pedidos de venda através do Aplicativo.
          </p>
          <p>
            Interface de Programação de Aplicações (API): é um conjunto de rotinas e padrões
            estabelecidos por um software para a utilização das suas funcionalidades por aplicativos
            que não pretendem envolver-se em detalhes da implementação do software, mas apenas usar
            seus serviços.
          </p>
          <p>1. DECLARAÇÃO DE VONTADE:</p>
          <p>
            1.1. O LICENCIADO DECLARA TER CIÊNCIA DOS DIREITOS E OBRIGAÇÕES DECORRENTES DO PRESENTE
            T&C, CONSTITUINDO ESTE INSTRUMENTO O ACORDO COMPLETO ENTRE AS PARTES. DECLARA, AINDA,
            TER LIDO, COMPREENDIDO E ACEITO TODOS OS TERMOS E CONDIÇÕES DESTE INSTRUMENTO.
          </p>
          <p>
            1.2. A partir do momento em que o LICENCIADO aceitar este T&C, as disposições aqui
            constantes regerão a relação entre a PRÁTICO VENDAS e o LICENCIADO, razão pela qual é
            recomendável que o LICENCIADO imprima uma cópia deste documento para futura referência.
          </p>
          <p>
            1.3. O LICENCIADO se declara ciente de que as operações que corresponderem àaceitação de
            determinadas opções serão registradas nos bancos de dados da PRÁTICO VENDAS, juntamente
            com a data e hora em que o aceite foi manifestado pelo LICENCIADO, podendo tal
            informação ser utilizada como prova da aceitação da opção pelo LICENCIADO,
            independentemente de outra formalidade.
          </p>
          <p>2. INFORMAÇÕES CONFIDENCIAIS E POLÍTICA DE SEGURANÇA DO USUÁRIO</p>
          <p>
            2.1. A PRÁTICO VENDAS se compromete a manter a confidencialidade, integridade e em
            segurança quaisquer Informações disponibilizadas pelo LICENCIADO relacionadas a seus
            Usuários e/ou Equipamentos de TI.
          </p>
          <p>
            2.2. Com a finalidade de garantir a privacidade do LICENCIADO, bem como a segurança de
            suas Informações, a PRÁTICO VENDAS se compromete a regularmente reavaliar a sua política
            de segurança e adaptá-la, conforme necessário.
          </p>
          <p>
            2.3. Em nenhuma hipótese, a PRÁTICO VENDAS irá vender ou disponibilizar as Informações
            do LICENCIADO ou geradas através do uso do Aplicativo, sendo certo que somente as
            utilizará para as seguintes finalidades, com as quais o LICENCIADO expressamente
            concorda e anui:
          </p>
          <p>
            a) Para enviar ao LICENCIADO qualquer notificação administrativa, alertas ecomunicados
            relevantes para o mesmo;
            <br /> b) Para cumprir com a finalidade do Portal ou do Aplicativo;
            <br />
            c) Identificar o perfil, desejos ou necessidades do LICENCIADO a fim de aprimorar os
            produtos e/ou serviços oferecidos pelo Aplicativo ou Portal;
            <br />
            d) Realizar estatísticas genéricas para monitoramento de utilização do Aplicativo;
            <br />
            e) Realizar estatísticas genéricas para monitoramento de utilização do Aplicativo;
            <br />
            f) Resolução de problemas no Portal ou Aplicativo, verificação e proteção
            dasInformações, do Portal ou Aplicativo contra erros, fraudes ou qualquer outro crime
            eletrônico.
          </p>
          <p>
            2.4. Caso haja qualquer alteração nas informações de cadastro fornecidas pelo
            LICENCIADO, este se compromete a informar a PRÁTICO VENDAS de tais alterações de modo a
            garantir o correto uso e funcionamento do Aplicativo.
          </p>
          <p>
            2.5. As Informações do LICENCIADO poderão ser transferidas a terceiros em decorrência da
            venda, aquisição, fusão, reorganização societária ou qualquer outra mudança no controle
            da PRÁTICO VENDAS. Caso ocorra qualquer destas hipóteses, no entanto, resultando na
            transferência das Informações a terceiros, o LICENCIADO será informado previamente e
            caso não deseje prosseguir com a utilização do Aplicativo, poderá excluir sua conta de
            acesso na forma prevista no Item 10 deste T&C. Nesta hipótese, as Informações do
            LICENCIADO serão apagadas do banco de dados da PRÁTICO VENDAS e não serão transmitidas a
            terceiros.
          </p>
          <p>
            2.6. Não obstante as Informações fornecidas pelo LICENCIADO estejam seguras nos termos
            deste T&C, o login e a senha de acesso ao Aplicativo são confidenciais e de
            responsabilidades exclusiva do LICENCIADO.
          </p>
          <p>
            2.7. Caso o LICENCIADO acredite que seu login e senha de acesso ao Portal tenham sido
            roubados ou sejam de conhecimento de outras pessoas, por qualquer razão, o LICENCIADO
            deverá imediatamente comunicar a PRÁTICO VENDAS na forma prevista no Item 6 deste T&C,
            sem prejuízo da alteração de senha imediata por meio do Portal ou do Aplicativo.
          </p>
          <p>3. DA REMUNERAÇÃO E FORMA DE PAGAMENTO:</p>
          <p>
            3.1. O LICENCIADO deve pagar à PRÁTICO VENDAS o valor do respectivo plano escolhido de
            acordo com a periodicidade definida entre as opções de pagamento disponibilizadas ao
            LICENCIADO no ato da contratação.
          </p>
          <p>
            3.2. Caso o LICENCIADO, no decorrer da vigência do presente instrumento, opte por outro
            plano de licenciamento, os valores serão alterados de forma proporcional (pro rata) de
            acordo com o respectivo plano escolhido.
          </p>
          <p>
            3.3. A falta de pagamento nas datas determinadas para seu vencimento acarretará na
            suspensão de acesso ao SOFTWARE até que as pendências financeiras sejam regularizadas.
          </p>
          <p>
            3.4. Caso a suspensão permaneça por prazo superior a 60 (sessenta) dias, a PRÁTICO
            VENDAS poderá excluir integralmente as informações lançadas no Aplicativo pelo
            LICENCIADO.
          </p>
          <p>
            3.5. Os valores estabelecidos no ato da formalização do aceite são para pagamento
            antecipado pelo LICENCIADO para posterior uso. Este valor poderá ser alterado a qualquer
            tempo pela PRÁTICO VENDAS, podendo o LICENCIADO não renovar o acesso ao Aplicativo caso
            discorde dos novos valores apresentados pela PRÁTICO VENDAS. Neste caso, o acesso ao
            Aplicativo será suspenso findo o período de validade do pagamento anterior.
          </p>
          <p>4. NÍVEL DE SERVIÇO</p>
          <p>
            4.1. A PRÁTICO VENDAS empreenderá esforços comercialmente razoáveis para tornar o
            Aplicativo disponível, no mínimo, 99% (noventa e nove por cento) durante cada Ano.
          </p>
          <p>
            4.1.1. O Compromisso de Nível de Serviço não se aplica às circunstâncias de
            indisponibilidade resultem (i) de uma interrupção do fornecimento de energia elétrica ou
            paradas emergenciais não superiores a 2 (duas) horas ou que ocorram no período das
            24:00h até às 6:00h; (ii) forem causadas por fatores que fujam ao cabível controle da
            PRÁTICO VENDAS, inclusive casos de força maior ou de acesso à Internet e problemas
            correlatos; (iii) resultem de quaisquer atos ou omissões do LICENCIADO ou de terceiros;
            (iv) resultem do equipamento de TI, software ou outras tecnologias que o LICENCIADO usar
            e/ou do equipamento de TI que impeçam o acesso regular ao Aplicativo; (v) resultem de
            falhas de instâncias individuais não atribuíveis à indisponibilidade do LICENCIADO.
          </p>
          <p>5. DA PROPRIEDADE INTELECTUAL</p>
          <p>
            5.1. O LICENCIADO não adquire, pelo presente instrumento, nenhum direito de propriedade
            intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas,
            direitos autorais ou direitos sobre informações confidenciais ou segredos de negócio,
            sobre ou relacionados ao Aplicativo ou nenhuma parte dele. O LICENCIADO também não
            adquire nenhum direito sobre ou relacionado ao Aplicativo ou qualquer componente dele,
            além dos direitos expressamente licenciados ao mesmo sob o presente T&C ou em qualquer
            outro contrato mutuamente acordado por escrito que o LICENCIADO possa ter celebrado com
            aPRÁTICO VENDAS. Quaisquer direitos não expressamente concedidos sob o presente
            instrumento são reservados.
          </p>
          <p>
            5.2. Caso o LICENCIADO venha a desenvolver um novo módulo ou produto que caracterize
            cópia, de todo ou em parte, quer seja do dicionário de dados, quer seja do programa,
            será considerado como parte do Aplicativo, ficando, portanto, sua propriedade
            incorporada pela PRÁTICO VENDAS e seu uso condicionado a estas cláusulas contratuais.
          </p>
          <p>
            5.3. Todo e qualquer conteúdo disponibilizado no Aplicativo ou no Portal, tais como, mas
            não se limitando a, textos, gráficos, imagens, logos, ícones, fotografias, conteúdo
            editorial, notificações, softwares e qualquer outro material, pertencem exclusivamente à
            PRÁTICO VENDAS e são protegidos pela lei brasileira no que se refere a propriedade
            intelectual e aos direitos autorais.
          </p>
          <p>6. DO ACESSO AO APLICATIVO</p>
          <p>
            6.1. Este Termo concede ao LICENCIADO uma licença revogável, onerosa, não exclusiva e
            intransferível de uso do Aplicativo, sendo certo que o LICENCIADO não poderá utilizar e
            nem permitir o uso do Aplicativo para qualquer outra finalidade não expressamente
            prevista neste instrumento.
          </p>
          <p>
            6.2. Por meio do Aplicativo o LICENCIADO, entre outros, terá acesso a uma ferramenta
            para gerir, controlar e emitir pedidos de venda, além de emitir relatórios de comissão e
            de vendas, obter histórico de vendas e comissões e controlar prepostos.
          </p>
          <p>
            6.3. Para tanto, o LICENCIADO realizará o seu cadastro no Aplicativo, fornecendo as suas
            Informações de Cadastro que serão utilizados para acesso ao conteúdo do Aplicativo.
          </p>
          <p>
            6.4. O LICENCIADO é responsável pela veracidade, validade e precisão das informações por
            ele fornecidas no seu cadastro, inclusive em relação à indicação de endereço de e-mail
            válido de sua titularidade, competindo-lhe, ainda, manter referido cadastro sempre
            atualizado.
          </p>
          <p>
            6.5. A PRÁTICO VENDAS não terá qualquer obrigação de policiar ou fiscalizar as
            informações fornecidas pelo LICENCIADO, mas poderá, ao seu exclusivo critério, excluir
            as informações que lhe pareçam inverídicas ou ofensivas.
          </p>
          <p>
            6.6. O login e senhas criados pelo LICENCIADO para acesso ao Aplicativo são
            confidenciais e de responsabilidade exclusiva do LICENCIADO, que deverá entrar em
            contato com a PRÁTICO VENDAS, imediatamente, na hipótese de comprometimento do seu
            sigilo.
          </p>
          <p>
            6.7. Em nenhuma hipótese, o LICENCIADO terá acesso ao código fonte do Aplicativo ora
            licenciado, por este se tratar de propriedade intelectual da PRÁTICO VENDAS.
          </p>
          <p>
            6.8. O LICENCIADO poderá, a qualquer tempo, realizar o cadastramento de usuários
            vinculados à sua conta. Uma vez cadastrados, esses usuários poderão acessar, modificar,
            incluir e excluir dados de sua conta, inclusive, podendo nomear outros usuários. Desta
            forma, o LICENCIADO isenta a PRÁTICO VENDAS de qualquer informação acessada, modificada,
            incluída ou excluída pelos usuários cujo acesso foi concedido exclusivamente pelo
            LICENCIADO.
          </p>
          <p>
            6.9. O LICENCIADO é responsável por qualquer software de terceiros utilizado para
            comunicar Informações à API da PRÁTICO VENDAS. Diante disso, qualquer acesso irregular,
            falhas de comunicação entre a API e os demais softwares são de responsabilidade
            exclusiva do LICENCIADO já que a PRÁTICO VENDAS apenas libera o acesso à API para que o
            LICENCIADO consiga realizar a comunicação entre o Aplicativo e outros softwares
            terceiros licenciados pelo LICENCIADO.
          </p>
          <p>7. DO USO DO APLICATIVO</p>
          <p>
            7.1. A PRÁTICO VENDAS poderá, ao seu exclusivo critério, a qualquer tempo, e sem a
            necessidade de comunicação prévia ao LICENCIADO:
          </p>
          <p>
            a) Encerrar, modificar ou suspender, total ou parcialmente, o acesso do LICENCIADO ao
            Aplicativo ou ao Portal, quando referido acesso ou cadastro estiver em violação das
            condições estabelecidas neste T&C;
          </p>
          <p>
            b) Excluir, total ou parcialmente, as informações cadastradas pelo LICENCIADO que não
            estejam em consonância com as disposições deste T&C; e,
          </p>
          <p>
            7.2. A qualquer tempo, mediante comunicação prévia ao LICENCIADO no endereço de e-mail
            por este indicado em seu cadastro pessoal ou por meio de aviso no próprio Aplicativo ou
            Portal, a PRÁTICO VENDAS poderá, ainda:
          </p>
          <p>
            a) Definir preços para oferecimento de determinados conteúdos e/ou serviços, ainda que
            inicialmente tenham sido ofertados de forma gratuita, sendo a utilização dos mesmos,
            após o referido aviso, considerada como concordância do LICENCIADO com tais preços; e,
          </p>
          <p>
            b) Enviar ao LICENCIADO mensagens de e-mail ou outras correspondências de caráter
            informativo, comercial e/ou promocional, salvo expressa solicitação em contrário pelo
            LICENCIADO.
          </p>
          <p>8. LIMITAÇÕES DE USO</p>
          <p>8.1. O LICENCIADO não poderá:</p>
          <p>
            a) Utilizar o Aplicativo para divulgar informações de qualquer forma que possa implicar
            em violação de normas aplicáveis no Brasil, de direitos de propriedade da PRÁTICO VENDAS
            e/ou de terceiros ou dos bons costumes, incluindo, sem limitação, a violação de direitos
            intelectuais, autorais e de privacidade, ou a produção e divulgação de conteúdo ilegal,
            imoral, inapropriado ou ofensivo;
          </p>
          <p>
            b) Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar,
            alienar de qualquer forma, transferir total ou parcialmente, sob quaisquermodalidades,
            gratuita ou onerosamente, provisória ou permanentemente, o Aplicativo, assim como seus
            módulos, partes, manuais ou quaisquer informaçõesrelativas ao mesmo;
          </p>
          <p>
            c) Empregar softwares, técnicas e/ou artifícios com o intuito de utilizar indevidamente
            o Aplicativo para práticas nocivas à PRÁTICO VENDAS ou a terceiros, tais como exploits,
            spamming, flooding, spoofing, crashing, root kits, etc.;
          </p>
          <p>
            d) Reproduzir, adaptar, modificar e/ou empregar, no todo ou em parte, para qualquer fim,
            o Aplicativo ou qualquer conteúdo do Portal sem a autorização expressa da PRÁTICO
            VENDAS;
          </p>
          <p>
            e) Publicar ou transmitir qualquer arquivo que contenha vírus, worms, cavalos de troia
            ou qualquer outro programa contaminante ou destrutivo, ou que de outra forma possa
            interferir no bom funcionamento Aplicativo;
          </p>
          <p>
            f) Utilizar o Aplicativo para finalidade diversa daquela para a qual foi disponibilizado
            pela PRÁTICO VENDAS; e,
          </p>
          <p>g) Realizar engenharia reversa.</p>
          <p>9. LIMITAÇÃO DE RESPONSABILIDADE E INDENIZAÇÃO</p>
          <p>
            9.1. A PRÁTICO VENDAS não responderá, em nenhuma hipótese, ainda que em caráter
            solidário ou subsidiário:
          </p>
          <p>
            a) Por eventuais prejuízos sofridos pelo LICENCIADO em razão da tomada de decisões com
            base nas informações disponibilizadas no Portal ou Aplicativo;
          </p>
          <p>
            b) Por eventuais prejuízos sofridos pelo LICENCIADO em razão de falhas no sistema de
            informática ou nos servidores que independam de culpa da PRÁTICO VENDAS ou em sua
            conectividade com a internet de modo geral, devendo o LICENCIADO manter, às suas
            expensas, linha de telecomunicação, modem, software de comunicação, endereço de correio
            eletrônico e outros recursos necessários à comunicação com a PRÁTICO VENDAS.
          </p>
          <p>
            c) Por situações de caso fortuito ou força maior, nos termos do artigo 393 do Código
            Civil Brasileiro. 9.2. A PRÁTICO VENDAS não garante que as funções contidas no
            Aplicativo atendam às suas necessidades, que a operação do Aplicativo será ininterrupta
            ou livre de erros, que qualquer funcionalidade continuará disponível, que os defeitos no
            Aplicativo serão corrigidos ou que o Aplicativo será compatível ou funcione com qualquer
            Aplicativo, aplicações ou serviços de terceiros.
          </p>
          <p>
            9.3. O LICENCIADO concorda em defender, indenizar e manter indene a PRÁTICO VENDAS e
            suas afiliadas, diretores, empregados e agentes, de e contra quaisquer encargos, ações
            ou demandas, incluindo, mas não limitado a, honorários advocatícios razoáveis,
            resultantes: (i) da sua eventual utilização indevida do Portal e do Aplicativo, ou (ii)
            da sua violação das condições ora pactuadas.
          </p>
          <p>
            9.4. Em nenhum caso a PRÁTICO VENDAS será responsável por danos pessoais ou qualquer
            prejuízo incidental, especial, indireto ou consequente, lucros cessantes, incluindo, sem
            limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de
            transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo
            ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o
            Aplicativo, por qualquer outro motivo.
          </p>
          <p>
            9.5. Na eventualidade da PRÁTICO VENDAS ser compelida, por decisão judicial transitada
            em julgado, a indenizar ou ressarcir o LICENCIADO por danos sofridos, apesar do disposto
            no Item 9.2 acima, o valor devido ao LICENCIADO será limitado à 20% (vinte por cento) da
            totalidade da quantia efetivamente paga pelo LICENCIADO à PRÁTICO VENDAS a título de
            fruição das funcionalidades oferecidas pelo Aplicativo.
          </p>
          <p>10. VIGÊNCIA E RESCISÃO</p>
          <p>
            10.1. Este T&C vigerá por prazo indeterminado, a partir do aceite do LICENCIADO,podendo
            ser modificado ou rescindido unilateralmente pela PRÁTICO VENDAS a qualquer tempo, sem
            qualquer ônus, mediante simples comunicação por meio do próprio Portal ou de mensagem
            para o e-mail indicado no cadastro pessoal do LICENCIADO.
          </p>
          <p>
            10.2. O LICENCIADO poderá, a qualquer momento, solicitar a suspensão e/ou a exclusão da
            sua conta de acesso ao Portal, encerrando seu relacionamento com a PRÁTICO VENDAS,
            mediante solicitação realizada diretamente por meio do Portal.
          </p>
          <p>
            10.3. Caso o LICENCIADO opte pela suspensão da sua conta de acesso ao Portal, os
            serviços prestados pela PRÁTICO VENDAS ao LICENCIADO serão paralisados, porém, as
            Informações serão mantidas no banco de dados da PRÁTICO VENDAS, sendo possível ao
            LICENCIADO reativar a sua conta de acesso a qualquer tempo e até pelo período de 60
            (sessenta) dias.
          </p>
          <p>11. MODIFICAÇÕES</p>
          <p>
            11.1. A PRÁTICO VENDAS poderá, a qualquer tempo, alterar este T&C ao seu exclusivo
            critério. Quaisquer alterações neste T&C serão informadas por meio do Portal ou do
            Aplicativo.
          </p>
          <p>
            11.2. Será sempre indicada a data da última atualização realizada pela PRÁTICO VENDAS no
            T&C.
          </p>
          <p>
            11.3. O LICENCIADO entende e concorda que, assim que publicada a alteração deste T&C no
            Portal ou no Aplicativo, o uso do Portal ou do Aplicativo passa a ser submetido ao T&C
            atualizado.
          </p>
          <p>12. DISPOSIÇÕES GERAIS</p>
          <p>
            12.1. Este T&C não gera nenhum contrato de sociedade, de mandato, franquia ou relação de
            trabalho entre a PRÁTICO VENDAS e o LICENCIADO, seus Parceiros e/ou Anunciantes.
          </p>
          <p>
            12.2. Caso qualquer disposição deste T&C for considerada ilegal, nula ou inexequível por
            qualquer razão, as restantes disposições não serão afetadas e manter-se-ão válidas e
            aplicáveis na máxima extensão possível.
          </p>
          <p>
            12.3. Este T&C constitui a totalidade do acordo sobre as condições de uso do Portal e do
            Aplicativo. O LICENCIADO declara ter ciência dos direitos e obrigações decorrentes do
            presente T&C, tendo lido, compreendido e aceito todos os termos e condições.
          </p>
          <p>
            12.4. Qualquer falha da PRÁTICO VENDAS para impor ou exercer qualquer disposição deste
            T&C ou direitos conexos, não constitui uma renúncia a esse direito ou disposição.
          </p>
          <p>
            12.5. A tolerância de uma parte para com a outra quanto ao descumprimento de qualquer
            uma das obrigações assumidas neste contrato não implicará em novação ou renúncia de
            direito. A parte tolerante poderá, a qualquer tempo, exigir da outra parte o fiel e
            cabal cumprimento deste contrato.
          </p>
          <p>
            12.6. O LICENCIADO concorda que a PRÁTICO VENDAS possa divulgar o fechamento contrato
            para fins comerciais, fazendo menção ao nome e à marca do LICENCIADO em campanhas
            comerciais, podendo, inclusive, divulgar mensagens enviadas de forma escrita ou oral,
            por telefone, para uso em sites, jornais, revistas e outras campanhas, enquanto vigorar
            o presente T&C. O LICENCIADO aceita, ainda, receber notificações via correio eletrônico
            sobre treinamentos, parcerias e campanhas relacionadas ao Aplicativo.
          </p>
          <p>13. LEI E FORO APLICÁVEIS</p>
          <p>13.1. Este T&C será interpretado exclusivamente segundo as leis do Brasil.</p>
          <p>
            13.2. As partes elegem o Foro da Comarca de São Paulo, Estado de São Paulo, como o único
            competente para dirimir qualquer litígio resultante deste T&C.
          </p>
          <p>São Paulo, 23 de janeiro de 2023.</p>
        </main>
        <Footer />
      </div>
    </>
  );
}
