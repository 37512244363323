import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEraser } from '@fortawesome/free-solid-svg-icons';
import { flushSync } from 'react-dom';

import Vendas from './vendas';
import Clientes from './clientes';
import FaturamentoRelatorio from '../../pedido-faturamento-relatorio';
import DasboardBarChart from '../../dashboard-app/components/bar-chart/index';
import SelectMulti from '../../../components/_base/multi-select';

import DashboardService from 'services/dashboard-service';
import ProdutoService from 'services/produto-service';

import './styles.scss';
import lib from 'helpers/lib';
import Pedidos from './pedidos';
import RedeService from 'services/rede-service';

const Dashboard = () => {
  const data = new Date();

  const refFilter = useRef({});
  const [filter, setFilter] = useState({
    representada: null,
    redes: [],
    ano: data.getFullYear(),
    mes: data.getMonth() + 1
  });

  const [redes, setRedes] = useState();
  const [representadas, setRepresentadas] = useState();

  const [metaContratante, setMetaContratante] = useState({});
  const [clientesAtivos, setClientesAtivos] = useState([]);

  const [metaEquipe, setMetaEquipe] = useState(null);
  const [faturamentoEquipe, setFaturamentoEquipe] = useState([]);
  const [erroFaturamento, setErroFaturamento] = useState([]);

  const [loadingOnSearch, setLoadingOnSearch] = useState(false);

  useEffect(async () => {
    const firstRepresentada = await getSelectListRepresentada();

    await getSelectListRede();

    if (firstRepresentada) {
      flushSync(() => {
        setFilter({ ...filter, representada: firstRepresentada });
      });
    }
  }, []);

  async function getSelectListRepresentada() {
    let representadas = await ProdutoService.selectListRepresentadasAsync();

    if (representadas.data) {
      setRepresentadas(representadas.data.data);
      return representadas.data.data[0];
    }
  }

  function getFilterParams(representada) {
    let params = {
      representadaId: representada
        ? representada.value
          ? representada.value
          : representada.representada.value
        : refFilter.current.value,
      mes: filter.mes,
      ano: filter.ano
    };

    return params;
  }

  async function getSelectListRede() {
    const result = await RedeService.getSelectList();
    if (result.data) setRedes(result.data.data);
  }

  async function getClientesAtivos(representada) {
    const response = await DashboardService.getClientesAtivosAsync(getFilterParams(representada));

    if (response.data) setClientesAtivos(response.data.data);
  }

  async function getFaturamentoPorEquipe(representada) {
    let response = await DashboardService.getFaturamentoPorEquipeAsync(
      getFilterParams(representada)
    );

    setFaturamentoEquipe(response.data.data);
    if (response.data.errors) {
      flushSync(() => setFaturamentoEquipe([]));
      setErroFaturamento(response.data.errors);
    } else {
      setErroFaturamento([]);
    }
  }

  async function getCardsMensais(representada) {
    let response = await DashboardService.getCardsMensais(getFilterParams(representada));

    response.data && setMetaEquipe(response.data.data);
  }

  async function getRelatorio(representada) {
    refFilter.current = getFilterParams();

    await Promise.all([getSelectListRede()]);
  }

  async function getMetaCotratante() {
    let response = await DashboardService.getMetaContratanteAsync(refFilter.current);

    setMetaContratante(response.data.data);
  }

  async function limpar() {
    setFilter({
      representada: null,
      redes: [],
      ano: data.getFullYear(),
      mes: data.getMonth() + 1
    });
  }

  return (
    <div className="dashboard-page">
      <Tabs defaultActiveKey="Faturamento" id="tabs-relatorio" className="mb-3">
        <Tab eventKey="Faturamento" title="Faturamento">
          <FaturamentoRelatorio />
        </Tab>

        <Tab eventKey="Evolução Mensal" title="Evolução Mensal">
          <Pedidos representadas={representadas} redes={redes} />
        </Tab>

        <Tab eventKey="Vendas e Metas" title="Vendas e Metas">
          <Vendas representadas={representadas} />
        </Tab>

        <Tab eventKey="Base de Clientes" title="Base de Clientes">
          <Clientes representadas={representadas} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Dashboard;
