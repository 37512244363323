import React from 'react';
import { useCombobox } from 'downshift';
import { matchSorter } from 'match-sorter';

export default function AutoCompleteCreatable(props) {
  const newValue = props.newValue ?? 0;
  const disabled = props.disabled;
  const matchSorterKeys = props.matchSorterKeys ?? ['label'];
  const { className, placeholder, keyLabel, keyValue } = props;
  const { onCreateItem, ...downshiftProps } = props;
  const [inputItems, setInputItems] = React.useState(downshiftProps.items);
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    setHighlightedIndex,
    inputValue,
    openMenu,
    selectItem,
    selectedItem
  } = useCombobox({
    ...downshiftProps,
    items: inputItems,

    onInputValueChange: ({ inputValue }) => {
      let filteredItems = matchSorter(downshiftProps.items, inputValue || '', {
        keys: matchSorterKeys
      });

      const matches = matchSorter(downshiftProps.items, inputValue || '', {
        keys: matchSorterKeys,
        threshold: matchSorter.rankings.EQUAL
      });

      if (inputValue.length > 0 && matches.length === 0)
        filteredItems = [{ [keyLabel]: `${inputValue}`, [keyValue]: newValue }, ...filteredItems];

      if (filteredItems.length === 1) setHighlightedIndex(0);

      setInputItems(filteredItems);
    },

    onStateChange: (changes) => {
      if (changes.type === useCombobox.stateChangeTypes.InputBlur && !changes.selectedItem) {
        selectItem(inputItems[0]);
      }
    }
  });

  return (
    <div className="downshift-container position-relative">
      <input
        {...getInputProps({
          className: className,
          disabled: disabled,
          placeholder: placeholder,
          onFocus: openMenu
        })}
      />
      <ul className="position-absolute w-100 bg-white shadow" {...getMenuProps()}>
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item[keyLabel]}
            </li>
          ))}
      </ul>
    </div>
  );
}
