import React from 'react';
import Switch from 'react-switch';
import { matchSorter } from 'match-sorter';
import ModalCadastro from '../_base/modal-cadastro';
import FormGroupHeader from '../_base/form-group-header';
import Errors from '../_base/errors';

import EquipeService from '../../services/equipe-service';

import lib from '../../helpers/lib';

import './styles.scss';

export default function ModalCadastroDefaultEquipePermissoes(props) {
  const { showModal, setShowModal, onClose, equipePermissoes: ref_equipePermissoes } = props;

  const emptyEquipePermissoes = {
    equipe: {},
    permissoes: []
  };

  const [equipePermissoes, setEquipePermissoes] = React.useState({
    ...emptyEquipePermissoes
  });
  const [permissoes, setPermissoes] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    getPermissoes();
  }, []);

  React.useEffect(() => {
    reset();

    getEquipePermissoes();
  }, [ref_equipePermissoes]);

  function reset() {
    setErrors({});
    setEquipePermissoes({ ...emptyEquipePermissoes });
  }

  async function getEquipePermissoes() {
    if (ref_equipePermissoes?.equipe?.equipeId > 0) {
      window.showLoader();

      const response = await EquipeService.getDefaultEquipePermissoesAsync(ref_equipePermissoes);
      const equipePermissoes = response.data.data;
      if (equipePermissoes != null) setEquipePermissoes(equipePermissoes);

      window.hideLoader();
    }
  }

  async function getPermissoes() {
    let response = await EquipeService.listPermissoesAsync();
    setPermissoes(response.data.data);
  }

  async function saveAsync() {
    window.showLoader();

    setErrors({});

    let response = await EquipeService.saveDefaultEquipePermissoesAsync(equipePermissoes);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();
      setEquipePermissoes({ ...emptyEquipePermissoes });
      onClose();
      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      onClose={onClose}
      headerText={`${equipePermissoes?.equipe?.nome || ''} - PERMISSÕES`}
    >
      <div className="modal-body">
        <div className="row justify-content-center usuarioPermissoes">
          <div className="col-lg-11">
            <label className="list-item  mb-3">
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setEquipePermissoes({
                      ...equipePermissoes,
                      permissoes: [...permissoes]
                    });
                  } else {
                    setEquipePermissoes({
                      ...equipePermissoes,
                      permissoes: []
                    });
                  }
                }}
              />{' '}
              Selecionar todos
            </label>

            {(permissoes || []).map((permissao, index) => (
              <div key={index} className="d-block">
                <label className="list-item mb-3">
                  <Switch
                    className="me-1 d-inline-block align-middle"
                    checked={
                      (equipePermissoes.permissoes || []).findIndex(
                        (c) => c.name === permissao.name
                      ) !== -1
                    }
                    onChange={(checked) => {
                      let lista = equipePermissoes.permissoes || [];

                      if (checked) {
                        lista.push(permissao);
                        setEquipePermissoes({
                          ...equipePermissoes,
                          permissoes: [...lista]
                        });
                      } else {
                        let index = lista.findIndex((x) => x.name === permissao.name);

                        if (index !== -1) {
                          lista.splice(index, 1);
                          setEquipePermissoes({
                            ...equipePermissoes,
                            permissoes: [...lista]
                          });
                        }
                      }
                    }}
                  />
                  <span className="d-inline align-middle">
                    {permissao.descricao || permissao.name}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </ModalCadastro>
  );
}
