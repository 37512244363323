import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faKey,
  faChevronLeft,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import ImageLogo from '../../assets/imagens/logos/logoescrito.png';
import LoginService from '../../services/login-service';
import lib from '../../helpers/lib';
import ModalEsqueceuSenha from '../../components/modal-esqueceu-senha';

import styles from './styles.scss';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

export default function Login() {
  const [email, setEmail] = React.useState('');
  const [senha, setSenha] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [showPassword, setShowPassword] = React.useState(false);

  const [showModalEsqueceuSenha, setShowModalEsqueceuSenha] = React.useState(false);
  //const [showModalCadastrar, setShowModalCadastrar] = React.useState(false);

  const toggle = () => {
    setShowPassword(!showPassword);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const senhaAlterada = searchParams.get('senhaAlterada');

  async function entrar() {
    window.showLoader();

    localStorage.clear();

    setErrors({});

    const params = new URLSearchParams(window.location.search);
    const redirectTo = params.get('redirectTo');

    let requestData = {
      email: email,
      senha: senha,
      redirectTo: redirectTo
    };

    let result = await LoginService.loginAsync(requestData);

    if (result.status === lib.statusCode.ok) {
      window.location = result.data.data.urlRedirect;
    } else {
      setErrors(result.data.errors);
    }

    window.hideLoader();
  }

  function displayErrors() {
    if (lib.isNullOrEmptyObject(errors)) return null;

    let listaErrors = [];
    for (let p in errors) {
      listaErrors = [...listaErrors, ...errors[p]];
    }

    return (
      <div className="alert alert-danger text-start mb-0 mt-2">
        <ul className="m-0">
          {listaErrors.map((descricao, index) => (
            <li key={index}>{descricao}</li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <>
      <div className="login_full_container">
        <div className="row h-100">
          <div className="left_container col-xl-4 text-center">
            <div className="row">
              <div className="col-xl-12">
                <img src={ImageLogo} className="praticoapp-logo" alt="praticoapp-logo" />
              </div>
            </div>

            <div className="divider"></div>

            <div className="divider"></div>

            <div className="row">
              <div className="col-xl-12 mb-5">
                <div className="input-group input_group_single_line">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-xl-12 mb-5">
                <div className="input-group input_group_single_line">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                  </div>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    placeholder="Senha"
                    value={senha}
                    onChange={(e) => {
                      setSenha(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        entrar();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={toggle}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      {!showPassword ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      )}
                    </span>
                  </div>

                  {senhaAlterada == 'true' ? (
                    <div className="alert alert-success text-start col-12 mb-0 mt-2">
                      Senha alterada com sucesso!
                    </div>
                  ) : null}
                </div>

                {displayErrors()}
              </div>

              <div className="col-xl-12">
                <button type="button" className="btn btn-primary w-100" onClick={entrar}>
                  ENTRAR
                </button>

                <button
                  type="button"
                  className={`btn btn-link w-100 mt-4 ${styles.btn_link}`}
                  onClick={() => {
                    setShowModalEsqueceuSenha(true);
                  }}
                >
                  Esqueceu sua senha?
                </button>

                <div className={styles.divider}></div>
              </div>
            </div>
          </div>

          <div className="right_container col-xl-8 p-0 d-none d-xl-block">
            <div className="h-100"></div>
          </div>
        </div>
      </div>

      <ModalEsqueceuSenha
        showModal={showModalEsqueceuSenha}
        setShowModal={setShowModalEsqueceuSenha}
      />

      {/* <ModalCadastrar showModal={showModalCadastrar} setShowModal={setShowModalCadastrar} />  */}
    </>
  );
}
