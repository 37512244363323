import React from 'react';
import { Modal } from 'react-bootstrap';

import './styles.scss';

export default function ModalCadastro(props) {
  const { showModal, setShowModal, headerText, headerBg, size, onClose } = props;

  function close() {
    setShowModal(false);

    if (onClose) onClose();
  }

  return (
    <Modal
      className="modal-cadastro"
      show={showModal}
      onHide={close}
      animation={false}
      size={size ? size : 'xl'}
      centered
    >
      <div className={'modal-header ' + headerBg}>
        <h5 className="modal-title flex-fill text-center text-white">{headerText}</h5>
        <button
          type="button"
          className="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={close}
        ></button>
      </div>

      {props.children}
    </Modal>
  );
}
