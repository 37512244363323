import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import ImageLogo from '../../assets/imagens/logos/logoescrito.png';
import LoginService from '../../services/login-service';
import lib from '../../helpers/lib';

import styles from './styles.scss';

//http://localhost:3000/novasenha?user=2729f60d-b8eb-4fd6-8290-efe91c3747a5&token=CfDJ8C72nTCtBMREkt6HeVqY5nm%2f%2fA%2b0PscjzsDDqUw90zhBxVi%2fpj8IAl4FeYrd5zcGWAxGN%2fLQWrwbQw5PE7l5aIrd3KJFRqD6jYIYHkV6QakbevMPfRFixx9GLA6PfL8dChlG5bx%2bPdhPep1TyCUQ48jfqxjdwOUsIWXrVN9hqHGjqJuwuT9HK7MPJWZtEl1BEo%2fl3I3l%2fiQtOuGcLEusedftIRG0EkBrnWiy4NdaFiJO

export default function NovaSenha() {
  const [confirmarSenha, setConfirmarSenha] = React.useState('');
  const [novaSenha, setNovaSenha] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [success, setSuccess] = React.useState(false);

  async function salvar() {
    setSuccess(false);
    setErrors({});

    window.showLoader();

    const params = new URLSearchParams(window.location.search);
    const userId = params.get('user');
    const token = params.get('token');

    let requestData = {
      userId: userId,
      token: token,
      novaSenha: novaSenha,
      confirmarSenha: confirmarSenha
    };

    let result = await LoginService.novaSenhaAsync(requestData);

    if (result.status === lib.statusCode.ok) {
      setSuccess(true);
      window.location.href = '/login?senhaAlterada=true';
    } else {
      setErrors(result.data.errors);
    }

    window.hideLoader();
  }

  function displayErrors() {
    if (lib.isNullOrEmptyObject(errors)) return null;

    let listaErrors = [];
    for (let p in errors) {
      listaErrors = [...listaErrors, ...errors[p]];
    }

    return (
      <div className="alert alert-danger text-start mb-0 mt-2">
        <ul className="m-0">
          {listaErrors.map((descricao, index) => (
            <li key={index}>{descricao}</li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <>
      <div className="login_full_container">
        <div className="row h-100">
          <div className="left_container col-xl-4 text-center">
            <div className="row">
              <div className="col-xl-12">
                <img src={ImageLogo} className="praticoapp-logo" alt="praticoapp-logo" />
              </div>
            </div>

            <div className="divider"></div>
            <div className="divider"></div>

            <h4 className="mb-4">Digite sua nova senha:</h4>

            <form className="form-nova-senha">
              <div className="row">
                <div className="col-xl-12 mb-5">
                  <div className="input-group input_group_single_line">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                    </div>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Nova Senha"
                      value={novaSenha}
                      onChange={(e) => {
                        setNovaSenha(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-xl-12 mb-5">
                  <div className="input-group input_group_single_line">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                    </div>
                    <input
                      type="password"
                      className={`form-control ${novaSenha !== confirmarSenha && confirmarSenha?.length > 0 ? 'is-invalid' : ''}`}
                      placeholder="Confirmar Senha"
                      value={confirmarSenha}
                      onChange={(e) => {
                        setConfirmarSenha(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          salvar();
                        }
                      }}
                    />
                  </div>

                  {displayErrors()}

                  {success ? (
                    <div className="alert alert-success text-start mb-0 mt-2">
                      Senha alterada com sucesso!{' '}
                      <a href="/login?senhaAlterada=true" className="ms-2">
                        Fazer login
                      </a>
                    </div>
                  ) : null}
                </div>

                <div className="col-xl-12">
                  <button type="button" className="btn btn-primary w-100" onClick={salvar}>
                    Salvar
                  </button>

                  <a
                    href="/login"
                    type="button"
                    className={`btn btn-link w-100 ${styles.btn_link} mt-3`}
                  >
                    Voltar
                  </a>

                  <div className={styles.divider}></div>
                </div>
              </div>
            </form>
          </div>

          <div className="right_container col-xl-8 p-0 d-none d-xl-block">
            <div className="h-100"></div>
          </div>
        </div>
      </div>
    </>
  );
}
