import React from 'react';

import lib from '../../../helpers/lib';

const Errors = ({ errors, keyName, className, style }) => {
  if (lib.isNullOrEmptyObject(errors[keyName])) return null;

  return (
    <>
      {errors[keyName].map((descricao, index) => (
        <div key={index} className={`invalid-feedback-error ${className}`} style={style}>
          {descricao}
        </div>
      ))}
    </>
  );
};

export default Errors;
