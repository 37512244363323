import React from 'react';
import ModalCadastro from '../_base/modal-cadastro';
import FormGroupHeader from '../_base/form-group-header';
import Errors from '../_base/errors';

import GeneralService from '../../services/general-service';
import EquipeService from '../../services/equipe-service';

import lib from '../../helpers/lib';

export default function ModalCadastroEquipe(props) {
  const { showModal, setShowModal, onClose, entity } = props;

  const formFields = {
    nome: { key: 'nome', validate: () => equipe.nome?.length > 0 }
  };

  const emptyEquipe = {
    nome: ''
  };

  const [equipe, setEquipe] = React.useState({ ...emptyEquipe });
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    setErrors({});
    setEquipe(lib.isNullOrEmptyObject(entity) ? { ...emptyEquipe } : { ...entity });
  }, [entity]);

  function updateEquipe(data) {
    setEquipe({ ...equipe, ...data });
  }

  async function saveAsync() {
    window.showLoader();

    if (!validateForm()) {
      window.hideLoader();
      return;
    }

    setErrors({});

    let response =
      equipe.equipeId > 0
        ? await EquipeService.updateAsync(equipe)
        : await EquipeService.addAsync(equipe);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      let responseEquipe = response.data.data;

      if (onClose) onClose(responseEquipe);

      setEquipe({ ...emptyEquipe });

      setShowModal(false);
      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  function validateForm() {
    let errors = {};

    for (let prop in formFields) {
      let field = formFields[prop];

      if (formFields[field.key].validate && formFields[field.key].validate()) continue;

      errors[field.key] = [];
    }

    if (lib.isNullOrEmptyObject(errors)) return true;

    setErrors(errors);

    return false;
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="CADASTRAR NOVO TIME"
    >
      <div className="modal-body">
        <div className="row justify-content-center">
          <div className="col-lg-8 pt-4">
            <div className={`row mb-4 ${equipe.equipeId > 0 ? 'd-none' : ''}`}>
              <div className="col-lg-12 ">
                <h4 className="text-center">
                  Para cadastrar um novo time, preencha dos dados abaixo e depois convide pessoas
                  para fazer parte do seu time:
                </h4>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-12">
                <input
                  type="text"
                  className={`form-control ${lib.isNullOrEmptyObject(errors[formFields.nome.key]) ? '' : 'is-invalid'}`}
                  placeholder="Nome"
                  value={equipe.nome}
                  onChange={(e) => updateEquipe({ nome: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </ModalCadastro>
  );
}
