import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFilter, faPlus, faRecycle, faTrash } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title/index';
import ModalCadastroTabelaPreco from '../../components/modal-cadastro-tabela-preco';
import ModalConfirmacao from '../../components/modal-confirmacao';
import TablePagination from '../../components/table-pagination';
import Layout from '../../components/layout/base/index';

import TabelaPrecoService from '../../services/tabela-preco-service';

import lib from '../../helpers/lib';

import './styles.scss';

export default function TabelaPreco() {
  const [tabelaPreco, setTabelaPreco] = React.useState({});

  const [filter, setFilter] = React.useState({
    nome: '',
    representada: '',
    situacao: 'A'
  });

  const [pageData, setPageData] = React.useState({});

  const [showModal, setShowModal] = React.useState(false);
  const [showModalConfirmacao, setShowModalConfirmacao] = React.useState(false);

  React.useEffect(() => {
    filterTabelaPrecos();
  }, []);

  async function filterTabelaPrecos(page) {
    window.showLoader();

    let response = await TabelaPrecoService.listPagedAsync({ ...filter, pageNumber: page });

    setPageData(response.data.data);

    window.hideLoader();
  }

  async function getExtratoTabelaDePrecos(page) {
    window.showLoader();

    let response = await TabelaPrecoService.getExtratoTabelaPreco({
      ...filter,
      pageNumber: page
    });

    if (response && response.data) {
      let fileName = `extrato_tabela_de_precos_${lib.getCurrentDateFilename()}.xlsx`;
      lib.downloadFile(response.data.data, fileName);
    }

    window.hideLoader();
  }

  function add() {
    setTabelaPreco({});
    setShowModal(true);
  }

  function update(tabelaPreco) {
    setTabelaPreco(tabelaPreco);
    setShowModal(true);
  }

  function onCloseModal() {
    setTabelaPreco({});
    filterTabelaPrecos();
  }
  async function changeStatus(tabelaPreco) {
    setTabelaPreco(tabelaPreco);
    setShowModalConfirmacao(true);
  }

  async function updateStatus(tabelaPreco) {
    window.showLoader();

    let response = await TabelaPrecoService.updateStatusAsync(tabelaPreco);
    if (response.status === lib.statusCode.ok) await filterTabelaPrecos();

    window.hideLoader();
  }

  return (
    <Layout>
      <PageTitle title="Cadastro de Tabela Preço" />

      {/* Botao Nova TabelaPreco --------------------------------------------------------------------- */}
      <div className="row mb-4">
        <div className="col-lg-12">
          <button type="button" className="btn btn-secondary" onClick={add}>
            + Nova Tabela Preço
          </button>
        </div>
      </div>

      <div className="row mb-4 filter-bar3">
        <div className="col-lg-3">
          <label>Representada</label>
          <input
            type="text"
            className="form-control"
            value={filter.representada}
            onChange={(e) => setFilter({ ...filter, representada: e.target.value })}
          />
        </div>

        {/* Nome ---------------------------------------------------------------------- */}
        <div className="col-lg-3">
          <label>Nome</label>
          <input
            type="text"
            className="form-control"
            value={filter.nome}
            onChange={(e) => setFilter({ ...filter, nome: e.target.value })}
          />
        </div>

        {/* Situacao -------------------------------------------------------------------------- */}
        <div className="col-lg-2">
          <label>Situação</label>
          <Select
            styles={customControlStyles}
            options={lib.selectListSituacao}
            value={lib.selectListSituacao.find((x) => x.value === filter.situacao)}
            onChange={(selectedValue) => {
              setFilter({ ...filter, situacao: selectedValue.value });
            }}
          />
        </div>

        {/* Botao Filtrar --------------------------------------------------------------------- */}
        <div className="col-lg-2 d-flex align-items-center">
          <button type="button" className="btn btn-secondary" onClick={() => filterTabelaPrecos()}>
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>
        </div>

        {/* Botao Exportar --------------------------------------------------------------------- */}
        <div className="col-lg-2 d-flex align-items-center">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => getExtratoTabelaDePrecos()}
          >
            Exportar
          </button>
        </div>
      </div>

      {pageData.data && (
        <>
          <table className="table table-cadastro">
            <thead>
              <tr>
                <th>Representada</th>
                <th>Nome</th>
                <th>Situação</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {(pageData?.data || []).map((tabelaPreco, index) => (
                <tr key={index}>
                  <td>{tabelaPreco.representada}</td>
                  <td>{tabelaPreco.nome}</td>
                  <td>{tabelaPreco.ativo ? 'Ativo' : 'Inativo'}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm me-1"
                      title="Editar"
                      onClick={() => update(tabelaPreco)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      title={tabelaPreco.ativo ? 'Inativar' : 'Reativar'}
                      onClick={() => changeStatus(tabelaPreco)}
                    >
                      <FontAwesomeIcon icon={tabelaPreco.ativo ? faTrash : faRecycle} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <TablePagination pageData={pageData} onChangePage={(page) => filterTabelaPrecos(page)} />
        </>
      )}

      <ModalCadastroTabelaPreco
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={onCloseModal}
        entity={tabelaPreco}
      />

      {/* Modal Confirmacao Ativar/Inativar TabelaPreco -------------------------------- */}
      <ModalConfirmacao
        showModal={showModalConfirmacao}
        setShowModal={setShowModalConfirmacao}
        onClose={(result) => {
          if (result === 'yes') {
            updateStatus(tabelaPreco);
          }
        }}
      >
        <p>
          Deseja mesmo <b>{tabelaPreco?.ativo ? 'inativar' : 'reativar'}</b> a tabelaPreco "
          {tabelaPreco?.nome}"?
        </p>
      </ModalConfirmacao>
    </Layout>
  );
}
