import React, { useEffect, useState, useRef } from 'react';
import { flushSync } from 'react-dom';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';

import DashboardService from 'services/dashboard-service';
import ProdutoService from 'services/produto-service';

import lib from 'helpers/lib';
import { useDebouncedEffect } from 'helpers/useDebouncedEffect';

export default function Clientes({ representadas }) {
  const data = new Date();

  const refFilter = useRef({});

  const [filter, setFilter] = useState({
    representada: null,
    redes: [],
    ano: data.getFullYear(),
    mes: data.getMonth() + 1
  });
  const [filtroSetor, setFiltroSetor] = useState('');
  const [setorFiltrada, setSetorFiltrada] = useState([]);
  const [clientesAtivos, setClientesAtivos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function getFilterParams(representada) {
    let params = {
      representadaId: representada
        ? representada.value
          ? representada.value
          : representada.representada.value
        : filter.representada,
      mes: filter.mes,
      ano: filter.ano
    };

    return params;
  }

  useEffect(async () => {
    setSetorFiltrada(clientesAtivos);
    setFiltroSetor('');

    const firstRepresentada = representadas ? representadas[0] : null;

    if (firstRepresentada) {
      setFilter({ ...filter, representada: firstRepresentada });
      await getClientesAtivos(firstRepresentada);
      setIsLoading(false);
    }
  }, [representadas]);

  useEffect(() => {
    setSetorFiltrada(clientesAtivos);
    setFiltroSetor('');
  }, [clientesAtivos]);

  useDebouncedEffect(
    () => {
      if (filtroSetor) {
        const newSetorFiltrada = clientesAtivos?.canais.filter((canais) =>
          canais.canalNome.toLowerCase().includes(filtroSetor.toLowerCase())
        );
        setSetorFiltrada({ ...clientesAtivos, canais: newSetorFiltrada });
      } else if (filtroSetor.trim().length === 0) {
        setSetorFiltrada(clientesAtivos);
      } else {
        setSetorFiltrada(clientesAtivos);
      }
    },
    [filtroSetor],
    300
  );

  const limparFiltroSetor = () => {
    setFiltroSetor('');
    setSetorFiltrada([]);
  };

  async function getClientesAtivos(representada) {
    const response = await DashboardService.getClientesAtivosAsync(getFilterParams(representada));

    if (response.data) setClientesAtivos(response.data.data);
    setIsLoading(false);
  }

  return (
    <div className="dashboard-page">
      <div className="pt-3" style={{ position: 'relative' }}>
        <div>
          <div className="col-12 relatorio-home">
            <div className="form-clientes">
              <div className="row align-items-end">
                {/* Representada ---------------------------------------------------------------------- */}
                <div className="col-lg-3 mb-3" disabled>
                  <label>Representada</label>
                  <Select
                    className="select-form-control z-3"
                    classNamePrefix="react-select-index"
                    options={representadas}
                    value={filter.representada}
                    isClearable={false}
                    onChange={(options) => setFilter({ ...filter, representada: options })}
                    placeholder="Selecione..."
                  />
                </div>

                <div className="col-lg-3 mb-3">
                  <label>Mês</label>
                  <Select
                    className="select-form-control z-3"
                    classNamePrefix="react-select-index"
                    value={lib.selectListMeses?.find((item) => item.value === filter.mes)}
                    options={lib.selectListMeses}
                    isClearable={false}
                    onChange={(item) => {
                      setFilter({ ...filter, mes: item.value });
                    }}
                  />
                </div>

                <div className="col-lg-3 mb-3">
                  <label>Ano</label>
                  <Select
                    className="select-form-control z-3"
                    classNamePrefix="react-select-index"
                    value={lib.selectListAnos?.find((item) => item.value === filter.ano)}
                    options={lib.selectListAnos}
                    isClearable={false}
                    onChange={(item) => {
                      setFilter({ ...filter, ano: item.value });
                    }}
                  />
                </div>

                <div className="col-lg-3 text-center align-items-center pt-3 pt-lg-0  mb-3">
                  <button
                    type="button"
                    className="btn btn-secondary btn-action w-100"
                    onClick={() => {
                      flushSync(() => {
                        setIsLoading(true);
                      });
                      getClientesAtivos(filter.representada);
                    }}
                    disabled={isLoading}
                  >
                    <FontAwesomeIcon icon={faFilter} /> Filtrar
                  </button>
                </div>
              </div>
            </div>

            <hr />
          </div>

          <div className="table-responsive pt-3">
            {!isLoading ? (
              <Table striped bordered hover width="100%">
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <th colSpan="4">BASE DE CLIENTE</th>
                  </tr>
                  <tr>
                    {/* BASE DE CLIENTE */}
                    <th style={{ textAlign: 'center' }}>
                      SETOR
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control`}
                          placeholder="Filtrar Setor"
                          value={filtroSetor || ''}
                          onChange={(e) => setFiltroSetor(e.target.value)}
                        />
                        <span
                          className="input-group-text"
                          id="basic-addon2"
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => limparFiltroSetor()}
                        >
                          <FontAwesomeIcon icon={faTimes} color="#7a7a7a" />
                        </span>
                      </div>
                    </th>
                    <th style={{ textAlign: 'center' }}>TOTAL</th>
                    <th style={{ textAlign: 'center' }}>ATIVO</th>
                    <th style={{ textAlign: 'center' }}>%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      <b>TOTAL</b>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <b>{clientesAtivos.qtdTotalClientes}</b>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <b>{clientesAtivos.qtdClientesAtivos}</b>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <b>{clientesAtivos.percentualClientesAtivos}%</b>
                    </td>
                  </tr>
                  {(setorFiltrada?.canais && setorFiltrada?.canais.length
                    ? setorFiltrada
                    : clientesAtivos
                  )?.canais?.map((canal, idx) => (
                    <tr key={`${canal.canalNome}-${idx}`}>
                      <td style={{ textAlign: 'center' }}>{canal.canalNome}</td>
                      <td style={{ textAlign: 'center' }}>{canal.qtdClientes}</td>
                      <td style={{ textAlign: 'center' }}>{canal.qtdClientesAtivos}</td>
                      <td style={{ textAlign: 'center' }}>{canal.percentualClientesAtivos}%</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Skeleton count={10} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
