import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFilter, faRecycle, faTrash } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title/index';
import ModalCadastroRepresentada from '../../components/modal-cadastro-representada';
import TablePagination from '../../components/table-pagination';
import Layout from '../../components/layout/base/index';
import ModalConfirmacao from '../../components/modal-confirmacao';

import RepresentadaService from '../../services/representada-service';

import lib from '../../helpers/lib';

export default function Cliente() {
  const [representada, setRepresentada] = React.useState({});

  const [filter, setFilter] = React.useState({
    cnpj: '',
    razaoSocial: '',
    text: '',
    situacao: 'A'
  });

  const [pageData, setPageData] = React.useState({});
  const [opcoesQuantidade, setOpcoesQuantidade] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalConfirmacao, setShowModalConfirmacao] = React.useState(false);

  React.useEffect(() => {
    filterRepresentadas();
    getOpcoesQuantidadeAsync();
  }, []);

  async function filterRepresentadas(page) {
    window.showLoader();

    let response = await RepresentadaService.listPagedAsync({
      ...filter,
      pageNumber: page
    });

    setPageData(response.data.data);

    window.hideLoader();
  }

  function add() {
    setRepresentada({});
    setShowModal(true);
  }

  function update(representada) {
    setRepresentada(representada);
    setShowModal(true);
  }

  function onCloseModal() {
    setRepresentada({});
    filterRepresentadas();
  }

  async function changeStatus(representada) {
    setRepresentada(representada);
    setShowModalConfirmacao(true);
  }

  async function updateStatus(representada) {
    window.showLoader();

    let response = await RepresentadaService.updateStatusAsync(representada);
    if (response.status === lib.statusCode.ok) await filterRepresentadas();

    window.hideLoader();
  }

  async function getOpcoesQuantidadeAsync() {
    let response = await RepresentadaService.getOrderQuantConfigOptionsAsync();

    if (response && response.data && response.data.data && response.data.data)
      setOpcoesQuantidade(response.data.data);
  }

  return (
    <Layout>
      <PageTitle title="Cadastro de Representada" />

      {/* Botao Nova Representada --------------------------------------------------------------------- */}
      <div className="row mb-4">
        <div className="col-lg-12">
          <button type="button" className="btn btn-secondary" onClick={add}>
            + Nova Representada
          </button>
        </div>
      </div>

      <div className="row mb-4 filter-bar3">
        {/* CNPJ, Razao Social ou Nome Fantasia ----------------------------------------------- */}
        <div className="col-lg-7">
          <label>CNPJ, Razão Social ou Nome Fantasia</label>
          <input
            type="text"
            className="form-control"
            value={filter.text}
            onChange={(e) => setFilter({ ...filter, text: e.target.value })}
          />
        </div>

        {/* Situacao -------------------------------------------------------------------------- */}
        <div className="col-lg-2">
          <label>Situação</label>
          <Select
            styles={customControlStyles}
            options={lib.selectListSituacao}
            value={lib.selectListSituacao.find((x) => x.value === filter.situacao)}
            onChange={(selectedValue) => {
              setFilter({ ...filter, situacao: selectedValue.value });
            }}
          />
        </div>

        {/* Botao Filtrar --------------------------------------------------------------------- */}
        <div className="col-lg-2 d-flex align-items-center">
          <button type="button" className="btn btn-secondary" onClick={() => filterRepresentadas()}>
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>
        </div>
      </div>

      {pageData.data && (
        <>
          <table className="table table-cadastro">
            <thead>
              <tr>
                <th>Razão Social</th>
                <th>Nome Fantasia</th>
                <th>CNPJ</th>
                <th>Situação</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {(pageData?.data || []).map((representada, index) => (
                <tr key={index}>
                  <td>{representada.razaoSocial}</td>
                  <td>{representada.nomeFantasia}</td>
                  <td>{representada.cnpj}</td>
                  <td>{representada.ativo ? 'Ativo' : 'Inativo'}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm me-1"
                      title="Editar"
                      onClick={() => update(representada)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      title={representada.ativo ? 'Inativar' : 'Reativar'}
                      onClick={() => changeStatus(representada)}
                    >
                      <FontAwesomeIcon icon={representada.ativo ? faTrash : faRecycle} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <TablePagination pageData={pageData} onChangePage={(page) => filterRepresentadas(page)} />
        </>
      )}

      <ModalCadastroRepresentada
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={onCloseModal}
        entity={representada}
        opcoesCaixaria={opcoesQuantidade}
      />

      {/* Modal Confirmacao Ativar/Inativar Representada -------------------------------- */}
      <ModalConfirmacao
        showModal={showModalConfirmacao}
        setShowModal={setShowModalConfirmacao}
        onClose={(result) => {
          if (result === 'yes') {
            updateStatus(representada);
          }
        }}
      >
        <p>
          Deseja mesmo <b>{representada?.ativo ? 'inativar' : 'reativar'}</b> a representada "
          {representada?.razaoSocial}"?
        </p>
      </ModalConfirmacao>
    </Layout>
  );
}
