import ServiceClient from './service-client';

class TabelaPrecoService {
  static async listPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get('/tabelaPreco/list-paged', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExtratoTabelaPreco(filter) {
    try {
      var result = await ServiceClient.Post('/tabelaPreco/get-extrato-em-bytes', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addAsync(tabelaPreco) {
    try {
      var result = await ServiceClient.Post('/tabelaPreco/add', tabelaPreco);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(tabelaPreco) {
    try {
      var result = await ServiceClient.Post('/tabelaPreco/update', tabelaPreco);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateStatusAsync(tabelaPreco) {
    try {
      var result = await ServiceClient.Post('/tabelaPreco/update-status', {
        tabelaPrecoId: tabelaPreco.tabelaPrecoId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listProdutosAsync(tabelaPreco) {
    try {
      var result = await ServiceClient.Get('/tabelaPreco/list-produtos', {
        tabelaPrecoId: tabelaPreco.tabelaPrecoId,
        representadaId: tabelaPreco.representadaId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listAsync() {
    try {
      var result = await ServiceClient.Get('/tabelaPreco/list');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default TabelaPrecoService;
