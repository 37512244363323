import React from 'react';

import CardReceita from './receita';
import CardPreco from './preco';
import CardVolume from './volume';
import CardCliente from './cliente';

const Cards = ({ cards }) => {
  return (
    <div className="cards h-100">
      <div className="row d-flex justify-content-between mt-2 align-items-stretch">
        <CardReceita data={cards.receita} />
        <CardVolume data={cards.volume} />
        <CardPreco data={cards.preco} />
        <CardCliente data={cards.cliente} />
      </div>
    </div>
  );
};

export default Cards;
