import ServiceClient from './service-client';

class MetaVolumeService {
  static async getModelAsync({ representadaId, ano, mes }) {
    try {
      var result = await ServiceClient.Get('/metavolume/get-model', { representadaId, ano, mes });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getEquipeUsuarioModelAsync({ equipeId, usuarioId, representadaId, ano, mes }) {
    try {
      var result = await ServiceClient.Get('/metavolume/get-equipe-usuario-model', {
        equipeId,
        usuarioId,
        representadaId,
        ano,
        mes
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveAsync(model) {
    try {
      var result = await ServiceClient.Post('/metavolume/save', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async saveEquipeUsuarioAsync(model) {
    try {
      var result = await ServiceClient.Post('/metavolume/save-equipe-usuario', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async clearAsync(model) {
    try {
      var result = await ServiceClient.Post('/metavolume/clear', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async clearEquipeUsuarioAsync(model) {
    try {
      var result = await ServiceClient.Post('/metavolume/clear-equipe-usuario', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async clearEquipeUsuarioAllAsync(model) {
    try {
      var result = await ServiceClient.Post('/metavolume/clear-equipe-usuario-all', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default MetaVolumeService;
