import React, { useEffect } from 'react';
import Select from 'react-select';
import { useQuery, useQueryClient } from 'react-query';
import lib from '../../helpers/lib';

import TablePagination from '../../components/table-pagination';
import PageTitle from '../../components/page-title/index';
import Layout from '../../components/layout/base/index';
import DatePickerCustom from '../../components/_base/DatePicker';
import { LoggedUserContext } from '../../contexts/logged-user-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf, faFilter } from '@fortawesome/free-solid-svg-icons';

import PedidoService from '../../services/pedido-service';
import RepresentadaService from '../../services/representada-service';
import RedeService from '../../services/rede-service';
import PedidoStatusService from '../../services/pedido-status-service';

import './styles.scss';
import AlertPedidosAvaliacao from '../../components/alert-pedidos-avaliacao';

import PedidoFaturamentoService from '../../services/pedido-faturamento-service';
import NumberFormat from 'react-number-format';
import SelectMulti from '../../components/_base/multi-select';
import ModalResumoPedido from '../../components/modal-resumo-pedido';
import Skeleton from 'react-loading-skeleton';
import { flushSync } from 'react-dom';

export default function FaturamentoRelatorio() {
  const mesInicialFiltro = new Date(),
    y = mesInicialFiltro.getFullYear(),
    m = mesInicialFiltro.getMonth();
  const dataInicialFiltro = new Date(y, m, 1);
  const [userData, _] = React.useContext(LoggedUserContext);

  const queryClient = useQueryClient();

  const [filter, setFilter] = React.useState({ dataInicio: dataInicialFiltro });
  const [isLoading, setIsLoading] = React.useState(true);

  // const { data: pageData } = useQuery(
  //   ['faturamentos', filter],
  //   () => getExtratoFaturamento(filter),
  //   { refetchOnWindowFocus: false }
  // );
  const [pageData, setPageData] = React.useState();
  const [showModalResumo, setShowModalResumo] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [codigo, setCodigo] = React.useState('');
  const [dataInicial, setDataInicial] = React.useState(dataInicialFiltro);
  const [dataFinal, setDataFinal] = React.useState();
  const [representadas, setRepresentadas] = React.useState();
  const [representadaSelecionada, setRepresentadaSelecionada] = React.useState();
  const [vendedores, setVendedores] = React.useState();
  const [vendedorSelecionado, setVendedorSelecionado] = React.useState();
  const [redes, setRedes] = React.useState();
  const [redeSelecionada, setRedeSelecionada] = React.useState();
  const [lojas, setLojas] = React.useState();
  const [lojaSelecionada, setLojaSelecionada] = React.useState();
  const [statusSelectList, setStatusSelectList] = React.useState();
  const [status, setStatus] = React.useState([]);
  const [pedidoTipos, setPedidoTipos] = React.useState();
  const [pedidoTipoSelecionado, setPedidoTipoSelecionado] = React.useState([]);

  // ------------------------------------------------------------------------------------------
  // Efeitos
  useEffect(() => {
    (async () => {
      await getSelectListRepresentada();
      await getSelectListRede();
      await getSelectListLoja();
      await getSelectListStatus();
      await getSelectListVendedor();
      await getPedidoTipos();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // await queryClient.invalidateQueries(['faturamentos']);
      await getExtratoFaturamento(filter);
    })();
  }, [filter]);

  // ------------------------------------------------------------------------------------------
  // Funcoes
  const getExtratoFaturamento = async (filter) => {
    flushSync(() => {
      setIsLoading(true);
    });
    const response = await PedidoFaturamentoService.getExtratoFaturamentoAsync(filter);
    setPageData(response?.data?.data);
    setIsLoading(false);
    return response?.data?.data;
  };

  const getPedidoTipos = async () => {
    const response = await PedidoService.GetPedidoTiposAsync();
    setPedidoTipos(response?.data?.data ?? []);
  };

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAsync();
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getSelectListStatus() {
    let resultStatus = await PedidoStatusService.getSelectListSiglaAsync();
    if (resultStatus?.data?.data[0]?.options) {
      setStatusSelectList(resultStatus.data.data[0].options);
    } //([...[{}], ...representadas.data.data]);
  }

  async function getSelectListRede() {
    let redes = await RedeService.getSelectList();
    if (redes.data) setRedes(redes.data.data); //([...[{}], ...redes.data.data]);
  }

  async function getSelectListLoja() {
    let lojas = await PedidoService.getSelectListLoja();
    if (lojas.data) setLojas(lojas.data.data); //([...[{}], ...lojas.data.data]);
  }

  async function getSelectListVendedor() {
    let vendedores = await PedidoService.getSelectListVendedor();
    if (vendedores.data) setVendedores(vendedores.data.data); //([...[{}], ...representadas.data.data]);
  }

  function calcularPorcentagem(valorPedido, valorFaturado) {
    if (!valorPedido || !valorFaturado) return 0;

    const percentualFaturado = (100 * valorFaturado) / valorPedido;
    return percentualFaturado;
  }

  function calcularDiferenca(valorPedido, valorFaturado) {
    if (!valorPedido) return 0;
    if (valorFaturado && valorFaturado > valorPedido) return valorFaturado - valorPedido;

    return (valorPedido - valorFaturado) * -1; //pra aparecer negativo na tela
  }

  async function downloadExtratoExcel() {
    window.showLoader();
    const filter = {
      codigo: codigo,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada,
      representanteId: vendedorSelecionado,
      lojaId: lojaSelecionada,
      redeId: redeSelecionada,
      tipo: pedidoTipoSelecionado.map((x) => x.value),
      status: status.map((x) => x.value),
      pageNumber: 1
    };
    let result = await PedidoFaturamentoService.getExtratoFaturamentoExcelAsync(filter);

    if (result && result.data) {
      let fileName = `faturamento_${lib.getCurrentDateFilename()}.xlsx`;
      lib.downloadFile(result.data.data, fileName);
      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  async function downloadExtratoPdf() {
    window.showLoader();
    const filter = {
      codigo: codigo,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada,
      representanteId: vendedorSelecionado,
      lojaId: lojaSelecionada,
      redeId: redeSelecionada,
      tipo: pedidoTipoSelecionado.map((x) => x.value),
      status: status.map((x) => x.value),
      pageNumber: 1
    };
    let result = await PedidoFaturamentoService.getExtratoFaturamentoPdfAsync(filter);

    if (result && result.data) {
      let today = new Date();
      let date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
      let file = lib._base64ToByteArray(result.data);
      let blob = new Blob([file], { type: 'arraybuffer' });

      let fileName = 'faturamento_extrato_' + date + '.pdf';
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();
      document.body.removeChild(a);
      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  function getInvoiceStatusLetterColor(status) {
    if (!status) return '#000000';
    const lowerStr = status.toLowerCase();

    if (lowerStr == 'cancelado') return '#973446';
    if (lowerStr == 'faturado') return '#2a6128';
    if (lowerStr.includes('parcial')) return '#b08948';
    if (lowerStr.includes('em')) return '#525250';
    if (lowerStr.includes('revis')) return '#b08948';
    if (lowerStr.includes('não')) return '#973446';

    return '#000000';
  }

  function getDifferenceLetterColor(valorPedido, valorFaturado) {
    var porcentagem = calcularPorcentagem(valorPedido, valorFaturado);

    if (porcentagem < 100) return '#973446';
    if (porcentagem >= 100) return '#2a6128';

    return '#000000';
  }

  function getBackgroundColor(status) {
    if (!status) return '#FFFFFF';
    const lowerStr = status.toLowerCase();

    if (lowerStr == 'cancelado') return '#ffc6ce';
    if (lowerStr == 'faturado') return '#c3f1cd';
    if (lowerStr.includes('parcial')) return '#feeb9c';
    if (lowerStr.includes('em')) return '#e8ecef';
    if (lowerStr.includes('revis')) return '#feeb9c';
    if (lowerStr.includes('não')) return '#ffc6ce';

    return '#FFFFFF';
  }

  function openResumoPedido(item) {
    setSelected(item);
    setShowModalResumo(true);
  }

  // ------------------------------------------------------------------------------------------
  // Renderizacao
  return (
    <div>
      {/* Filter ------------------------------------------------------------------------------ */}
      <div className="row filter-bar2 pt-3">
        {/* Código  --------------------------------------------------------------------------- */}
        <div className="col-xl-4 mb-4">
          <label>Pedido</label>
          <input
            type="text"
            className="form-control"
            value={codigo}
            onChange={(ev) => {
              setCodigo(ev.target.value);
            }}
          />
        </div>
        {/* Representada ---------------------------------------------------------------------- */}
        <div className="col-xl-2 mb-2" disabled>
          <label>Representada</label>
          <Select
            isClearable
            clearValue={() => {
              setRepresentadaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={representadas}
            value={representadas?.find((x) => x.value === representadaSelecionada)}
            onChange={(selectedValue) => {
              //setRedeSelecionada(selectedValue?.nome ?? {});
              setRepresentadaSelecionada(selectedValue?.value);
            }}
          />
        </div>

        {/* Vendedor ---------------------------------------------------------------------- */}
        <div className="col-xl-2 mb-2">
          <label>Vendedor</label>
          <Select
            isClearable
            clearValue={() => {
              setVendedorSelecionado({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={vendedores}
            value={vendedores?.find((x) => x.value === vendedorSelecionado)}
            onChange={(selectedValue) => {
              setVendedorSelecionado(selectedValue?.value);
            }}
          />
        </div>
        {/* Rede ------------------------------------------------------------------------------ */}
        <div className="col-xl-2 mb-2" disabled>
          <label>Rede</label>
          <Select
            isClearable
            clearValue={() => {
              setRedeSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={redes}
            value={redes?.find((x) => x.value === redeSelecionada)}
            onChange={(selectedValue) => setRedeSelecionada(selectedValue?.value)}
          />
        </div>
        {/* Loja ------------------------------------------------------------------------------ */}
        <div className="col-xl-2 mb-2">
          <label>Loja</label>
          <Select
            isClearable
            clearValue={() => {
              setLojaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={lojas}
            value={lojas?.find((x) => x.value === lojaSelecionada)}
            onChange={(selectedValue) => setLojaSelecionada(selectedValue?.value)}
          />
        </div>
      </div>

      {/* Filter date-------------------------------------------------------------------------- */}
      <div className="row ">
        <div className="col-xl-2 mb-3" disabled>
          <label>Tipo</label>
          <SelectMulti
            isClearable
            clearValue={() => {
              setPedidoTipoSelecionado([]);
            }}
            placeholder="Selecione..."
            options={pedidoTipos}
            value={pedidoTipoSelecionado}
            onChange={(options) => {
              setPedidoTipoSelecionado(options);
            }}
          />
        </div>
        <div className="col-xl-2 mb-2">
          <label>Pedido - Data Inicial</label>
          <DatePickerCustom
            name="dataInicial"
            selected={dataInicial}
            onChange={(value) => {
              setDataInicial(value);
            }}
          />
        </div>
        <div className="col-xl-2 mb-2">
          <label>Pedido - Data Final</label>
          <DatePickerCustom
            name="dataFinal"
            selected={dataFinal}
            onChange={(value) => {
              setDataFinal(value);
            }}
          />
        </div>

        <div className="col-xl-2 mb-3" disabled>
          <label>Status</label>
          <SelectMulti
            isClearable
            clearValue={() => {
              setStatus([]);
            }}
            placeholder="Selecione..."
            options={statusSelectList}
            value={status}
            onChange={(options) => {
              setStatus(options);
            }}
          />
        </div>

        <div className="col-xl-4 mb-3 d-flex gap-2 text-center align-items-end pt-3 pt-lg-0  mb-3">
          {/* Botão Filtrar --------------------------------------------------------------------- */}
          <button
            type="button"
            className="btn btn-secondary btn-action w-100"
            onClick={() => {
              setFilter({
                ...filter,
                codigo: codigo,
                dataInicio: dataInicial,
                dataFim: dataFinal,
                representadaId: representadaSelecionada,
                representanteId: vendedorSelecionado,
                lojaId: lojaSelecionada,
                redeId: redeSelecionada,
                tipo: pedidoTipoSelecionado.map((x) => x.value),
                status: status.map((x) => x.value),
                pageNumber: 1
              });
            }}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>

          {/* Botão Exportar EXCEL-------------------------------------------------------------------- */}
          <button
            type="button"
            className="btn btn-secondary  w-100 btn-action"
            onClick={() => {
              downloadExtratoExcel();
            }}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={faFileExcel} /> Excel
          </button>
          {/* Botão Exportar PDF -------------------------------------------------------------------- */}
          <button
            type="button"
            className="btn btn-secondary w-100 btn-action"
            onClick={() => {
              downloadExtratoPdf();
            }}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={faFilePdf} /> PDF
          </button>
        </div>
      </div>

      <hr />

      {/* Lista Representadas ----------------------------------------------------------------- */}
      <div className="table-responsive pt-3">
        {!isLoading && pageData?.data ? (
          <table className="table table-cadastro">
            <thead>
              <tr>
                <th>Data Pedido</th>
                <th>Pedido na Repres.</th>
                <th>Vendedor</th>
                <th>Rede</th>
                <th>Loja</th>
                <th>Representada</th>
                <th>Status</th>
                <th>Pedido</th>
                <th>Faturado</th>
                <th>% Faturado</th>
                <th>Diferença</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '10px' }}>
              {(pageData?.data || []).map((item, index) => (
                <tr key={index}>
                  <td>{lib.getStringFromDateMinute(item.data)}</td>
                  <td>
                    <a
                      href="#"
                      onClick={() => {
                        openResumoPedido(item);
                      }}
                    >
                      {item?.codigoPedidoLoja
                        ? item?.codigoPedidoLoja
                        : item?.codigoPedidoRepresentada}
                    </a>
                  </td>
                  <td>{item.vendedor}</td>
                  <td>{item.rede}</td>
                  <td>{item.loja}</td>
                  <td>{item.representada}</td>
                  <td style={{ background: getBackgroundColor(item.pedidoStatus) }}>
                    <a
                      href="#"
                      onClick={() => {
                        openResumoPedido(item);
                      }}
                      style={{
                        color: getInvoiceStatusLetterColor(item.pedidoStatus)
                      }}
                    >
                      <span
                        style={{
                          color: getInvoiceStatusLetterColor(item.pedidoStatus)
                        }}
                      >
                        {item.pedidoStatus}
                      </span>
                    </a>
                  </td>
                  <td>
                    {item.valorPedido && (
                      <NumberFormat
                        value={item.valorPedido}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'R$ '}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    )}
                  </td>
                  <td>
                    {item.valorFaturado && (
                      <NumberFormat
                        value={item.valorFaturado}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'R$ '}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    )}
                  </td>
                  <td>
                    {item.valorFaturado && (
                      <NumberFormat
                        value={calcularPorcentagem(item.valorPedido, item.valorFaturado)}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={''}
                        suffix=""
                        decimalScale={2}
                        fixedDecimalScale={false}
                      />
                    )}
                    {!item.valorFaturado && (
                      <NumberFormat
                        value={0}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={''}
                        decimalScale={2}
                        fixedDecimalScale={false}
                      />
                    )}
                    &nbsp;%
                  </td>
                  <td
                    style={{
                      color: getDifferenceLetterColor(item.valorPedido, item.valorFaturado)
                    }}
                  >
                    <NumberFormat
                      value={calcularDiferenca(item.valorPedido, item.valorFaturado)}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={'R$ '}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Skeleton count={10} />
        )}
      </div>

      {/* Paginacao --------------------------------------------------------------------------- */}
      {!isLoading && (
        <TablePagination
          pageData={pageData}
          onChangePage={(pageNumber) => {
            setFilter({ ...filter, pageNumber: pageNumber });
          }}
        />
      )}

      {/* Modal Resumo Pedido ----------------------------------------------------------------- */}
      <ModalResumoPedido
        model={selected}
        showModal={showModalResumo}
        setShowModal={setShowModalResumo}
      />
    </div>
  );
}
