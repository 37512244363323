import React, { useEffect } from 'react';
import Select, { StylesConfig } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';

import PageTitle from '../../components/page-title/index';
import Layout from '../../components/layout/base/index';
import DatePickerCustom from '../../components/_base/DatePicker';
import ModalFaturamentoNota from '../../components/modal-faturamento-nota';
import ModalPedidoFaturamento from '../../components/modal-pedido-faturamento';
import ModalConfirmacao from '../../components/modal-confirmacao';

import PedidoFaturamentoHistoricoService from '../../services/pedido-faturamento-historico-service';
import RepresentadaService from '../../services/representada-service';
import PedidoFaturamentoComissaoService from '../../services/pedido-faturamento-comissao-service';
import lib from '../../helpers/lib';

import './styles.scss';
import TablePagination from '../../components/table-pagination';
import { faTimes, faPencil } from '@fortawesome/free-solid-svg-icons';

export default function PedidoFaturamentoHistorico() {
  const [pageData, setPageData] = React.useState({});
  const [representadas, setRepresentadas] = React.useState();
  const [codigo, setCodigo] = React.useState('');
  const [nota, setNota] = React.useState('');
  const [dataInicial, setDataInicial] = React.useState();
  const [dataFinal, setDataFinal] = React.useState();
  const [filter, setFilter] = React.useState({});
  const [representadaSelecionada, setRepresentadaSelecionada] = React.useState();
  const [errors, setErrors] = React.useState({});
  const [modalFaturamentoNotaData, setModalFaturamentoNotaData] = React.useState({});

  const [selected, setSelected] = React.useState(null);
  const [showModalFaturar, setShowModalFaturar] = React.useState(false);
  const [showModalRemover, setShowModalRemover] = React.useState(false);

  let entityArray = pageData?.data;

  async function remover() {
    window.showLoader();

    let response = await PedidoFaturamentoComissaoService.removerAsync(selected);

    window.hideLoader();

    if (response.status === lib.statusCode.ok) {
      //remover do array
      let entityIndex = entityArray.indexOf(selected);
      entityArray.splice(entityIndex, 1);
      //--

      refresh();
    } else {
      window.scrollTo(0, 0);
      setErrors(response.data.errors);
    }
  }

  async function closeModal() {
    setShowModalFaturar(false);
    getListFaturamento();
  }

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAsync();
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getListFaturamento(page) {
    window.showLoader();

    let response = await PedidoFaturamentoHistoricoService.listPagedAsync({
      ...filter,
      pageNumber: page
    });

    setPageData(response.data.data);

    window.hideLoader();
  }

  useEffect(() => {
    (async () => {
      await getSelectListRepresentada();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      getListFaturamento();
    })();
  }, [filter]);

  function onEnterKey(event) {
    if (event.key === 'Enter') {
      updateFilter();
    }
  }

  function updateFilter() {
    setFilter({
      ...filter,
      nota: nota,
      codigo: codigo,
      dataInicio: dataInicial,
      dataFim: dataFinal,
      representadaId: representadaSelecionada
    });
  }

  function refresh() {
    //remover propriedade novoValorComissaoRecebida
    let faturamentoNotas = pageData?.data.map(
      ({ novoValorComissaoRecebida, ...faturamentoNota }) => faturamentoNota
    );

    pageData.data = faturamentoNotas;

    setPageData({ ...pageData });
  }

  let totalPrevisto = (pageData?.data || []).reduce(
    (total, f) => total + (f.comissaoPrevista ?? 0),
    0
  );
  let totalRecebido = (pageData?.data || []).reduce(
    (total, f) => total + (f.comissaoRecebida ?? 0),
    0
  );
  let total = totalPrevisto - totalRecebido;

  // ------------------------------------------------------------------------------------------
  // Renderizacao
  return (
    <Layout>
      <div className="pedido-faturamento-page">
        <PageTitle title="Histórico de Notas"></PageTitle>

        {/* Filter ------------------------------------------------------------------------------ */}
        <div className="row filter-bar2">
          {/* Nota  --------------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Nº Nota</label>
            <input
              type="text"
              className="form-control"
              value={nota}
              onChange={(ev) => {
                setNota(ev.target.value);
              }}
              onKeyPress={onEnterKey}
            />
          </div>

          {/* Pedido  --------------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Pedido</label>
            <input
              type="text"
              className="form-control"
              value={codigo}
              onChange={(ev) => {
                setCodigo(ev.target.value);
              }}
              onKeyPress={onEnterKey}
            />
          </div>

          {/* Representada ---------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3" disabled>
            <label>Representada</label>
            <Select
              className="select-form-control"
              isClearable
              clearValue={() => {
                setRepresentadaSelecionada({});
              }}
              placeholder="Selecione..."
              options={representadas}
              value={representadas?.find((x) => x.value === representadaSelecionada)}
              onChange={(selectedValue) => {
                setRepresentadaSelecionada(selectedValue?.value);
              }}
            />
          </div>
        </div>

        {/* Filter buttons -------------------------------------------------------------------------- */}
        <div className="row filter-bar2">
          {/* Data ---------------------------------------------------------------------- */}
          <div className="col-xl-3 mb-3">
            <label>Data Inicial </label>
            <DatePickerCustom
              name="dataInicial"
              selected={dataInicial}
              onChange={(value) => {
                setDataInicial(value);
              }}
            />
          </div>
          <div className="col-xl-3 mb-3">
            <label>Data Final</label>
            <DatePickerCustom
              name="dataFinal"
              selected={dataFinal}
              onChange={(value) => {
                setDataFinal(value);
              }}
            />
          </div>
          <div className="col-xl-3 mb-3 d-flex">
            {/* Botão Filtrar --------------------------------------------------------------------- */}
            <button type="button" className="btn btn-secondary btn-action" onClick={updateFilter}>
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </button>
          </div>
        </div>

        {lib.isNullOrEmptyObject(errors) ? null : (
          <div className="alert alert-danger">
            <ul className="m-0">
              {Object.values(errors).map((message) => (
                <li>{message}</li>
              ))}
            </ul>
          </div>
        )}

        {(pageData?.data || []).length === 0 && (
          <div className="row">
            <div className="col text-center">Não foi encontrado nenhum registro.</div>
          </div>
        )}
        {(pageData?.data || []).length > 0 && (
          <div className="row">
            <div className="col table-responsive">
              <table className="table text-center table-faturamento-comissao">
                <thead>
                  <tr className="bg-primary text-light">
                    <th>Nº NOTA</th>
                    <th>DT NOTA</th>
                    <th>PEDIDO</th>
                    <th>REPRESENTADA</th>
                    <th>VALOR</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {(pageData?.data || []).length === 0
                    ? null
                    : (pageData?.data || []).map((faturamentoNota, index) => (
                        <tr key={faturamentoNota.pedidoFaturamentoId}>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-link"
                              onClick={() => {
                                setModalFaturamentoNotaData({
                                  pedidoFaturamentoId: faturamentoNota.pedidoFaturamentoId,
                                  showModal: true
                                });
                              }}
                            >
                              {faturamentoNota.nota}
                            </button>
                          </td>
                          <td>{lib.getStringFromDate(faturamentoNota.dataNota)}</td>
                          <td>{faturamentoNota.codigoPedido}</td>
                          <td>{faturamentoNota.representada}</td>
                          <td>
                            <NumberFormat
                              value={faturamentoNota.valor}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'R$ '}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td>
                            {faturamentoNota.notaFechada === false ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-xs btn-danger ms-2 text-nowrap"
                                  title="Descartar"
                                  onClick={() => {
                                    setSelected(faturamentoNota);
                                    setShowModalRemover(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTimes} className="me-1" />
                                  Excluir Nota
                                </button>

                                <button
                                  className="btn btn-sm btn-xs btn-secondary ms-2 text-nowrap"
                                  onClick={() => {
                                    setSelected(faturamentoNota);
                                    setShowModalFaturar(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPencil} className="me-1" />
                                  Editar
                                </button>
                              </>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>

              <TablePagination
                pageData={pageData}
                onChangePage={(page) => getListFaturamento(page)}
              />
            </div>
          </div>
        )}
      </div>
      <hr></hr>
      <Link to="/pedido/faturamento" type="button" className="btn btn-secondary">
        Voltar
      </Link>

      <ModalFaturamentoNota
        pedidoFaturamentoId={modalFaturamentoNotaData.pedidoFaturamentoId}
        showModal={modalFaturamentoNotaData.showModal}
        setShowModal={() =>
          setModalFaturamentoNotaData({
            ...modalFaturamentoNotaData,
            showModal: false
          })
        }
      />

      <ModalPedidoFaturamento
        model={selected}
        showModal={showModalFaturar}
        setShowModal={setShowModalFaturar}
        onClose={closeModal}
      />

      {/* Modal Descartar */}
      <ModalConfirmacao
        headerText={' '}
        showModal={showModalRemover}
        setShowModal={setShowModalRemover}
        onConfirm={remover}
      >
        Tem certeza que deseja remover a nota <i className="text-primary">{selected?.nota}</i>?
      </ModalConfirmacao>
    </Layout>
  );
}
