import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import Layout from '../../components/layout/base/index';
import PageTitle from '../../components/page-title/index';
import ModalMessage from '../../components/modal-message';
import ModalEmailPedido from '../../components/modal-email-pedido';

import PedidoService from '../../services/pedido-service';
import PedidoRascunhoService from '../../services/pedido-rascunho-service';

import './styles.scss';

const PedidoFinalizado = () => {
  const params = useParams();
  const navigate = useNavigate();
  const pedidoId = params.id || 0;

  const [model, setModel] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalEnviarPedidoEmail, setShowModalEnviarPedidoEmail] = useState(false);
  const [parametrosEmailPedido, setParametrosEmailPedido] = useState({
    pedidoRepresentadaId: 0,
    representadaId: 0,
    dataHora: undefined
  });

  useEffect(() => {
    (async () => {
      window.showLoader();
      let result = await PedidoService.getAsync(pedidoId);

      if (result.data) {
        setModel(result.data.data);
      }
      window.hideLoader();
    })();
  }, []);

  async function redirectHome() {
    navigate('/');
  }

  async function redirectHistorico() {
    navigate('/pedido/historico');
  }

  async function redirectNovoPedido() {
    window.showLoader();
    var result = await PedidoRascunhoService.getInicialAsync();
    navigate('/pedido/' + result.data.data.pedidoRascunhoId);
  }

  async function enviarEmail(pedidoRepresentadaId, representadaId) {
    setParametrosEmailPedido({
      pedidoRepresentadaId: pedidoRepresentadaId,
      representadaId: representadaId,
      dataHora: new Date()
    });
    setShowModalEnviarPedidoEmail(true);
  }

  function oncloseModalEnviarEmail() {
    setShowModal(true);
  }

  return (
    <Layout>
      <div className="pedido-finalizado-page">
        <PageTitle title="PEDIDO ENVIADO COM SUCESSO"></PageTitle>
        <div className="icon-check">
          <FontAwesomeIcon icon={faCheck} />
        </div>
        <div className="dados-pedido">
          <div>
            Número Pedido: {model.codigoPedidoLoja ? model.codigoPedidoLoja : model.pedidoId}
          </div>
          <div>Loja: {model.lojaNomeFantasia}</div>
          <hr></hr>
          <div>Representada(s):</div>
          <div className="representadas">
            {model.pedidoRepresentadas && (
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Cód. Pedido</th>
                      <th>Representada</th>
                      <th>Baixar</th>
                      <th>Enviar por Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {model.pedidoRepresentadas.map((item, index) => (
                      <tr key={index}>
                        <td valign="middle">
                          {model.codigoPedidoLoja
                            ? model.codigoPedidoLoja
                            : item.codigoPedidoRepresentada}
                        </td>
                        <td valign="middle">
                          {item.nomeFantasia} - {item.cnpj}
                        </td>
                        <td valign="middle">
                          {!item.dataAprovacao && item.pedidoStatusSigla === 'A' && (
                            <span className="pedido-analise">Pedido em Análise</span>
                          )}
                          {item.dataAprovacao ||
                            (item.pedidoStatusSigla !== 'A' && (
                              <a href={item.urlArquivoPedido} target="_blank" download>
                                Baixar PDF Pedido
                              </a>
                            ))}
                        </td>
                        <td valign="middle">
                          {!item.dataAprovacao && item.pedidoStatusSigla === 'A' && (
                            <span className="pedido-analise">Pedido em Análise</span>
                          )}
                          {item.dataAprovacao ||
                            (item.pedidoStatusSigla !== 'A' && (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  enviarEmail(item.pedidoRepresentadaId, item.representadaId)
                                }
                              >
                                Enviar por E-mail
                              </button>
                            ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 btn-finalizar">
            <button className="btn btn-primary" onClick={() => redirectNovoPedido()}>
              CRIAR NOVO PEDIDO
            </button>
          </div>
          <div className="col-md-4 btn-finalizar">
            <button className="btn btn-primary" onClick={() => redirectHistorico()}>
              IR PARA HISTÓRICO DE PEDIDOS
            </button>
          </div>
          <div className="col-md-4 btn-finalizar">
            <button className="btn btn-primary" onClick={() => redirectHome()}>
              VOLTAR PARA HOME
            </button>
          </div>
        </div>
      </div>

      <ModalMessage
        title="E-mail Enviado!"
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={() => {}}
      >
        <p>O e-mail com o pedido foi enviado com sucesso!</p>
      </ModalMessage>
      <ModalEmailPedido
        showModal={showModalEnviarPedidoEmail}
        setShowModal={setShowModalEnviarPedidoEmail}
        // pedidoRepresentadaId={pedidoRepresentadaIdPorEmail}
        // representadaId={representadaIdPorEmail}
        params={parametrosEmailPedido}
        emailEnviadoMessage={oncloseModalEnviarEmail}
      ></ModalEmailPedido>
    </Layout>
  );
};

export default PedidoFinalizado;
