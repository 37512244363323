/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { GlobalContext } from '../../contexts/global-context';
import TableauService from '../../services/tableau-service';

const _tableau = (props) => {
  const { isMobile } = React.useContext(GlobalContext);

  const [model, setModel] = useState({});

  useEffect(() => {
    //<script type="module" src="https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"></script>
    const script = document.createElement('script');

    script.type = 'module';
    script.src = 'https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  async function getModel() {
    let result = isMobile ? await TableauService.getHomeMobile() : await TableauService.getHome();

    setModel(result.data.data);
  }

  useEffect(() => {
    setModel({});
    getModel();
  }, [isMobile]);

  if (!model?.url?.length) return null;

  return (
    <tableau-viz
      id="tableauViz"
      src={model.url || ''}
      device={isMobile ? 'phone' : 'desktop'}
      toolbar="hidden"
      width="100%"
      height={isMobile ? '5000' : '2000'}
      style={{ flexGrow: 1 }}
    >
      {(model.parameters || []).map((p, index) => (
        <viz-parameter key={index} name={p.name} value={p.value} />
      ))}
    </tableau-viz>
  );
};

export default _tableau;
