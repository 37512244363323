import React from 'react';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';

import lib from 'helpers/lib';

const CardReceita = ({ data }) => {
  return (
    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
      <div className="card h-100">
        <div className="card border-left-primary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-md font-weight-bold text-primary text-uppercase mb-2">
                  Receita
                </div>

                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  <div className="row">
                    <div className="col-4">Meta</div>
                    <div className="col-8">
                      {_.isNil(data.valorMeta) ? (
                        <Skeleton count={1} width={'100%'} height={20} />
                      ) : (
                        lib.formatToCurrencyText(data.valorMeta)
                      )}
                    </div>

                    <div className="col-4">Pedido</div>
                    <div className="col-8">
                      {_.isNil(data.valorPedido) ? (
                        <Skeleton count={1} width={'100%'} height={20} />
                      ) : (
                        lib.formatToCurrencyText(data.valorPedido)
                      )}
                    </div>

                    <div className="col-4">Faturado</div>
                    <div className="col-8">
                      {_.isNil(data.valorFaturado) ? (
                        <Skeleton count={1} width={'100%'} height={20} />
                      ) : (
                        lib.formatToCurrencyText(data.valorFaturado)
                      )}
                    </div>

                    <div className="col-4">GAP</div>
                    <div className="col-8">
                      {_.isNil(data.valorGap) ? (
                        <Skeleton count={1} width={'100%'} height={20} />
                      ) : (
                        `${lib.formatToCurrencyText(data.valorGap)}
                        | ${data.porcentagemGap}% Atg.`
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardReceita;
