import React, { useEffect } from 'react';
import ModalCadastro from '../_base/modal-cadastro';
import { LoggedUserContext } from '../../contexts/logged-user-context';

import './styles.scss';

export default function ModaleSelecionarEquipe(props) {
  const [userData, _] = React.useContext(LoggedUserContext);
  const { showModal, setShowModal, onClose } = props;
  const [equipeIdSelecionada, setEquipeIdSelecionada] = React.useState(
    userData.equipe?.equipeId ?? 0
  );

  async function setEquipe(equipeId) {
    window.showLoader();
    localStorage.setItem('equipeId', equipeId);
    window.location = '/';
  }

  useEffect(() => {
    if (!(userData?.planoAtivo || userData?.equipes?.length != 0)) {
      window.location = '/login';
    }
  }, []);

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText={`SELECIONE UM TIME`}
      size="md"
    >
      <div className="modal-body">
        <div className="row model-selecionar-equipe">
          {userData?.planoAtivo ? (
            <div className="col-12">
              <p className="observacao-equipe">
                Caso queira a acessar sua conta clique na opção com o nome cadastrado em sua conta e
                depois clique em "ACESSAR".
              </p>
              <div
                className={'card-equipe' + (!equipeIdSelecionada ? ' equipe-selecionada' : '')}
                onClick={() => {
                  setEquipeIdSelecionada(null);
                  setEquipe(null);
                }}
              >
                <div className="nome-equipe">{userData?.nome}</div>
                <div className="nome-usuario">Voltar para minha conta</div>
              </div>
              <hr></hr>
            </div>
          ) : null}

          <div className="col-12">
            <p className="observacao-equipe">
              Selecione o time que quer trabalhar agora e clique em "ACESSAR" para ter acesso aos
              dados do time escolhido.
            </p>
            {(userData?.equipes || []).map((equipe, index) => (
              <div
                key={index}
                className={
                  'card-equipe' +
                  (equipe.equipeId == equipeIdSelecionada ? ' equipe-selecionada' : '')
                }
                onClick={() => {
                  setEquipeIdSelecionada(equipe.equipeId);
                  setEquipe(equipe.equipeId);
                }}
              >
                <div className="nome-equipe">Time: {equipe.nome}</div>
                <div className="nome-usuario">Proprietário: {equipe.usuario}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setEquipe(equipeIdSelecionada)}
        >
          ACESSAR
        </button>
      </div>
    </ModalCadastro>
  );
}
