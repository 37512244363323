import React from 'react';
import { Table } from 'react-bootstrap';

import './table.css';

const TableVendas = ({ equipe }) => {
  return (
    <Table striped bordered width="100%" className="table-vendas">
      <thead>
        <tr>
          <th colSpan="12" style={{ backgroundColor: '#7a7a7a', color: '#fff', fontSize: '1rem' }}>
            {equipe?.equipeNome
              ? equipe.equipeNome.toUpperCase()
              : equipe?.usuarioNome
                ? equipe.usuarioNome.toUpperCase()
                : ' - '}
          </th>
        </tr>
        <tr>
          {/* EQUIPE NOME */}
          <th rowSpan="2" style={{ borderRight: '2px solid #7a7a7a' }}>
            NOME DA CATEGORIA
          </th>
          <th colSpan="3" style={{ borderRight: '2px solid #7a7a7a' }}>
            FATURAMENTO
          </th>
          <th colSpan="3" style={{ borderRight: '2px solid #7a7a7a' }}>
            FAMILIA
          </th>
          <th colSpan="3" style={{ borderRight: '2px solid #7a7a7a' }}>
            VOLUME (KG)
          </th>
          <th colSpan="3">PREÇO MÉDIO</th>
        </tr>
        <tr>
          {/* FATURAMENTO */}
          <th>PEDIDO</th>
          <th>FATURADO</th>
          <th style={{ borderRight: '2px solid #7a7a7a' }}>META</th>
          {/* FAMILIA */}
          <th>% PEDIDO</th>
          <th>% FATURADO</th>
          <th style={{ borderRight: '2px solid #7a7a7a' }}>% META</th>
          {/* VOLUME */}
          <th>PEDIDO</th>
          <th>FATURADO</th>
          <th style={{ borderRight: '2px solid #7a7a7a' }}>META</th>
          {/* PREÇO MÉDIO */}
          <th>REAL (%)</th>
          <th>META</th>
        </tr>
      </thead>
      <tbody>
        {equipe.categoriaRelatorio.map((categoria) => (
          <tr key={categoria.categoriaId}>
            {/* FATURAMENTO */}
            <td style={{ borderRight: '2px solid #7a7a7a' }}>{categoria.categoriaNome}</td>
            <td>
              {categoria.faturamentoPorValor.vendido
                ? categoria.faturamentoPorValor.vendido.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                : 'R$ 0,00'}{' '}
              ({categoria.faturamentoPorValor.percentualVendido}%)
            </td>
            <td>
              {categoria.faturamentoPorValor.faturado
                ? categoria.faturamentoPorValor.faturado.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                : 'R$ 0,00'}{' '}
              ({categoria.faturamentoPorValor.percentualFaturado}%)
            </td>
            <td style={{ borderRight: '2px solid #7a7a7a' }}>
              {categoria.faturamentoPorValor.meta
                ? categoria.faturamentoPorValor.meta.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                : 'R$ 0,00'}
            </td>
            {/* FAMILIA */}
            <td>{categoria.faturamentoPorValor.familiaVendidoPercentual}%</td>
            <td>{categoria.faturamentoPorValor.familiaFaturadoPercentual}%</td>
            <td style={{ borderRight: '2px solid #7a7a7a' }}>
              {categoria.faturamentoPorValor.familiaMetaPercentual}%
            </td>
            {/* VOLUME */}
            <td>
              {categoria.faturamentoPorVolume.vendido.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}{' '}
              ({categoria.faturamentoPorVolume.percentualVendido}%)
            </td>
            {/* <td>{categoria.faturamentoPorVolume.percentualMensalVendido}%</td> */}
            <td>
              {categoria.faturamentoPorVolume.faturado
                ? categoria.faturamentoPorVolume.faturado.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                : '0,00'}{' '}
              ({categoria.faturamentoPorVolume.percentualFaturado}%)
            </td>
            {/* <td>{categoria.faturamentoPorVolume.percentualMensalFaturado}%</td> */}
            <td style={{ borderRight: '2px solid #7a7a7a' }}>
              {categoria.faturamentoPorVolume.meta
                ? categoria.faturamentoPorVolume.meta.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                : '0,00'}{' '}
              ({categoria.faturamentoPorVolume.percentualMeta}%)
            </td>

            {/* PREÇO MÉDIO */}

            <td>
              {categoria.faturamentoPrecoMedio.precoMedioReal
                ? categoria.faturamentoPrecoMedio.precoMedioReal.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                : 'R$ 0,00'}
              ({categoria.faturamentoPrecoMedio.precoMedioPercentual}%)
            </td>
            <td>
              {categoria.faturamentoPrecoMedio.precoMedioMeta
                ? categoria.faturamentoPrecoMedio.precoMedioMeta.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                : 'R$ 0,00'}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableVendas;
