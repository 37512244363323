import React from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const customStyles = {
  control: (styles) => ({
    ...styles,
    border: 'none',
    borderBottom: '1px solid #919191',
    borderRadius: 0
  })
};

const formatOptionLabel = (option) => (
  <div className="format-option-representada" style={{ display: 'flex' }}>
    <div>
      <span className="fantasia">{option.fantasia}</span>
      <span className="cnpj"> - {option.cnpj}</span>
    </div>
  </div>
);

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    &nbsp;
    <FontAwesomeIcon icon={faIndustry} /> {children}
  </components.Control>
);

const SelectRepresentada = (props) => {
  return (
    <>
      <Select
        {...props}
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
        components={{ Control }}
        isClearable={true}
        placeholder="Selecione uma representada"
        noOptionsMessage={() => 'Sem opções'}
      />
    </>
  );
};

export default SelectRepresentada;
