import ServiceClient from './service-client';

class PedidoStatusService {
  static async getSelectListSiglaAsync() {
    try {
      var result = await ServiceClient.Get('/pedidostatus/select-list-sigla');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default PedidoStatusService;
