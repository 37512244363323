import React from 'react';

import Layout from 'components/layout/base';
import PageTitle from 'components/page-title';
import AlertPedidosAvaliacao from 'components/alert-pedidos-avaliacao';

import Dashboard from './dashboard';

import './styles.scss';

function Home() {
  return (
    <Layout>
      <PageTitle title="HOME" />

      <AlertPedidosAvaliacao />

      <div className="home-web">
        <Dashboard />
      </div>
    </Layout>
  );
}

export default Home;
