import ServiceClient from './service-client';
import { service_url } from './service-constants';

class PedidoFaturamentoComissaoHistoricoService {
  static async listAsync(filter) {
    try {
      var result = await ServiceClient.Get('/pedidofaturamentocomissaohistorico/list', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get(
        '/pedidofaturamentocomissaohistorico/list-paged',
        filter
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default PedidoFaturamentoComissaoHistoricoService;
