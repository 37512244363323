import React, { useState, useEffect } from 'react';
import './styles.scss';
import Skeleton from 'react-loading-skeleton';

export default function CardPanel({ source, isLoading = false }) {
  const [cardData, setCardData] = useState(null);

  useEffect(() => {
    let metaContratante = source;
    if (!metaContratante?.dias) return;

    let ultimoDia = metaContratante.dias.pop();

    let pedidos = ultimoDia.valor;
    let vendas = ultimoDia.valorFaturado;
    let meta = ultimoDia.meta;

    let objetivo,
      metaFaltante = 0;
    if (meta > 0) {
      objetivo = Math.round((vendas / meta) * 100 * 100) / 100;
      metaFaltante = meta - vendas;
    }

    setCardData({
      pedidos: pedidos,
      vendas: vendas,
      meta: meta,
      objetivo: objetivo,
      metaFaltante: metaFaltante
    });
  }, [source]);

  // if (!cardData) return null;

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>
      <div className="row d-flex">
        <div className="col mb-4">
          <div className="card">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      PEDIDOS NO MÊS
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {!isLoading ? (
                        cardData.pedidos.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      ) : (
                        <Skeleton count={1} />
                      )}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col mb-4">
          <div className="card">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      FATURADO NO MÊS
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {!isLoading ? (
                        cardData.vendas.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      ) : (
                        <Skeleton count={1} />
                      )}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col mb-4">
          <div className="card">
            <div className="card border-left-meta shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-meta text-uppercase mb-1">
                      OBJETIVO NO MÊS
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {!isLoading ? (
                        cardData.meta.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      ) : (
                        <Skeleton count={1} />
                      )}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col mb-4">
          <div className="card">
            <div className="card border-left-info shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      OBJETIVO REALIZADO
                    </div>
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto">
                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                          {!isLoading ? (
                            cardData.objetivo > 0 ? (
                              cardData.objetivo
                            ) : (
                              '0%'
                            )
                          ) : (
                            <Skeleton count={1} />
                          )}
                        </div>
                      </div>
                      <div className="col">
                        {!isLoading ? (
                          <div className="progress progress-sm mr-2">
                            <div
                              className="progress-bar bg-info"
                              role="progressbar"
                              style={{ width: `${cardData.objetivo}%` }}
                              aria-valuenow={cardData.objetivo}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            />
                          </div>
                        ) : (
                          <Skeleton count={1} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col mb-4">
          <div className="card">
            <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                      OBJETIVO FALTANTE
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {!isLoading ? (
                        cardData.metaFaltante.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      ) : (
                        <Skeleton count={1} />
                      )}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-comments fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
