import React from 'react';
import lib from 'helpers/lib';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';

const CardCliente = ({ data }) => {
  return (
    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
      <div className="card h-100">
        <div className="card border-left-primary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-md font-weight-bold text-primary text-uppercase mb-2">
                  Cliente
                </div>

                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  <div className="row">
                    <div className="col-6">Posit. 90 Dias</div>
                    <div className="col-6">
                      {_.isNil(data.positivados90Dias) ? (
                        <Skeleton count={1} width={'100%'} height={20} />
                      ) : (
                        data.positivados90Dias
                      )}
                    </div>

                    <div className="col-6">Positivados</div>
                    <div className="col-6">
                      {_.isNil(data.positivados) ? (
                        <Skeleton count={1} width={'100%'} height={20} />
                      ) : (
                        data.positivados
                      )}
                    </div>

                    <div className="col-6">GAP</div>
                    <div className="col-6">
                      {_.isNil(data.valorGap) ? (
                        <Skeleton count={1} width={'100%'} height={20} />
                      ) : (
                        `${data.valorGap} | ${data.porcentagemGap}% Atg.`
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCliente;
