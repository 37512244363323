import React from 'react';

import Layout from '../../components/layout/base/index';
import PageTitle from '../../components/page-title';
import TableCadastroComissao from '../../components/table-cadastro-comissao';

import ComissaoService from '../../services/comissao-service';

import lib from '../../helpers/lib';

export default function Comissao() {
  const [model, setModel] = React.useState({});

  React.useEffect(() => {
    (async () => {
      window.showLoader();
      let response = await ComissaoService.getModelAsync();

      setModel(response.data.data);

      window.hideLoader();
    })();
  }, []);

  async function saveAsync() {
    window.showLoader();

    let response = await ComissaoService.saveAsync(model);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      return;
    }

    //setErrors(response.data.errors);
    window.hideLoader();
  }

  return (
    <Layout>
      <PageTitle title="Cadastro de Comissão" />

      <TableCadastroComissao model={model} save={saveAsync} />
    </Layout>
  );
}
