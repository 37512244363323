import React from 'react';

import Layout from '../../components/layout/base/index';
import PageTitle from '../../components/page-title';
import { Tab, Tabs } from 'react-bootstrap';
import LojasSemPedidos from './lojas-sem-pedidos';
import RelatorioAnalitico from './relatorio-analitico';
import RelatorioMetas from './relatorio-metas';
import RelatorioMetasVolume from './relatorio-metas-volume';
import { LoggedUserContext } from '../../contexts/logged-user-context';

import './styles.scss';
import ExtratoComissao from './extrato-comissao';

export default function Relatorio() {
  const [userData, _] = React.useContext(LoggedUserContext);
  return (
    <Layout>
      <PageTitle title="Relatórios" />
      <div className="col-12 relatorio">
        <Tabs defaultActiveKey="analitico" id="tabs-relatorio" className="mb-3">
          <Tab eventKey="analitico" title="Relatório Analítico">
            <RelatorioAnalitico />
          </Tab>
          <Tab eventKey="cliente" title="Acompanhamento de Clientes">
            <LojasSemPedidos />
          </Tab>
          {userData?.acessaRelatorioMeta && (
            <Tab eventKey="metas" title="Acompanhamento de Metas por Valor">
              <RelatorioMetas />
            </Tab>
          )}
          {userData?.acessaRelatorioMeta && (
            <Tab eventKey="metas-volume" title="Acompanhamento de Metas por Volume">
              <RelatorioMetasVolume />
            </Tab>
          )}
          {userData?.acessaRelatorioComissao && (
            <Tab eventKey="extrato" title="Extrato Comissão">
              <ExtratoComissao />
            </Tab>
          )}
        </Tabs>
      </div>
    </Layout>
  );
}
