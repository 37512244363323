import { MultiSelect } from 'react-multi-select-component';
import { matchSorter } from 'match-sorter';

export default function SelectMulti(props) {
  let { options, value, onChange, filterOptions, filterValues } = props;

  options = options || [];

  const overrideStrings = {
    allItemsAreSelected: 'Todos Selecionados',
    clearSearch: ' ',
    clearSelected: ' ',
    noOptions: 'Nenhum Resultado',
    search: ' ',
    selectAll: 'Selecionar Todos',
    selectAllFiltered: 'Selecionar Todos',
    selectSomeItems: ' ',
    create: ' '
  };

  let _options =
    (filterValues || []).length === 0
      ? options
      : options.filter((option) => filterValues.indexOf(option.value) !== -1);

  const selectOptions = {
    overrideStrings: overrideStrings,

    ...props,

    filterOptions:
      filterOptions ||
      ((options, filter) => {
        if (!filter) {
          return options;
        }

        return matchSorter(options, filter, { keys: ['label'] });
      }),

    options: _options
  };

  return <MultiSelect {...selectOptions} />;
}
