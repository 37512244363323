import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useParams, useNavigate } from 'react-router-dom';
import ModalImage from 'react-modal-image';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEnvelope, faWarning } from '@fortawesome/free-solid-svg-icons';

import PedidoService from '../../services/pedido-service';
import PedidoRascunhoService from '../../services/pedido-rascunho-service';
import LojaService from '../../services/loja-service';
import EquipeService from '../../services/equipe-service';

import Layout from '../../components/layout/base/index';
import PageTitle from '../../components/page-title/index';
import FormGroupHeader from '../../components/_base/form-group-header/index';
import Errors from '../../components/_base/errors/index';
import DatePickerCustom from '../../components/_base/DatePicker';

import { LoggedUserContext } from '../../contexts/logged-user-context';

import lib from '../../helpers/lib';

import SemImagem from '../../assets/imagens/sem-imagem.jpg';

import './styles.scss';

const PedidoRevisao = () => {
  const params = useParams();
  const navigate = useNavigate();
  const pedidoRascunhoId = params.id || 0;
  const [userData, _] = React.useContext(LoggedUserContext);

  const [model, setModel] = useState({ pedidoRepresentadas: [] });
  const [loja, setLoja] = useState({ pedidoRepresentadas: [] });
  const [valorTotal, setValorTotal] = useState(0.0);
  const [valorReferenciaTotal, setValorReferenciaTotal] = useState(0.0);
  const [valorTotalDescontoAdicional, setValorTotalDescontoAdicional] = useState(0.0);
  const [errors, setErrors] = useState({});
  const [precoInvalido, setPrecoInvalido] = useState(false);
  const [usuarioEquipe, setUsuarioEquipe] = useState();

  useEffect(() => {
    (async () => {
      window.showLoader();

      let rascunho = await PedidoRascunhoService.getAsync(pedidoRascunhoId);

      if (rascunho.data) {
        var lojaresult = await LojaService.getAsync(rascunho.data.data.lojaId);

        if (lojaresult.data) {
          if (lojaresult.data.errors) {
            setErrors(lojaresult.data.errors);
          } else {
            if (rascunho.data.data.jsonModel) {
              let pedido = JSON.parse(rascunho.data.data.jsonModel);
              if (pedido.pedidoRepresentadas && pedido.pedidoRepresentadas.length > 0) {
                pedido.pedidoRepresentadas.forEach(function (rep, i) {
                  if (
                    rep.dataMaximaEntrega &&
                    (typeof rep.dataMaximaEntrega === 'string' ||
                      rep.dataMaximaEntrega instanceof String)
                  ) {
                    rep.dataMaximaEntrega = lib.getDateFromStringJson(rep.dataMaximaEntrega);
                  }

                  if (rep.pedidoRepresentadaProdutos && rep.pedidoRepresentadaProdutos.length > 0) {
                    rep.pedidoRepresentadaProdutos.forEach(function (prod, ind) {
                      if (prod.precoInvalido === true) {
                        setPrecoInvalido(true);
                      }
                    });
                  }
                });
              }

              await setPedidoRepresentadaPrazos(pedido);

              setModel(pedido);

              if (userData?.equipe?.nome) {
                let equipeuser = await EquipeService.getUsuarioResponsavelAsync();
                setUsuarioEquipe(equipeuser.data?.data);
              }
            }
            setLoja(lojaresult.data.data);
          }
        }
      }

      window.hideLoader();
    })();
  }, []);

  useEffect(() => {
    calcularValorTotal();
  }, [model.pedidoRepresentadas]);

  async function setPedidoRepresentadaPrazos(pedido) {
    if (!pedido.pedidoRepresentadas?.length) return;

    let representadaIds = pedido.pedidoRepresentadas.map((pr) => pr.representadaId);

    let response = await PedidoService.listRepresentadaPrazosAsync(representadaIds);

    let representadas = response.data.data.representadas;
    if (!representadas.length) return;

    for (let representada of representadas) {
      if (!representada.prazos?.length) continue;

      let pedidoRepresentada = pedido.pedidoRepresentadas.find(
        (x) => x.representadaId === representada.representadaId
      );

      pedidoRepresentada.listaPrazos = representada.prazos.map((x) => ({
        value: x,
        label: `${x} dias`
      }));
    }
  }

  function calcularValorTotal() {
    let total = 0.0;
    let totalValorReferencia = 0.0;
    let totalDescontoAdicional = 0.0;
    for (let i = 0; i < model.pedidoRepresentadas.length; i++) {
      total += model.pedidoRepresentadas[i].pedidoRepresentadaProdutos.reduce(function (
        totalProduto,
        currentValueProduto
      ) {
        return totalProduto + currentValueProduto.total;
      }, 0.0);
      totalValorReferencia += model.pedidoRepresentadas[i].pedidoRepresentadaProdutos.reduce(
        function (totalPrecoRefProd, currentValueProduto) {
          if (currentValueProduto.referenciaTotal > 0) {
            return totalPrecoRefProd + currentValueProduto.referenciaTotal;
          }
          return totalPrecoRefProd + currentValueProduto.total;
        },
        0.0
      );

      totalDescontoAdicional += model.pedidoRepresentadas[i].pedidoRepresentadaProdutos.reduce(
        function (totalDescAdicionalProd, currentValueProduto) {
          return totalDescAdicionalProd + currentValueProduto.totalDescontoAdicional;
        },
        0.0
      );
    }

    // if (totalDescontoAdicional > 0) {
    //   total = total - totalDescontoAdicional;
    // }

    setValorTotal(total);
    setValorReferenciaTotal(totalValorReferencia);
    //setDescontoAdicionalTotal(totalDescontoAdicional);
  }

  function calcularPorcentagemDesconto(valorReferencia, valorAtual) {
    if (valorReferencia == 0 || valorAtual == 0) return 0;
    const percentual = (100 * valorAtual) / valorReferencia;
    if (percentual > 100) return 0;
    const resultPerct = 100 - percentual;
    return Math.round(resultPerct * 100) / 100;
  }

  async function finalizar(enviarEmail, enviarWhatsapp) {
    window.showLoader();
    model.enviarPorEmail = enviarEmail;
    model.enviarPorWhatsapp = enviarWhatsapp;
    model.pedidoRascunhoId = pedidoRascunhoId;

    const result = await PedidoService.addAsync(model);
    if (result.data) {
      if (result.data.errors) {
        setErrors(result.data.errors);
      } else {
        navigate('/pedido/finalizado/' + result.data.data.pedidoId);
      }
    }
    window.hideLoader();
  }

  const handleChange = (e) => {
    setModel({ ...model, pedidoPadrao: e.target.checked });
  };

  function onChangeObservacao(value, representadaId) {
    const indexRepresentada = model.pedidoRepresentadas.findIndex((pr) => {
      return pr.representadaId === representadaId;
    });

    let rep = model.pedidoRepresentadas[indexRepresentada];

    rep = { ...rep, observacao: value };

    let newPedidoRepresentadas = [...model.pedidoRepresentadas];
    newPedidoRepresentadas[indexRepresentada] = rep;

    setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });
  }

  function onChangeObservacaoNotaFiscal(value, representadaId) {
    const indexRepresentada = model.pedidoRepresentadas.findIndex((pr) => {
      return pr.representadaId === representadaId;
    });

    let rep = model.pedidoRepresentadas[indexRepresentada];

    rep = { ...rep, observacaoNotaFiscal: value };

    let newPedidoRepresentadas = [...model.pedidoRepresentadas];
    newPedidoRepresentadas[indexRepresentada] = rep;

    setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });
  }

  function onChangeData(value, representadaId) {
    const indexRepresentada = model.pedidoRepresentadas.findIndex((pr) => {
      return pr.representadaId === representadaId;
    });

    let rep = model.pedidoRepresentadas[indexRepresentada];

    rep = { ...rep, dataMaximaEntrega: value };

    let newPedidoRepresentadas = [...model.pedidoRepresentadas];
    newPedidoRepresentadas[indexRepresentada] = rep;

    setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });
  }

  function onChangeSelectedDias(value, representadaId) {
    const indexRepresentada = model.pedidoRepresentadas.findIndex((pr) => {
      return pr.representadaId === representadaId;
    });

    const rep = model.pedidoRepresentadas[indexRepresentada];
    const newModel = { ...rep, dias: value };

    let newPedidoRepresentadas = [...model.pedidoRepresentadas];
    newPedidoRepresentadas[indexRepresentada] = newModel;

    setModel({ ...model, pedidoRepresentadas: newPedidoRepresentadas });
  }

  function totalCliente(representada) {
    let totalCliente = 0.0;
    let totalDescontoAdicionalCliente = 0.0;
    totalCliente = representada.pedidoRepresentadaProdutos.reduce(function (
      totalProduto,
      currentValueProduto
    ) {
      return totalProduto + currentValueProduto.total;
    }, 0.0);
    totalDescontoAdicionalCliente = representada.pedidoRepresentadaProdutos.reduce(function (
      totalDescAdicional,
      currentValueProduto
    ) {
      return totalDescAdicional + currentValueProduto.totalDescontoAdicional;
    }, 0.0);
    return totalCliente - totalDescontoAdicionalCliente;
  }

  async function salvar() {
    window.showLoader();
    await updatePedidoRascunho(model);

    navigate('/');

    window.hideLoader();
  }

  async function updatePedidoRascunho(modelPedido) {
    let modelUpdate = {
      pedidoRascunhoId: pedidoRascunhoId,
      jsonModel: JSON.stringify(modelPedido)
    };

    await PedidoRascunhoService.updateRevisaoAsync(modelUpdate);
  }

  return (
    <Layout>
      <div className="pedido-revisao-page">
        <PageTitle title="REVISÃO DO PEDIDO"></PageTitle>
        {precoInvalido && !userData?.equipe?.nome && (
          <div className="invalid-feedback-error mensagem-preco">
            <FontAwesomeIcon icon={faWarning}></FontAwesomeIcon> O pedido contém produtos fora do
            preço permitido na tabela de preços.
          </div>
        )}
        {precoInvalido && userData?.equipe?.nome && (
          <div className="invalid-feedback-error mensagem-preco">
            <FontAwesomeIcon icon={faWarning}></FontAwesomeIcon> O pedido contém produtos fora do
            preço permitido na tabela de preços, volte para ajustar os preços ou um e-mail será
            enviado para o responsável pela representada para validação do pedido.
          </div>
        )}
        <Errors errors={errors} keyName="" />
        <FormGroupHeader text="DADOS DO CLIENTE" />
        <div className="row dados-loja">
          {model.codigoPedidoLoja && (
            <div className="col-md-12 text-center">
              <label>Nº Pedido: </label> {model.codigoPedidoLoja}
              <br></br>
              <br></br>
            </div>
          )}

          <div className="col-md-6">
            <div className="row">
              <div className="col-lg-5 text-lg-end">
                <label>Nome Fantasia:</label>
              </div>
              <div className="col-lg-7">{loja.nomeFantasia}</div>
              <div className="col-lg-5 text-lg-end">
                <label>CNPJ:</label>
              </div>
              <div className="col-lg-7">{loja.cnpj}</div>
              <div className="col-lg-5 text-lg-end">
                <label>Telefone:</label>
              </div>
              <div className="col-lg-7">{loja.contatoTelefone}</div>
              <div className="col-lg-5 text-lg-end">
                <label>E-mail:</label>
              </div>
              <div className="col-md-7">{loja.contatoEmail}</div>
              <div className="col-lg-5 text-lg-end">
                <label>Condição Pagamento:</label>
              </div>
              <div className="col-md-7">
                {loja.condicaoPagamento != '' ? loja.condicaoPagamento : '-'}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-lg-5 text-lg-end">
                <label>Endereço:</label>
              </div>
              <div className="col-lg-7">
                {loja.logradouro}, {loja.numero}
                <br></br>
                {loja.bairro}
                <br></br>
                {!loja.complemento ? (
                  ''
                ) : (
                  <>
                    {loja.complemento}
                    <br></br>
                  </>
                )}
                {loja.cidade} - {loja.estado} <br></br>
                Cep: {loja.cep}
              </div>
            </div>
          </div>
        </div>
        <FormGroupHeader text="DADOS DO PEDIDO" />
        <div className="row">
          <div className="col-12">
            {model.pedidoRepresentadas.map((representada, i) => (
              <div key={i}>
                <label className="representada-adicionada">
                  {representada.nomeFantasia} - {representada.cnpj}
                </label>
                {representada.pedidoRepresentadaProdutos.map((produto, index) => {
                  let urlImagem = produto.urlImagem ? produto.urlImagem : SemImagem;

                  return (
                    <div key={index} className="row align-items-center produtos-pedido">
                      <div className="col-auto d-none d-xxl-block text-center">
                        <div className="imagem-produto">
                          <ModalImage small={urlImagem} large={urlImagem} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <div className="row dados-produto">
                              <div className="col-xl-2">
                                <label>Codigo</label>
                              </div>
                              <div className="col-xl-10">{produto.codigoNaRepresentada}</div>
                              <div className="col-xl-2">
                                <label>Produto</label>
                              </div>
                              <div className="col-xl-10">{produto.nome}</div>
                            </div>
                          </div>
                          <div className="col-auto d-xxl-none">
                            <div className="imagem-produto-sm">
                              <ModalImage small={urlImagem} large={urlImagem} />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6 col-lg-3">
                            <label>Unid. Medida</label>
                            <div>{produto.unidadeMedidaNome}</div>
                          </div>
                          <div className="col-6 col-lg-3">
                            <label>Quantidade</label>
                            <div>
                              <NumberFormat
                                displayType={'text'}
                                thousandSeparator={false}
                                decimalSeparator={','}
                                value={produto.quantidade}
                              />
                            </div>
                          </div>
                          <div className="col-6 col-lg-3">
                            <label>Valor Unitário</label>
                            <div>
                              <NumberFormat
                                value={produto.valor}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                prefix={'R$ '}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                readOnly={true}
                              />
                            </div>
                            {Number.isNaN(produto?.valorDescontoAdicional) == false &&
                              produto?.valorDescontoAdicional > 0 && (
                                <>
                                  <label>Valor com Descontos</label>
                                  <div>
                                    <NumberFormat
                                      value={produto.valor - (produto?.valorDescontoAdicional ?? 0)}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'R$ '}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      readOnly={true}
                                    />
                                  </div>
                                </>
                              )}
                            {(produto?.precoMinimo && produto?.precoMinimo > 0) ||
                            (produto?.precoMaximo && produto?.precoMaximo > 0) ? (
                              <div>
                                <label>Tabela:</label>{' '}
                                {produto?.precoMinimo && produto?.precoMinimo > 0
                                  ? lib.formatToCurrencyText(produto.precoMinimo)
                                  : 'R$ 0'}
                                {produto?.precoMaximo && produto?.precoMaximo > 0
                                  ? ' - ' + lib.formatToCurrencyText(produto.precoMaximo)
                                  : ''}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="col-6 col-lg-3">
                            <label>Total</label>
                            <div>
                              <NumberFormat
                                value={produto.total}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                prefix={'R$ '}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </div>
                            <label>Total Desconto %</label>
                            <div>
                              <NumberFormat
                                value={PedidoService.somarPorcentagem(
                                  produto?.porcentagemDesconto,
                                  produto?.porcentagemDescontoAdicional
                                )}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                prefix={''}
                                suffix={' %'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </div>
                            {Number.isNaN(produto?.valorDescontoAdicional) == false &&
                              produto?.totalDescontoAdicional > 0 && (
                                <>
                                  <label>Total com Desconto Adicional</label>
                                  <div>
                                    <NumberFormat
                                      value={produto.total - produto.totalDescontoAdicional}
                                      displayType={'text'}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'R$ '}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                        {produto.erro && (
                          <div className="row">
                            <div className="col-12 ">
                              <div className="alert-danger erro-produto">{produto.erro}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="text-end">
                  <label>
                    Total do Cliente:{' '}
                    <NumberFormat
                      value={totalCliente(representada)}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={'R$ '}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </label>
                </div>
                <div className="row form-group mb-2">
                  <div className="col-xl-3">
                    <label>Entregar até:</label>
                    <DatePickerCustom
                      heightAuto={true}
                      name={representada.representadaId}
                      selected={representada.dataMaximaEntrega}
                      minDate={Date.now()}
                      onChange={(value, name) => {
                        onChangeData(value, name);
                      }}
                    />
                  </div>
                </div>

                {representada.listaPrazos?.length > 0 ? (
                  <div className="row form-group mb-2">
                    <div className="col-xl-3">
                      <label>Prazo de Pagamento:</label>
                      <Select
                        isMulti
                        placeholder="Selecione"
                        styles={customControlStyles}
                        options={representada.listaPrazos}
                        value={representada.listaPrazos.filter((l) => {
                          if (representada.dias && representada.dias != '') {
                            var arrayDias = representada.dias.split(',');
                            const valor = arrayDias.find((dia) => l.value == Number(dia));
                            return valor;
                          }
                          return undefined;
                        })}
                        onChange={(arraySelectedValue) => {
                          if (arraySelectedValue && arraySelectedValue.length > 0) {
                            const diasSelected = arraySelectedValue.map((d) => d.value);
                            const diasConcated = diasSelected.join(',');
                            onChangeSelectedDias(diasConcated, representada.representadaId);
                          } else {
                            onChangeSelectedDias('', representada.representadaId);
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                <div>
                  <label>Observações Internas:</label>
                  <textarea
                    className="form-control"
                    onChange={(e) =>
                      onChangeObservacao(e.target.value, representada.representadaId)
                    }
                    value={representada.observacao}
                  ></textarea>
                  <hr></hr>
                </div>
                <div>
                  <label>Observações Nota Fiscal:</label>
                  <textarea
                    className="form-control"
                    onChange={(e) =>
                      onChangeObservacaoNotaFiscal(e.target.value, representada.representadaId)
                    }
                    value={representada.observacaoNotaFiscal}
                  ></textarea>
                  <hr></hr>
                </div>
              </div>
            ))}
            {model.percentualDescontoAdicional && model.percentualDescontoAdicional > 0 && (
              <div className="text-end">
                <label>
                  Total:{' '}
                  <NumberFormat
                    value={valorTotal}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'R$ '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </label>
                <br />
                <label>
                  Desconto Adicional %:{' '}
                  <NumberFormat
                    value={model.percentualDescontoAdicional}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'R$ '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </label>
                <br />
                <label>
                  Total Desconto Adicional :{' '}
                  <NumberFormat
                    value={valorTotalDescontoAdicional}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'R$ '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </label>
              </div>
            )}

            <div className="text-end total-pedido">
              <label>
                Total do Pedido:{' '}
                <NumberFormat
                  value={valorTotal - valorTotalDescontoAdicional}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$ '}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </label>
              <br />
              <br />
              <div className="row align-items-center valor-total">
                <div className="col text-end">
                  <label className="label-total">% Desconto Sobre a Tabela: </label>
                </div>
                <div className="col-auto">
                  <label className="valor-total-final">
                    <NumberFormat
                      value={
                        valorReferenciaTotal > 0 && valorReferenciaTotal > valorTotal
                          ? calcularPorcentagemDesconto(valorReferenciaTotal, valorTotal)
                          : 0
                      }
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={2}
                      suffix=" %"
                      fixedDecimalScale={true}
                    />
                  </label>
                </div>
              </div>
              <div className="row align-items-center valor-total">
                <div className="col text-end">
                  <label>Total Desconto Sobre a Tabela: </label>
                </div>
                <div className="col-auto">
                  <label className="valor-total-final">
                    <NumberFormat
                      value={
                        valorTotal > 0
                          ? valorReferenciaTotal > 0 && valorReferenciaTotal > valorTotal
                            ? valorReferenciaTotal - valorTotal
                            : 0
                          : 0
                      }
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={'R$'}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="row align-items-center check-pedido-padrao">
              <div className="col-auto">
                {' '}
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="pedidoPadrao"
                  value={true}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col">
                <label className="form-check-label" htmlFor="pedidoPadrao">
                  {'  '}Tornar esse pedido padrão (selecione esta opção caso queira salvar este
                  pedido como padrão para esse cliente)
                </label>
              </div>
            </div>
            {(!precoInvalido || (precoInvalido && !userData?.equipe?.nome)) && (
              <div className="row">
                <div className="col-md-12 btn-finalizar">
                  <button className="btn btn-primary " onClick={() => finalizar(false, false)}>
                    <FontAwesomeIcon icon={faSave} />
                    {' Finalizar'}
                  </button>
                </div>
                {/*<div className="col-md-6 btn-finalizar">
                  <button
                    className="btn btn-secondary"
                    onClick={() => finalizar(true, false)}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                    {" Finalizar e Enviar por Email"}
                  </button>
                </div>*/}
              </div>
            )}
            {precoInvalido && userData?.equipe?.nome && (
              <div className="row">
                <div className="col-md-12 btn-finalizar">
                  <button className="btn btn-primary " onClick={() => finalizar(false, false)}>
                    <FontAwesomeIcon icon={faSave} />
                    {' Finalizar e enviar o pedido para a aprovação do responsável'}
                  </button>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12 btn-finalizar">
                <button className="btn btn-secondary " onClick={() => salvar()}>
                  Salvar e continuar mais tarde
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PedidoRevisao;
