import ServiceClient from './service-client';

class LojaService {
  static async getAsync(id) {
    try {
      var result = await ServiceClient.Get('/loja/get', { lojaId: id });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCadastroAsync(id) {
    try {
      var result = await ServiceClient.Get('/loja/get-cadastro', { lojaId: id });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExtratoLojas(filter) {
    try {
      var result = await ServiceClient.Post('/loja/get-extrato-em-bytes', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCadastroDistribuidorAsync(id) {
    try {
      var result = await ServiceClient.Get('/loja/get-cadastro-distribuidor', { lojaId: id });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getDistribuidorAsync(id) {
    try {
      var result = await ServiceClient.Get('/loja/get-distribuidor', { lojaId: id });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get('/loja/list-paged', filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addAsync(loja) {
    try {
      var result = await ServiceClient.Post('/loja/add', loja);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(loja) {
    try {
      var result = await ServiceClient.Post('/loja/update', loja);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateStatusAsync(loja) {
    try {
      var result = await ServiceClient.Post('/loja/update-status', { lojaId: loja.lojaId });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addDistribuidorAsync(loja) {
    try {
      var result = await ServiceClient.Post('/loja/add-distribuidor', loja);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateDistribuidorAsync(loja) {
    try {
      var result = await ServiceClient.Post('/loja/update-distribuidor', loja);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateStatusDistribuidorAsync(loja) {
    try {
      var result = await ServiceClient.Post('/loja/update-status-distribuidor', {
        lojaId: loja.lojaId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listTabelaPrecosDistribuidorAsync() {
    try {
      var result = await ServiceClient.Get('/loja/list-tabela-precos-distribuidor');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listRedesAsync() {
    try {
      var result = await ServiceClient.Get('/loja/list-redes');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listCanaisAsync() {
    try {
      var result = await ServiceClient.Get('/loja/list-canais');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listRegionaisAsync() {
    try {
      var result = await ServiceClient.Get('/loja/list-regionais');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async validateCnpjAsync(loja) {
    try {
      var result = await ServiceClient.Post('/loja/validate-cnpj', loja);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default LojaService;
