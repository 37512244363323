import React from 'react';
import Select from 'react-select';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import RelatorioService from '../../services/relatorio-service';
import RepresentadaService from '../../services/representada-service';
import lib from '../../helpers/lib';
import { Tab, Table, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faFileExcel,
  faFilePdf,
  faFilter,
  faSquareArrowUpRight
} from '@fortawesome/free-solid-svg-icons';
import PedidoService from '../../services/pedido-service';

const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require('html-to-pdfmake');

export default function LojasSemPedidos() {
  const [model, setModel] = React.useState([]);
  const [minimoDias, setMinimoDias] = React.useState({
    label: '60',
    value: 60
  });
  const [representadas, setRepresentadas] = React.useState();
  const [vendedores, setVendedores] = React.useState();
  const [representadaSelecionada, setRepresentadaSelecionada] = React.useState();
  const [vendedorSelecionado, setVendedorSelecionado] = React.useState();
  const [ordenacaoPeloMenor, setOrdenacaoPeloMenor] = React.useState(true);
  const [opcoes, _] = React.useState([
    { label: '60', value: 60 },
    { label: '120', value: 120 },
    { label: '180', value: 180 },
    { label: 'SEM LIMITE', value: 0 }
  ]);

  React.useEffect(() => {
    (async () => {
      window.showLoader();
      let response = await RelatorioService.getLojasSemPedidosAsync({
        dias: minimoDias.value
      });
      orderList(ordenacaoPeloMenor, response?.data?.data ?? []);
      window.hideLoader();
    })();
    (async () => {
      await getSelectListRepresentada();
      await getSelectListVendedor();
    })();
  }, []);

  async function getLojas(dias, representada, vendedor) {
    window.showLoader();
    let response = await RelatorioService.getLojasSemPedidosAsync({
      dias: dias,
      representadaId: representada ? representada : undefined,
      vendedorId: vendedor ? vendedor : undefined
    });
    orderList(ordenacaoPeloMenor, response?.data?.data ?? []);
    window.hideLoader();
  }

  async function getSelectListVendedor() {
    let vendedores = await PedidoService.getSelectListVendedor();
    if (vendedores.data) setVendedores(vendedores.data.data); //([...[{}], ...representadas.data.data]);
  }

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAllAsync();
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  function calcularDias(dataPedido) {
    const today = new Date();
    const date = new Date(dataPedido);
    const dif = today.getTime() - date.getTime();
    const totalDays = Math.ceil(dif / (1000 * 3600 * 24));

    return totalDays.toString();
  }

  function toggleOrdenacaoPeloMenor() {
    const newValue = !ordenacaoPeloMenor;
    setOrdenacaoPeloMenor(newValue);
    orderList(newValue, model);
  }

  function orderList(ascOrder, list) {
    if (ascOrder) {
      const newList = [...list].sort((x, y) => {
        return new Date(y.ultimaCompra) - new Date(x.ultimaCompra);
      });
      newList.forEach((i) => {
        i.ultimaCompraFormatted = lib.getStringFromDate(i.ultimaCompra);
        i.dias = calcularDias(i.ultimaCompra);
      });
      setModel(newList);
    } else {
      const newList = [...list].sort((x, y) => {
        return new Date(x.ultimaCompra) - new Date(y.ultimaCompra);
      });
      newList.forEach((i) => {
        i.ultimaCompraFormatted = lib.getStringFromDate(i.ultimaCompra);
        i.dias = calcularDias(i.ultimaCompra);
      });
      setModel(newList);
    }
  }

  function changeDias(selectedValue) {
    setMinimoDias(selectedValue);
    getLojas(selectedValue.value, representadaSelecionada?.value, vendedorSelecionado?.value);
  }

  function changeRepresentada(selectedValue) {
    setRepresentadaSelecionada(selectedValue);
    getLojas(minimoDias.value, selectedValue?.value, vendedorSelecionado?.value);
  }

  function changeVendedor(selectedValue) {
    setVendedorSelecionado(selectedValue);
    getLojas(minimoDias.value, representadaSelecionada?.value, selectedValue?.value);
  }

  function exportarClick() {
    exportToCSV(model, 'Lojas Sem Pedidos');
  }

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  async function exportToPDF() {
    window.showLoader();
    const rawFilter = {
      dias: minimoDias.value,
      representadaId: representadaSelecionada?.value,
      vendedorId: vendedorSelecionado?.value
    };

    let result = await RelatorioService.getRelatorioAnaliticoUltimoPedidoPdf(rawFilter);

    if (result && result.data) {
      let fileName = `lojas-sem-compras-${lib.getCurrentDateFilename()}.pdf`;
      lib.downloadFile(result.data.data, fileName);

      window.hideLoader();
    } else {
      window.hideLoader();
    }
  }

  const exportToCSV = (csvData, fileName) => {
    const dataFormatted = csvData.map((i) => {
      return {
        CNPJ: i.cnpj.toString(),
        'Nome do Cliente': i.nome,
        Cidade: i.cidade,
        Vendedor: i.vendedor,
        'Última Compra': lib.getStringFromDate(i.ultimaCompra),
        'Dias Sem Comprar': calcularDias(i.ultimaCompra)
      };
    });
    const ws = XLSX.utils.json_to_sheet(dataFormatted);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      <div className="row" style={{ marginBottom: '15px' }}>
        {/* Minimo de dias ------------------------------------------------------------------------------ */}
        <div className="col-xs-12 col-md-3">
          <label>Mínimo de Dias</label>
          <Select
            styles={customControlStyles}
            placeholder="Selecione..."
            options={opcoes}
            value={minimoDias}
            onChange={(selectedValue) => {
              changeDias(selectedValue);
            }}
          />
        </div>
        {/* Representada ---------------------------------------------------------------------- */}
        <div className="col-xs-12 col-md-3" disabled>
          <label>Representada</label>
          <Select
            // className="select-form-control"
            styles={customControlStyles}
            isClearable
            clearValue={() => {
              setRepresentadaSelecionada({});
            }}
            placeholder="Selecione..."
            options={representadas}
            value={representadas?.find((x) => x.value === representadaSelecionada?.value)}
            onChange={(selectedValue) => {
              changeRepresentada(selectedValue);
            }}
          />
        </div>
        {/* Vendedor ---------------------------------------------------------------------- */}
        <div className="col-xs-12 col-md-3">
          <label>Vendedor</label>
          <Select
            isClearable
            clearValue={() => {
              setVendedorSelecionado({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={vendedores}
            value={vendedores?.find((x) => x.value === vendedorSelecionado?.value)}
            onChange={(selectedValue) => {
              changeVendedor(selectedValue);
            }}
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-12 d-flex justify-content-end ">
          {/* Botão Filtrar --------------------------------------------------------------------- */}
          <button
            type="button"
            style={{ marginTop: '20px', width: '300px' }}
            className="btn btn-secondary btn-sm btn-action"
            onClick={exportarClick}
          >
            <FontAwesomeIcon icon={faFileExcel} /> Exportar Excel
          </button>
          <button
            type="button"
            style={{ marginTop: '20px', width: '300px', marginLeft: '20px' }}
            className="btn btn-secondary btn-sm btn-action"
            onClick={exportToPDF}
          >
            <FontAwesomeIcon icon={faFilePdf} /> Exportar PDF
          </button>
        </div>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>CNPJ</th>
            <th>Nome do Cliente</th>
            <th>Cidade</th>
            <th>Vendedor</th>
            <th>Última Compra</th>
            <th onClick={toggleOrdenacaoPeloMenor}>
              Dias sem Comprar &nbsp;&nbsp;
              {ordenacaoPeloMenor ? (
                <FontAwesomeIcon icon={faArrowUp} color="#000" size="sm" />
              ) : (
                <FontAwesomeIcon icon={faArrowDown} color="#000" size="sm" />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {(model || []).map((item, index) => (
            <tr key={index}>
              <td>{item.cnpj}</td>
              <td>{item.nome}</td>
              <td>{item.cidade}</td>
              <td>{item.vendedor}</td>
              <td>{item.ultimaCompraFormatted}</td>
              <td className="d-flex justify-content-center">{item.dias}</td>
            </tr>
          ))}
          {(!model || model.length == 0) && (
            <tr>
              <td colSpan={5} align="center">
                Sem Registros
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div id="tabela-clientes" style={{ display: 'none' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>CNPJ</th>
              <th>Nome do Cliente</th>
              <th>Cidade</th>
              <th>Última Compra</th>
              <th onClick={toggleOrdenacaoPeloMenor}>Dias sem Comprar</th>
            </tr>
          </thead>
          <tbody>
            {(model || []).map((item, index) => (
              <tr key={index}>
                <td>{item.cnpj}</td>
                <td>{item.nome}</td>
                <td>{item.cidade}</td>
                <td>{item.ultimaCompraFormatted}</td>
                <td className="d-flex justify-content-center">{item.dias}</td>
              </tr>
            ))}
            {(!model || model.length == 0) && (
              <tr>
                <td colSpan={5} align="center">
                  Sem Registros
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
