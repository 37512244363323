import ServiceClient from './service-client';
import { service_url } from './service-constants';

class MeusDadosService {
  static async getAsync() {
    try {
      var result = await ServiceClient.Get(service_url.meusdados_get);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async updateAsync(usuario) {
    try {
      var result = await ServiceClient.FormPost(service_url.meusdados_update, usuario);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async changePasswordAsync(model) {
    try {
      var result = await ServiceClient.Post(service_url.meusdados_changePassword, model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default MeusDadosService;
