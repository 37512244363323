import ServiceClient from './service-client';
import { service_url } from './service-constants';

class PedidoService {
  static async getAsync(id) {
    try {
      var result = await ServiceClient.Get('/pedido/get', { pedidoId: id });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async GetPedidoTiposAsync() {
    try {
      var result = await ServiceClient.Get(service_url.pedido_tipos, {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async GetResumoAsync(pedidoId, representadaId) {
    try {
      var result = await ServiceClient.Get(service_url.pedido_resumo, {
        pedidoId: pedidoId,
        representadaId: representadaId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getResumoAvaliacaoEmPdfAsync(pedidoId, representadaId) {
    try {
      var result = await ServiceClient.Get(service_url.pedido_resumo_pdf, {
        pedidoId: pedidoId,
        representadaId: representadaId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async GetHistoricoPagedAsync(filter) {
    try {
      var result = await ServiceClient.Post(service_url.pedido_historicopaged, filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listRepresentadaPrazosAsync(representadaIds) {
    try {
      var result = await ServiceClient.Get(
        `/pedido/list-representada-prazos?${representadaIds
          .map((n, index) => `representadaIds[${index}]=${n}`)
          .join('&')}`
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async ListPedidoAvaliacaoPagedAsync(filter) {
    try {
      var result = await ServiceClient.Get(service_url.pedido_avaliacao_paged, filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListLoja() {
    try {
      var result = await ServiceClient.Get('/pedido/select-list-loja');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListVendedor() {
    try {
      var result = await ServiceClient.Get('/pedido/select-list-vendedor');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListRepresentada() {
    try {
      var result = await ServiceClient.Get('/pedido/select-list-representada');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExtratoAsync(filter) {
    try {
      var result = await ServiceClient.Post(service_url.pedido_extrato, filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getExtratoFinanceiroAsync(filter) {
    try {
      var result = await ServiceClient.Post(service_url.pedido_extrato_financeiro, filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCountPedidosAvaliacao() {
    try {
      var result = await ServiceClient.Get('pedido/get-count-pedidos-avaliacao');

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async validateAsync(pedido) {
    try {
      var result = await ServiceClient.Post('/pedido/validate', pedido);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async addAsync(model) {
    try {
      var result = await ServiceClient.Post('/pedido/add', model);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async aprovarAsync(id) {
    try {
      var result = await ServiceClient.FormPost('/pedido/aprovar', {
        pedidoRepresentadaId: id
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async cancelarAsync(id) {
    try {
      var result = await ServiceClient.FormPost('/pedido/cancelar', {
        pedidoRepresentadaId: id
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getResumoMensalAsync(representadaId, mes, ano) {
    try {
      var result = await ServiceClient.Get('/pedido/get-resumo-mensal', {
        representadaId: representadaId,
        mes: mes,
        ano: ano
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async enviarEmailRepresentadaAsync(pedidoRepresentadaId) {
    try {
      var result = await ServiceClient.Get('/pedido/enviar-email-representada', {
        pedidoRepresentadaId: pedidoRepresentadaId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getUltimosEmailsDoUsuarioAsync(representadaId) {
    try {
      var result = await ServiceClient.Get(
        '/pedido/get-emails-ultimo-pedido-usuario/' + representadaId
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async enviarPedidoParaEmailsAsync(pedidoRepresentadaId, representadaId, emails) {
    try {
      const result = await ServiceClient.Post('/pedido/enviar-pedido-para-emails', {
        pedidoRepresentadaId: pedidoRepresentadaId,
        representadaId: representadaId,
        emails: emails
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async listProdutosAsync(lojaId) {
    try {
      var result = await ServiceClient.Get(service_url.pedido_list_produtos, {
        lojaId: lojaId
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  //

  static async listProdutosPorRepresentadasAsync(resentadasIds, pageNumber = null, pageSize = null) {
    try {
      var result = await ServiceClient.Post(service_url.pedido_list_produtos_por_representadas, {
        representadaIds: resentadasIds,
        pageNumber: pageNumber,
        pageSize: pageSize,
      });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getUnidadeConverterAsync(filter) {
    try {
      var result = await ServiceClient.Get(service_url.pedido_unidade_converter, filter);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static calcularPorcentagem(percentual, total) {
    if (percentual == 0 || total == 0) return 0;

    const desconto = (total * percentual) / 100;
    return desconto;
  }

  static somarPorcentagem(porcentagemProduto, porcentagemAdicional) {
    let desc = 0.0;
    let descAdic = 0.0;
    if (
      Number.isNaN(porcentagemProduto) == false &&
      porcentagemProduto != undefined &&
      porcentagemProduto != null
    )
      desc = porcentagemProduto;
    if (
      Number.isNaN(porcentagemAdicional) == false &&
      porcentagemAdicional != undefined &&
      porcentagemAdicional != null
    )
      descAdic = porcentagemAdicional;

    return desc + descAdic;
  }
}

export default PedidoService;
