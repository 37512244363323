import React from 'react';
import Layout from '../../components/layout/base/index';
import PageTitle from '../../components/page-title/index';
import AlertPedidosAvaliacao from '../../components/alert-pedidos-avaliacao';
import _tableau from './_tableau';

function TesteHome() {
  return (
    <Layout>
      <PageTitle title="HOME"></PageTitle>

      <AlertPedidosAvaliacao />

      <div className="home-web d-flex">
        <_tableau />
      </div>
    </Layout>
  );
}

export default TesteHome;
