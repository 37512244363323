import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFilter, faPlus, faRecycle, faTrash } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title/index';
import Layout from '../../components/layout/base/index';

import { LoggedUserContext } from '../../contexts/logged-user-context';

import OwnerTeams from './owner-teams';
import MemberTeams from './member-teams';

import EquipeService from '../../services/equipe-service';

import lib from '../../helpers/lib';

import './styles.scss';

export default function Time() {
  const [userData, _] = React.useContext(LoggedUserContext);

  return (
    <Layout>
      <PageTitle title="MEUS TIMES" />

      {userData?.cadastraEquipe && (
        <>
          <OwnerTeams />
          <hr />
        </>
      )}

      <MemberTeams />
    </Layout>
  );
}
