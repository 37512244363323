import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import SelectMulti from '../../components/_base/multi-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEraser, faFilePdf } from '@fortawesome/free-solid-svg-icons';

import TabelaRede4 from './components/tabela-rede4';
import FaturamentoRepresentada from './components/FaturamentoRepresentada';
import FaturamentoCliente from './components/FaturamentoCliente';
import FaturamentoCategoria from './components/FaturamentoCategoria';
import FaturamentoProduto from './components/FaturamentoProduto';

import DashboardService from '../../services/dashboard-service';
import RepresentadaService from '../../services/representada-service';
import RedeService from '../../services/rede-service';
import lib from '../../helpers/lib';

import './styles.scss';

export default function Detalhamento() {
  const data = new Date();

  const [detalhamento, setDetalhamento] = useState({});

  const [redes, setRedes] = useState();
  const [representadas, setRepresentadas] = useState();
  const [vendedores, setVendedores] = useState();
  const [categorias, setCategorias] = useState();
  const [produtos, setProdutos] = useState();

  const refFilter = useRef({});
  const [filter, setFilter] = useState({
    representadas: [],
    redes: [],
    vendedores: [],
    categorias: [],
    produtos: [],
    ano: data.getFullYear(),
    mes: data.getMonth() + 1
  });

  useEffect(() => {
    getSelectListRepresentada();
    getSelectListRede();
    getSelectListVendedor();
    getSelectListCategoria();
    getSelectListProduto();

    getRelatorio(false);
  }, []);

  async function getRelatorio(exibeloader) {
    if (exibeloader) showLoader();

    //atualizar os vendedores se houve alteração do periodo
    if (refFilter.current.ano !== filter.ano || refFilter.current.mes !== filter.mes) {
      getSelectListRepresentada();
      getSelectListRede();
      getSelectListVendedor();
      getSelectListCategoria();
      getSelectListProduto();
    }

    refFilter.current = getFilterParams();

    await Promise.all([getDetalhamento()]);

    hideLoader();
  }

  async function getSelectListRepresentada() {
    let representadas = await DashboardService.getSelectListRepresentada({
      ano: filter.ano,
      mes: filter.mes
    });
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getSelectListRede() {
    const result = await DashboardService.getSelectListRede({
      ano: filter.ano,
      mes: filter.mes
    });
    if (result.data) setRedes(result.data.data);
  }

  async function getSelectListVendedor() {
    const result = await DashboardService.getSelectListVendedor({
      ano: filter.ano,
      mes: filter.mes
    });
    if (result.data) setVendedores(result.data.data);
  }

  async function getSelectListCategoria() {
    const result = await DashboardService.getSelectListCategoria({
      ano: filter.ano,
      mes: filter.mes
    });
    if (result.data) setCategorias(result.data.data);
  }

  async function getSelectListProduto() {
    const result = await DashboardService.getSelectListProduto({
      ano: filter.ano,
      mes: filter.mes
    });
    if (result.data) setProdutos(result.data.data);
  }

  async function getDetalhamento() {
    let response = await DashboardService.getDetalhamentoAsync(refFilter.current);

    setDetalhamento(response.data.data);
  }

  function getFilterParams() {
    return {
      representadaIds: filter.representadas.map((x) => x.value),
      redeIds: filter.redes.map((x) => x.value),
      vendedorIds: filter.vendedores.map((x) => x.value),
      categoriaIds: filter.categorias.map((x) => x.value),
      produtoIds: filter.produtos.map((x) => x.value),
      mes: filter.mes,
      ano: filter.ano
    };
  }

  function limpar() {
    filter.representadas = [];
    filter.redes = [];
    filter.vendedores = [];
    filter.categorias = [];
    filter.produtos = [];
    filter.ano = data.getFullYear();
    filter.mes = data.getMonth() + 1;

    setFilter({ ...filter });

    getRelatorio(true);
  }

  return (
    <div className="dashboard-page">
      <div>
        <div className="row">
          {/* Representada ---------------------------------------------------------------------- */}
          <div className="col-lg-3" disabled>
            <label>Representada</label>
            <SelectMulti
              options={representadas}
              value={filter.representadas}
              onChange={(options) => setFilter({ ...filter, representadas: options })}
              //filterOptions={search}
            />
          </div>

          {/* Rede ---------------------------------------------------------------------- */}
          <div className="col-lg-3" disabled>
            <label>Rede</label>
            <SelectMulti
              options={redes}
              value={filter.redes}
              onChange={(options) => setFilter({ ...filter, redes: options })}
              //filterOptions={search}
            />
          </div>

          <div className="col-lg-3" disabled>
            <label>Vendedor</label>
            <SelectMulti
              options={vendedores}
              value={filter.vendedores}
              onChange={(options) => setFilter({ ...filter, vendedores: options })}
              //filterOptions={search}
            />
          </div>

          <div className="col-lg-3" disabled>
            <label>Categoria</label>
            <SelectMulti
              options={categorias}
              value={filter.categorias}
              onChange={(options) => setFilter({ ...filter, categorias: options })}
              //filterOptions={search}
            />
          </div>

          <div className="col-lg-3" disabled>
            <label>Produto</label>
            <SelectMulti
              options={produtos}
              value={filter.produtos}
              onChange={(options) => setFilter({ ...filter, produtos: options })}
              //filterOptions={search}
            />
          </div>

          <div className="col-lg-3">
            <label>Mês</label>
            <Select
              className="select-form-control"
              value={lib.selectListMeses?.find((item) => item.value === filter.mes)}
              options={lib.selectListMeses}
              isClearable={false}
              onChange={(item) => {
                setFilter({ ...filter, mes: item.value });
              }}
            />
          </div>
          <div className="col-lg-3">
            <label>Ano</label>
            <Select
              className="select-form-control"
              value={lib.selectListAnos?.find((item) => item.value === filter.ano)}
              options={lib.selectListAnos}
              isClearable={false}
              onChange={(item) => {
                setFilter({ ...filter, ano: item.value });
              }}
            />
          </div>
        </div>
        <div className="row mt-3 justify-content-end">
          <div className="col-3 text-center">
            <button type="button" className="btn btn-secondary btn-action w-100" onClick={limpar}>
              <FontAwesomeIcon icon={faEraser} /> Limpar
            </button>
          </div>

          <div className="col-3 text-center">
            <button
              type="button"
              className="btn btn-secondary btn-action w-100"
              onClick={() => getRelatorio(true)}
            >
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <div className="row m-0">
          <div className="col-lg-12">
            <TabelaRede4 source={detalhamento} />
          </div>
        </div>
        <div className="row m-0 custom-container">
          <div className="col-lg-3 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
            <FaturamentoRepresentada source={detalhamento} />
          </div>
          <div className="col-lg-3 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
            <FaturamentoCliente source={detalhamento} />
          </div>
          <div className="col-lg-3 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
            <FaturamentoCategoria source={detalhamento} />
          </div>
          <div className="col-lg-3 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
            <FaturamentoProduto source={detalhamento} />
          </div>
        </div>
      </div>
    </div>
  );
}
