import React from 'react';

import './styles.scss';

const Loader = () => {
  return (
    <div id="globalLoader" className="loader-gif">
      <div className="spinner-border text-secondary" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  );
};

export default Loader;
