import ServiceClient from './service-client';

class GeneralService {
  static async getCNPJAsync(cnpj) {
    try {
      var result = await ServiceClient.Get('/general/get-cnpj', { cnpj: cnpj });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getEnderecoAsync(cep) {
    try {
      var result = await ServiceClient.Get('/general/get-endereco', { cep: cep });

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default GeneralService;
