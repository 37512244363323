import React from 'react';
import VMasker from 'vanilla-masker';

export default function InputCnpj({
  className,
  placeholder,
  onChange,
  value,
  onKeyDown,
  disabled
}) {
  return (
    <input
      type="text"
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={(event) => {
        let input = event.target.value;
        input = VMasker.toPattern(input, '99999999999999999999');
        input = VMasker.toPattern(input, '99.999.999/9999-99');
        onChange(input);
      }}
      onKeyDown={onKeyDown}
    />
  );
}
