import React, { useEffect } from 'react';
import Select, { StylesConfig } from 'react-select';
import { useQuery, useQueryClient } from 'react-query';
import lib from '../../helpers/lib';

import TablePagination from '../../components/table-pagination';
import PageTitle from '../../components/page-title/index';
import Layout from '../../components/layout/base/index';
import ModalResumoPedido from '../../components/modal-resumo-pedido';
import DatePickerCustom from '../../components/_base/DatePicker';
import { LoggedUserContext } from '../../contexts/logged-user-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEye } from '@fortawesome/free-solid-svg-icons';

import PedidoService from '../../services/pedido-service';
import RepresentadaService from '../../services/representada-service';
import RedeService from '../../services/rede-service';
import ModalResumoPedidoAvaliacao from '../../components/modal-resumo-pedido-avaliacao';

export default function PedidoAvaliacao() {
  const [userData, _] = React.useContext(LoggedUserContext);

  const queryClient = useQueryClient();

  const [filter, setFilter] = React.useState({});
  const { data: pageData } = useQuery(['pedidos', filter], () => getPedidos(filter));

  const [showModalResumo, setShowModalResumo] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const [codigo, setCodigo] = React.useState('');
  const [dataInicial, setDataInicial] = React.useState();
  const [dataFinal, setDataFinal] = React.useState();
  const [representadas, setRepresentadas] = React.useState();
  const [representadaSelecionada, setRepresentadaSelecionada] = React.useState();
  const [redes, setRedes] = React.useState();
  const [redeSelecionada, setRedeSelecionada] = React.useState();
  const [lojas, setLojas] = React.useState();
  const [lojaSelecionada, setLojaSelecionada] = React.useState();
  const [situacao, setSituacao] = React.useState('');

  // ------------------------------------------------------------------------------------------
  // Efeitos
  useEffect(() => {
    (async () => {
      window.showLoader();
      await getSelectListRepresentada();
      await getSelectListRede();
      await getSelectListLoja();
      window.hideLoader();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await queryClient.invalidateQueries(['pedidos']);
    })();
  }, [filter]);

  // ------------------------------------------------------------------------------------------
  // Funcoes
  async function getPedidos(filter) {
    window.showLoader();

    let response = await PedidoService.ListPedidoAvaliacaoPagedAsync(filter);

    window.hideLoader();

    return response?.data?.data || [];
  }

  async function getSelectListRepresentada() {
    let representadas = await RepresentadaService.selectListAsync();
    if (representadas.data) setRepresentadas(representadas.data.data); //([...[{}], ...representadas.data.data]);
  }

  async function getSelectListRede() {
    let redes = await RedeService.getSelectList();
    if (redes.data) setRedes(redes.data.data); //([...[{}], ...redes.data.data]);
  }

  async function getSelectListLoja() {
    let lojas = await PedidoService.getSelectListLoja();
    if (lojas.data) setLojas(lojas.data.data); //([...[{}], ...lojas.data.data]);
  }

  return (
    <Layout>
      <PageTitle title="Avaliar Pedidos"></PageTitle>
      <div>&nbsp;</div>

      {/* Filter ------------------------------------------------------------------------------ */}
      <div className="row filter-bar2">
        {/* Código  --------------------------------------------------------------------------- */}
        <div className="col-lg-3 mb-3">
          <label>Pedido</label>
          <input
            type="text"
            className="form-control"
            value={codigo}
            onChange={(ev) => {
              setCodigo(ev.target.value);
            }}
          />
        </div>
        {/* Representada ---------------------------------------------------------------------- */}
        <div className="col-lg-3 mb-3" disabled>
          <label>Representada</label>
          <Select
            isClearable
            clearValue={() => {
              setRepresentadaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={representadas}
            value={representadas?.find((x) => x.value === representadaSelecionada)}
            onChange={(selectedValue) => {
              //setRedeSelecionada(selectedValue?.nome ?? {});
              setRepresentadaSelecionada(selectedValue?.value);
            }}
          />
        </div>
        {/* Rede ------------------------------------------------------------------------------ */}
        <div className="col-lg-3 mb-3" disabled>
          <label>Rede</label>
          <Select
            isClearable
            clearValue={() => {
              setRedeSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={redes}
            value={redes?.find((x) => x.value === redeSelecionada)}
            onChange={(selectedValue) => setRedeSelecionada(selectedValue?.value)}
          />
        </div>
        {/* Loja ------------------------------------------------------------------------------ */}
        <div className="col-lg-3 mb-3">
          <label>Loja</label>
          <Select
            isClearable
            clearValue={() => {
              setLojaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={lojas}
            value={lojas?.find((x) => x.value === lojaSelecionada)}
            onChange={(selectedValue) => setLojaSelecionada(selectedValue?.value)}
          />
        </div>
      </div>

      {/* Filter date-------------------------------------------------------------------------- */}
      <div className="row filter-bar2">
        <div className="col-lg-3 mb-3">
          <label>Data Inicial </label>
          <DatePickerCustom
            name="dataInicial"
            selected={dataInicial}
            onChange={(value) => {
              setDataInicial(value);
            }}
          />
        </div>
        <div className="col-lg-3 mb-3">
          <label>Data Final</label>
          <DatePickerCustom
            name="dataFinal"
            selected={dataFinal}
            onChange={(value) => {
              setDataFinal(value);
            }}
          />
        </div>
        {/* Botão Filtrar --------------------------------------------------------------------- */}
        <div className="col-lg-3 mb-3 d-flex align-items-center">
          <button
            type="button"
            className="btn btn-secondary btn-sm btn-action"
            onClick={() => {
              setFilter({
                ...filter,
                codigo: codigo,
                dataInicio: dataInicial,
                dataFim: dataFinal,
                representadaId: representadaSelecionada,
                lojaId: lojaSelecionada,
                redeId: redeSelecionada,
                situacao: situacao,
                pageNumber: 1
              });
            }}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>
        </div>

        {/* Botão Exportar --------------------------------------------------------------------
        <div className="col-lg-3 mb-3">
          <button
            type="button"
            className="btn btn-secondary btn-sm btn-action"
            onClick={() => {
              downloadExtratoExcel();
            }}
          >
            <FontAwesomeIcon icon={faFileExcel} /> Exportar
          </button>
        </div> */}
      </div>

      {/* Lista Representadas ----------------------------------------------------------------- */}
      <div className="table-responsive">
        <table className="table table-cadastro">
          <thead>
            <tr>
              <th>Pedido</th>
              <th>Rede</th>
              <th>Loja</th>
              <th>Representada</th>
              <th>Data</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(pageData?.data || []).map((item, index) => (
              <tr key={index}>
                <td>
                  {item.codigoPedidoLoja ? item.codigoPedidoLoja : item.codigoPedidoRepresentada}
                </td>
                <td>{item.redeNome}</td>
                <td>{item.lojaNomeFantasia ?? item.lojaRazaoSocial}</td>
                <td>{item.representadaNomeFantasia}</td>
                <td>{lib.getStringFromDate(item.data)}</td>
                <td className="action-btns">
                  {/* Botao Resumo -------------------------------------------------------------- */}
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    title="Ver Pedido"
                    onClick={() => {
                      setSelected(item);
                      setShowModalResumo(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>

                  {/* <a href={item.urlArquivoPedido} target="_blank">
                    <button
                      type="button"
                      disabled={item.urlArquivoPedido ? false : true}
                      className="btn btn-secondary btn-sm"
                      title="Baixar PDF"
                      onClick={() => { }}
                    >
                      <FontAwesomeIcon icon={faFilePdf} />
                    </button>
                  </a>

                  {userData?.cancelaPedido && <button
                    type="button"
                    //disabled={item.urlArquivoPedido ? false : true}
                    className="btn btn-danger btn-sm"
                    title="Cancelar pedido"
                    onClick={() => {
                      setSelected(item);
                      setShowModalCancelar(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faCancel} />
                  </button>} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Paginacao --------------------------------------------------------------------------- */}
      <TablePagination
        pageData={pageData}
        onChangePage={(page) => {
          setFilter({ ...filter, pageNumber: page });
        }}
      />

      {/* Modal Resumo Pedido ----------------------------------------------------------------- */}
      <ModalResumoPedidoAvaliacao
        model={selected}
        showModal={showModalResumo}
        setShowModal={setShowModalResumo}
        onClose={() => {}}
      ></ModalResumoPedidoAvaliacao>
    </Layout>
  );
}
