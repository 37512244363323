import React from 'react';
import ModalCadastro from '../_base/modal-cadastro';

import ImageEsqueceuSenha from '../../assets/imagens/ativos/esqueceu_senha.png';
import LoginService from '../../services/login-service';
import lib from '../../helpers/lib';

export default function ModalEsqueceuSenha(props) {
  const { showModal, setShowModal } = props;

  const [email, setEmail] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  function show() {
    setShowModal(true);
  }

  function close() {
    setShowModal(false);
  }

  async function enviar() {
    setSuccess(false);
    setErrors({});

    let requestData = {
      email: email
    };

    window.showLoader();

    let result = await LoginService.recuperarSenhaAsync(requestData);

    window.hideLoader();

    if (result.status === lib.statusCode.ok) {
      setSuccess(true);
    } else {
      setErrors(result.data.errors);
    }
  }

  function displayErrors() {
    if (lib.isNullOrEmptyObject(errors)) return null;

    let listaErrors = [];
    for (let p in errors) {
      listaErrors = [...listaErrors, ...errors[p]];
    }

    return (
      <div className="alert alert-danger text-start mb-0 mt-2">
        <ul className="m-0">
          {listaErrors.map((descricao, index) => (
            <li key={index}>{descricao}</li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText="Recuperar senha"
      onClose={() => {
        setEmail('');
        setSuccess(false);
      }}
    >
      <div className="modal-body">
        <div className="row justify-content-center">
          <div className="col-xl-8 text-center">
            <div style={{ marginBottom: '20px' }}>
              <img
                className="img-fluid"
                src={ImageEsqueceuSenha}
                alt="esqueceu-senha"
                style={{ maxHeight: '200px' }}
              />
            </div>

            <p style={{ fontSize: '16px' }}>
              Informe seu e-mail abaixo para receber as instruções de recuperação de senha:
            </p>

            <div className="row justify-content-center mb-3">
              <div className="col-9">
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyDown={(e) => e.key === 'Enter' && enviar()}
                />

                <div className="mt-3">
                  {success && (
                    <div className="alert alert-success">
                      Um e-mail foi enviado com sucesso. Dentro de alguns minutos verifique sua
                      caixa de entrada ou spam.
                    </div>
                  )}

                  {displayErrors()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary w-100" onClick={enviar}>
          ENVIAR
        </button>
      </div>
    </ModalCadastro>
  );
}
