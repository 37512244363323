import React, { useEffect } from 'react';
import Select, { StylesConfig } from 'react-select';
import lib from '../../helpers/lib';

import TablePagination from '../../components/table-pagination';
import PageTitle from '../../components/page-title/index';
import Layout from '../../components/layout/base/index';
import ModalConfirmacao from '../../components/modal-confirmacao';
import DatePickerCustom from '../../components/_base/DatePicker';
import Checkbox from '../../components/_base/input-checkbox';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faFilter,
  faPlus,
  faRecycle,
  faTrash,
  faFileLines,
  faFilePdf,
  faFileExcel
} from '@fortawesome/free-solid-svg-icons';

import PedidoService from '../../services/pedido-service';
import PedidoRascunhoService from '../../services/pedido-rascunho-service';
import RedeService from '../../services/rede-service';
import { useNavigate } from 'react-router-dom';

//import "./styles.scss";

export default function PedidoRascunhoLista() {
  const [pageData, setPageData] = React.useState({
    pageNumber: 1,
    totalRows: 0,
    totalPages: 0
  });

  const [showModalConfirmacao, setShowModalConfirmacao] = React.useState(false);
  const [showModalMarcados, setShowModalMarcados] = React.useState(false);
  const [rascunhos, setRascunhos] = React.useState([]);
  const [selected, setSelected] = React.useState();
  const [dataInicial, setDataInicial] = React.useState();
  const [dataFinal, setDataFinal] = React.useState();
  const [lojas, setLojas] = React.useState();
  const [lojaSelecionada, setLojaSelecionada] = React.useState();
  const [representadas, setRepresentadas] = React.useState();
  const [representadaSelecionada, setRepresentadaSelecionada] = React.useState();
  const [filter, setFilter] = React.useState({
    codigo: ''
  });
  const [checkedItens, setCheckedItens] = React.useState([]);
  const [checkAll, setCheckAll] = React.useState(false);

  const navigate = useNavigate();

  // ----------------------------------------------------------------------------------------
  // Efeitos
  useEffect(() => {
    (async () => {
      window.showLoader();
      await getSelectListLoja();
      await getSelectListRepresentada();
      window.hideLoader();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getListaPedidoRascunho();
      clearChecks();
    })();
  }, [filter]);

  // ----------------------------------------------------------------------------------------
  // Funcoes
  const getListaPedidoRascunho = async (page) => {
    window.showLoader();

    let response = await PedidoRascunhoService.GetPagedAsync({
      ...filter,
      pageNumber: page
    });
    setPageData(response?.data?.data);
    setRascunhos(response?.data?.data?.data || []);

    window.hideLoader();
  };

  const removeRascunho = async (rascunho) => {
    window.showLoader();

    const response = await PedidoRascunhoService.removeAsync(rascunho?.pedidoRascunhoId);
    await getListaPedidoRascunho();

    window.hideLoader();
  };

  const removeRascunhosSelecionados = async () => {
    let itens = [];
    checkedItens.forEach((item, index) => {
      if (item === true) {
        itens.push(rascunhos[index]);
      }
    });

    if (itens.length <= 0) {
      return;
    }

    itens?.forEach(async (item) => {
      window.showLoader();
      const response = await PedidoRascunhoService.removeAsync(item?.pedidoRascunhoId);
      await getListaPedidoRascunho();
      clearChecks();
      window.hideLoader();
    });
  };

  async function getSelectListLoja() {
    let lojas = await PedidoService.getSelectListLoja();
    if (lojas.data) {
      setLojas(lojas.data.data);
    }
  }

  async function getSelectListRepresentada() {
    let representadas = await PedidoService.getSelectListRepresentada();
    if (representadas.data) {
      setRepresentadas(representadas.data.data);
    }
  }

  function toggleCheck(idx) {
    checkedItens[idx] = !checkedItens[idx];
    let checks = [];
    checkedItens.forEach((item, index) => {
      checks[index] = item;
    });
    setCheckedItens(checks);
    setCheckAll(false);
  }

  function clearChecks() {
    let checks = [];
    rascunhos.forEach((item, index) => {
      checks[index] = false;
    });
    setCheckedItens(checks);
  }

  function checkAllItens() {
    let checks = [];
    rascunhos.forEach((item, index) => {
      checks[index] = true;
    });
    setCheckedItens(checks);
  }

  // ----------------------------------------------------------------------------------------
  // Renderizacao
  return (
    <Layout>
      <PageTitle title="Rascunhos de Pedidos"></PageTitle>
      <div>&nbsp;</div>

      {/* Filter ------------------------------------------------------------------------ */}
      <div className="row filter-bar2">
        {/* Loja ---------------------------------------------------------------------- */}
        <div className="col-lg-3 mb-3">
          <label>Loja</label>
          <Select
            isClearable
            clearValue={() => {
              setLojaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={lojas}
            value={lojas?.find((x) => x.value === lojaSelecionada)}
            onChange={(selectedValue) => setLojaSelecionada(selectedValue?.value)}
          />
        </div>
        {/* Representada -------------------------------------------------------------- */}
        <div className="col-lg-3 mb-3" disabled>
          <label>Representada</label>
          <Select
            isClearable
            clearValue={() => {
              setRepresentadaSelecionada({});
            }}
            styles={customControlStyles}
            placeholder="Selecione..."
            options={representadas}
            value={representadas?.find((x) => x.value === representadaSelecionada)}
            onChange={(selectedValue) => {
              setRepresentadaSelecionada(selectedValue?.value);
            }}
          />
        </div>
        {/* Data Inicial -------------------------------------------------------------- */}
        <div className="col-lg-3 mb-4">
          <label>Data Inicial </label>
          <DatePickerCustom
            name="dataInicial"
            selected={dataInicial}
            onChange={(value) => {
              setDataInicial(value);
            }}
          />
        </div>
        {/* Data Final ---------------------------------------------------------------- */}
        <div className="col-lg-3 mb-2">
          <label>Data Final</label>
          <DatePickerCustom
            name="dataFinal"
            selected={dataFinal}
            onChange={(value) => {
              setDataFinal(value);
            }}
          />
        </div>
      </div>

      <div className="row filter-bar2">
        {/* Botão Filtrar ------------------------------------------------------------- */}
        <div className="col-lg-2 mb-3 d-flex align-items-center">
          <button
            type="button"
            className="btn btn-secondary btn-sm btn-action"
            onClick={() => {
              setFilter({
                ...filter,
                dataInicial: dataInicial,
                dataFinal: dataFinal,
                representadaId: representadaSelecionada,
                lojaId: lojaSelecionada
              });
            }}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>
        </div>
        {/* Botão Remover Selecioados ------------------------------------------------- */}
        <div className="col-lg-3 mb-3 d-flex align-items-center">
          <button
            type="button"
            className="btn btn-danger btn-sm btn-action"
            onClick={() => {
              if (checkedItens?.find((value) => value === true)) {
                setShowModalMarcados(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faTrash} /> Remover Selecionados
          </button>
        </div>
      </div>

      {/* Lista Rascunhos --------------------------------------------------------------- */}
      <div className="table-responsive">
        <table className="table table-cadastro">
          <thead>
            <tr>
              <th>
                <Checkbox
                  value={checkAll}
                  onChange={(e) => {
                    if (checkAll) {
                      clearChecks();
                    } else {
                      checkAllItens();
                    }
                    setCheckAll(!checkAll);
                  }}
                />
              </th>
              <th>Número</th>
              <th>Loja</th>
              <th>Representada</th>
              <th>Data</th>
              <th>Tipo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {(rascunhos || []).map((item, index) => {
              const model = item.jsonModel ? JSON.parse(item.jsonModel) : null;
              const rep = model?.pedidoRepresentadas ? model.pedidoRepresentadas[0] : null;
              return (
                <tr key={index}>
                  <td>
                    <Checkbox
                      value={checkedItens[index]}
                      onChange={() => {
                        toggleCheck(index);
                      }}
                    />
                  </td>
                  <td>{item.pedidoRascunhoId}</td>
                  <td>{model?.lojaNomeFantasia}</td>
                  <td>{rep?.nomeFantasia}</td>
                  <td>{lib.getStringFromDate(item.data)}</td>
                  <td>{item?.pedidoTipo}</td>
                  <td className="action-btns">
                    {/* Botao Continuar ------------------------------------------- */}
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      title="Continuar"
                      onClick={() => {
                        navigate('/pedido/' + item.pedidoRascunhoId);
                      }}
                    >
                      <FontAwesomeIcon icon={faFileLines} />
                    </button>

                    {/* Botao Descartar ------------------------------------------- */}
                    <a href={item.urlArquivoPedido} target="_blank">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        title="Descartar"
                        onClick={() => {
                          setSelected(item);
                          setShowModalConfirmacao(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Paginacao --------------------------------------------------------------------- */}
      <TablePagination
        pageData={pageData}
        onChangePage={(page) => {
          getListaPedidoRascunho(page);
        }}
      />

      {/* Modal Confirmacao Descartar Rascunho ------------------------------------------ */}
      <ModalConfirmacao
        showModal={showModalConfirmacao}
        setShowModal={setShowModalConfirmacao}
        onClose={(result) => {
          if (result === 'yes') {
            removeRascunho(selected);
          }
        }}
      >
        <p>
          Deseja mesmo <b>descartar</b> o rascunho de pedido número{' '}
          <b>{selected?.pedidoRascunhoId}</b> ?
        </p>
      </ModalConfirmacao>

      {/* Modal Confirmacao Descartar Rascunho Checados --------------------------------- */}
      <ModalConfirmacao
        showModal={showModalMarcados}
        setShowModal={setShowModalMarcados}
        onClose={(result) => {
          if (result === 'yes') {
            removeRascunhosSelecionados();
          }
        }}
      >
        <p>
          Deseja mesmo <b>descartar</b> os rascunhos selecionados?
        </p>
      </ModalConfirmacao>
    </Layout>
  );
}
