import React from 'react';
import ModalCadastro from '../_base/modal-cadastro';
import FormGroupHeader from '../_base/form-group-header';
import Errors from '../_base/errors';
import TableCadastroComissao from '../table-cadastro-comissao';

import EquipeService from '../../services/equipe-service';

import lib from '../../helpers/lib';

import './styles.scss';
import ComissaoService from '../../services/comissao-service';

export default function ModalCadastroEquipeComissao(props) {
  const { showModal, setShowModal, onClose, comissao } = props;

  const [model, setModel] = React.useState({});
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    reset();

    getModel();
  }, [comissao]);

  function reset() {
    setErrors({});
    setModel({});
  }

  async function getModel() {
    if (comissao?.usuario?.usuarioId?.length && comissao?.equipe?.equipeId > 0) {
      window.showLoader();

      let response = await ComissaoService.getEquipeUsuarioModelAsync({
        equipeId: comissao.equipe.equipeId,
        usuarioId: comissao.usuario.usuarioId
      });
      let model = response.data.data;
      setModel(model);

      window.hideLoader();
    }
  }

  async function saveAsync() {
    window.showLoader();

    setErrors({});

    let response = await ComissaoService.saveEquipeUsuarioAsync(model);

    if (response.status === lib.statusCode.ok) {
      window.hideLoader();

      setModel({});

      setShowModal(false);
      return;
    }

    setErrors(response.data.errors);
    window.hideLoader();
  }

  return (
    <ModalCadastro
      showModal={showModal}
      setShowModal={setShowModal}
      headerText={`${comissao?.usuario?.nome || ''} - ${comissao?.equipe?.nome || ''} - COMISSÕES`}
    >
      <div className="modal-body">
        <TableCadastroComissao model={model} showValorBase={true} fullSize={true} />
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={saveAsync}>
          SALVAR
        </button>
      </div>
    </ModalCadastro>
  );
}
