import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import lib from '../../helpers/lib';
import ChamadoService from '../../services/chamado-service';
import './styles.scss';

export default function ContentChamado(props) {
  const [tipoEncerrado, setTipoEncerrado] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [mensagens, setMensagens] = React.useState([]);
  const [chamadoEncerrado, setChamadoEncerrado] = React.useState(false);

  React.useEffect(() => {
    setChamadoEncerrado(props.chamado.chamadoStatusId == 3);
    (async () => {
      getMensagens();
    })();
  }, [props.chamado]);

  async function getMensagens() {
    if (props.chamado == undefined || props.chamado == null || props.chamado.chamadoId == undefined)
      return;
    window.showLoader();

    let response = await ChamadoService.getMensagensAsync(props.chamado.chamadoId);

    setMensagens(response.data.data);

    window.hideLoader();
  }

  async function addMensagem(body) {
    if (body.mensagem == '') return;

    window.showLoader();

    let response = await ChamadoService.addMensagemAsync(body);
    var mensagem = response.data.data;
    setMensagens([...mensagens, mensagem]);

    window.hideLoader();
  }

  function onClickEnviar() {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const newMessage = {
      chamadoMensagemId: 0,
      chamadoId: props.chamado.chamadoId,
      mensagem: message
    };
    addMensagem(newMessage);
    setMessage('');
  }

  async function onClickFinalizar() {
    window.showLoader();
    var result = await ChamadoService.endChamadoAsync(props.chamado.chamadoId);
    if (result.data.data) {
      setChamadoEncerrado(true);
    }
    window.hideLoader();
    window.location.reload();
  }

  function somenteFinalizadosClick() {
    setTipoEncerrado(!tipoEncerrado);
    props.changeStatusChamado(!tipoEncerrado);
    setMensagens([]);
  }

  return (
    <div className="content-chamado">
      <div className="vh-100">
        <div className="row">
          <div className="col-8">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                style={{
                  backgroundColor: tipoEncerrado ? '#046100' : '#ffffff',
                  height: '20px'
                }}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                onClick={() => {
                  somenteFinalizadosClick();
                }}
              />
              <label className="form-check-label text-top" htmlFor="flexSwitchCheckChecked">
                VISUALIZAR CHAMADOS FINALIZADOS
              </label>
            </div>
          </div>
          <div className="col-4">
            <button type="button" className="btn btn-primary" onClick={props.abrirNovoClick}>
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: 'white', height: '15px', marginRight: '10px' }}
              />
              ABRIR NOVO CHAMADO
            </button>
          </div>
        </div>
        {props.chamado && props.chamado.chamadoId && (
          <div
            className="row  caixa-titulo d-flex align-items-center"
            style={{ marginTop: '25px' }}
          >
            <span className="text-titulo">
              CHAMADO {props.chamado.chamadoId} - {props.chamado.tipo}
            </span>
          </div>
        )}
        {props.chamado && props.chamado.chamadoId && (
          <div className="messages">
            <div key={0} className="message-owner">
              {props.chamado.mensagem}
              <div className="data-message">
                {lib.getStringFromDateMinute(props.chamado.dataCriacao)}
              </div>
            </div>
            {mensagens.map((mensagem) => (
              <div
                key={mensagem.chamadoMensagemId}
                className={
                  props.chamado.usuarioId == mensagem.usuarioId
                    ? 'message-owner'
                    : 'message-response'
                }
              >
                {mensagem.urlArquivo && mensagem.urlArquivo.length > 0 && (
                  <img
                    width={300}
                    height={300}
                    style={{ paddingBottom: '10px' }}
                    src={mensagem.urlArquivo}
                    alt="imagem"
                  />
                )}
                <br />
                {mensagem.mensagem}
                <div className="data-message">{lib.getStringFromDateMinute(mensagem.data)}</div>
              </div>
            ))}
          </div>
        )}
        {props.chamado && props.chamado.chamadoId && chamadoEncerrado == false && (
          <div className=" bottom-input">
            <textarea
              className=" form-control"
              name="message-input"
              id="message-input"
              rows={4}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              value={message}
            ></textarea>

            <div className="row d-flex flex-row-reverse" style={{ marginTop: '10px' }}>
              <button type="button" className="btn-enviar" onClick={onClickEnviar}>
                ENVIAR
              </button>
              <button type="button" className="btn-finalizar" onClick={onClickFinalizar}>
                FINALIZAR
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
