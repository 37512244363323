import ServiceClient from './service-client';
import { service_url } from './service-constants';

class RedeService {
  static async getSelectList() {
    try {
      var result = await ServiceClient.Get(service_url.rede_selectList);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListAllAsync() {
    try {
      var result = await ServiceClient.Get(service_url.rede_selectList_all);

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default RedeService;
