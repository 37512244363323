import { AxiosJsonClient, AxiosFormClient } from '../helpers/axios.config';

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

class ServiceClient {
  static async Get(url, model) {
    try {
      var result = await AxiosJsonClient.get(url, { params: model });

      return result;
    } catch (error) {
      console.log(error);

      return undefined;
    }
  }

  static async Post(url, model) {
    try {
      var result = await AxiosJsonClient.post(url, model);

      return result;
    } catch (error) {
      console.log(error);

      return undefined;
    }
  }

  static async FormPost(url, model) {
    try {
      const formData = jsonToFormData(model);
      console.log(formData);
      var result = await AxiosFormClient.post(url, formData);
      return result;
    } catch (error) {
      console.log(error);

      return undefined;
    }
  }
}

export default ServiceClient;
