import React, { useState, useEffect } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import './styles.scss';

export default function DashboardDonutCarteira({ source }) {
  const [donutChart, setDonutChart] = useState(null);

  useEffect(() => {
    if (!source?.clienteCarteira) return;

    const chartData = {
      series: [
        source.clienteCarteira.ativos,
        source.clienteCarteira.inativosAntigos,
        source.clienteCarteira.inativosRecentes
      ],
      options: {
        chart: {
          width: '100%', // Define a largura da pizza para ocupar o espaço disponível
          type: 'donut'
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(1) + '%';
          }
        },

        title: {
          text: 'CARTEIRA DE CLIENTES',
          align: 'center',
          margin: 1
        },

        legend: {
          position: 'bottom',
          offsetY: 1,
          markers: {
            width: 5,
            height: 5
          },
          itemMargin: {
            vertical: 5
          }
        },
        labels: [
          `${source.clienteCarteira.ativos} ATIVOS`,
          `${source.clienteCarteira.inativosRecentes} INATIVOS RECENTES`,
          `${source.clienteCarteira.inativosAntigos} INATIVOS ANTIGOS`
        ]
      }
    };

    setDonutChart(chartData);
  }, [source]);

  if (!donutChart) return null;

  const windowWidth = window.innerWidth;
  const chartHeight = windowWidth < 768 ? 250 : 350; // Define a altura com base na largura da tela

  return (
    <div className="dashboard-donut-chart">
      <div id="donut-chart">
        {donutChart ? (
          <ReactApexChart
            options={donutChart.options}
            series={donutChart.series}
            type="donut"
            height={350}
          />
        ) : null}
      </div>
    </div>
  );
}
