import React from 'react';
import { useState, useEffect } from 'react';

import './styles.scss';

export default function Accordion({ className, show, children, header }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  return (
    <div className={`custom-accordion-container ${className}`}>
      <button
        className={`custom-accordion-button ${visible ? 'custom-accordion-button-show' : 'custom-accordion-button-hide'}`}
        onClick={() => setVisible(!visible)}
      >
        {header}
      </button>

      {visible ? <div className="custom-accordion-body">{children}</div> : null}
    </div>
  );
}
