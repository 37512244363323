import ServiceClient from './service-client';

class DashboardService {
  static async getMetaContratanteAsync(filter) {
    try {
      let result = await ServiceClient.Post('/dashboard/get-meta-contratante', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getPositivacaoAsync(filter) {
    try {
      let result = await ServiceClient.Post('/dashboard/get-positivacao', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getResumoMensalRedeAsync(filter) {
    try {
      let result = await ServiceClient.Post('/dashboard/get-resumo-mensal-rede', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getDetalhamentoAsync(filter) {
    try {
      let result = await ServiceClient.Post('/dashboard/get-detalhamento', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getMetaEquipeAsync(filter) {
    try {
      let result = await ServiceClient.Post('/dashboard/get-meta-equipe', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getPositivacaoEquipeAsync(filter) {
    try {
      let result = await ServiceClient.Post('/dashboard/get-positivacao-equipe', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getResumoMensalEquipeVendedorAsync(filter) {
    try {
      let result = await ServiceClient.Post(
        '/dashboard/get-resumo-mensal-equipe-vendedor',
        filter || {}
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getResumoMensalEquipeRedeAsync(filter) {
    try {
      let result = await ServiceClient.Post(
        '/dashboard/get-resumo-mensal-equipe-rede',
        filter || {}
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListRepresentada(filter) {
    try {
      let result = await ServiceClient.Get('/dashboard/select-list-representada', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListRepresentadaPorVendedores(filter) {
    try {
      let result = await ServiceClient.Get(
        '/dashboard/select-list-representada-vendedores',
        filter || {}
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListRede(filter) {
    try {
      let result = await ServiceClient.Get('/dashboard/select-list-rede', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListRedePorVendedores(filter) {
    try {
      let result = await ServiceClient.Get('/dashboard/select-list-rede-vendedores', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListVendedor(filter) {
    try {
      let result = await ServiceClient.Get('/dashboard/select-list-vendedor', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListCategoria(filter) {
    try {
      let result = await ServiceClient.Get('/dashboard/select-list-categoria', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getSelectListProduto(filter) {
    try {
      let result = await ServiceClient.Get('/dashboard/select-list-produto', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getClientesAtivosAsync(filter) {
    try {
      const result = await ServiceClient.Post('/dashboard/clientes-ativos', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getCardsMensais(filter){
    try {
      const result = await ServiceClient.Post('/dashboard/get-cards-mensais', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getFaturamentoPorEquipeAsync(filter) {
    try {
      const result = await ServiceClient.Post(
        '/dashboard/faturamento-produtos-por-equipe',
        filter || {}
      );

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatoriosNovoDashboard(filter) {
    try {
      const result = await ServiceClient.Post('/dashboard/relatorios-novo-dashboard', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatoriosGraficos(filter) {
    try {
      const result = await ServiceClient.Post('/dashboard/relatorios-graficos', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatoriosCards(filter) {
    try {
      const result = await ServiceClient.Post('/dashboard/relatorios-cards-mensais', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getRelatoriosRedes(filter) {
    try {
      const result = await ServiceClient.Post('/dashboard/relatorios-redes', filter || {});

      return result;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}

export default DashboardService;
