import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEraser } from '@fortawesome/free-solid-svg-icons';

import SelectMulti from 'components/_base/multi-select';

import lib from 'helpers/lib';
import ProdutoService from 'services/produto-service';
import EquipeService from 'services/equipe-service';
import LojaService from 'services/loja-service';
import { LoggedUserContext } from 'contexts/logged-user-context';
import PedidoService from 'services/pedido-service';

const Filters = ({ filtro, setFiltro, cleanFiltro, onClick, isLoading }) => {
  const [userData, _] = useContext(LoggedUserContext);

  const estadosList = [
    { sigla: 'AC', valor: 'AC' },
    { sigla: 'AL', valor: 'AL' },
    { sigla: 'AP', valor: 'AP' },
    { sigla: 'AM', valor: 'AM' },
    { sigla: 'BA', valor: 'BA' },
    { sigla: 'CE', valor: 'CE' },
    { sigla: 'DF', valor: 'DF' },
    { sigla: 'ES', valor: 'ES' },
    { sigla: 'GO', valor: 'GO' },
    { sigla: 'MA', valor: 'MA' },
    { sigla: 'MS', valor: 'MS' },
    { sigla: 'MT', valor: 'MT' },
    { sigla: 'MG', valor: 'MG' },
    { sigla: 'PA', valor: 'PA' },
    { sigla: 'PB', valor: 'PB' },
    { sigla: 'PR', valor: 'PR' },
    { sigla: 'PE', valor: 'PE' },
    { sigla: 'PI', valor: 'PI' },
    { sigla: 'RJ', valor: 'RJ' },
    { sigla: 'RN', valor: 'RN' },
    { sigla: 'RS', valor: 'RS' },
    { sigla: 'RO', valor: 'RO' },
    { sigla: 'RR', valor: 'RR' },
    { sigla: 'SC', valor: 'SC' },
    { sigla: 'SP', valor: 'SP' },
    { sigla: 'SE', valor: 'SE' },
    { sigla: 'TO', valor: 'TO' }
  ];

  const [uf, setUf] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [listProdutos, setListProdutos] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [equipes, setEquipes] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [redes, setRedes] = useState([]);
  const [representadas, setRepresentadas] = useState([]);

  async function getCategorias() {
    let response = await ProdutoService.listCategoriasAsync();

    if (response.data) {
      setCategorias(response.data.data);
    }
  }

  async function getProdutos() {
    // let response = await ProdutoService.listPagedAsync();
    let representadasPayload = representadas.map((p) => p.value);
    let response = await PedidoService.listProdutosPorRepresentadasAsync(
      representadasPayload,
      1,
      100000
    );

    if (response.data) {
      setListProdutos(response.data.data);
      setProdutos(response.data.data);
    }
  }

  async function filterProdutos(categoriasIds) {
    let produtosFiltrados =
      categoriasIds.length > 0
        ? listProdutos.filter((p) => categoriasIds.includes(p.produtoCategoriaId))
        : listProdutos;
    setProdutos(produtosFiltrados);
  }

  async function getEquipes() {
    //let response = await EquipeService.listOwnerTeamsPagedAsync();
    let response = await EquipeService.listRepresentantesAsync();

    if (response.data) {
      setEquipes(response.data.data);
      filterVendedores(response.data.data);
    }
  }

  async function filterVendedores(equipesSelect) {
    let vendedores =
      equipesSelect.length > 0
        ? equipesSelect.flatMap((p) => p.equipeUsuarios)
        : equipes.flatMap((p) => p.equipeUsuarios);
    setVendedores(vendedores);
  }

  async function getRedes() {
    let response = await LojaService.listRedesAsync();

    if (response.data) {
      setRedes(response.data.data);
    }
  }

  async function getRepresentadas() {
    let response = await ProdutoService.selectListRepresentadasAsync();

    if (response.data) {
      setRepresentadas(response.data.data);
    }
  }

  function resetLists() {
    filterProdutos([]);
    filterVendedores([]);
  }

  useEffect(() => {
    getCategorias();
    getEquipes();
    getRedes();
    getRepresentadas();
  }, []);

  useEffect(() => {
    if (representadas.length > 0) {
      getProdutos();
    }
  }, [representadas]);

  //   representada mes ano uf
  // Equipe vendedor rede
  // Categoria produtos

  return (
    <div>
      <div className="row">
        {/* Representada ---------------------------------------------------------------------- */}
        <div className="col-lg-4 p-2">
          <label>Representada</label>
          <Select
            options={representadas}
            placeholder="Selecione..."
            className="select-form-control"
            value={filtro.representada}
            getOptionLabel={(option) => `${option.label}`}
            getOptionValue={(option) => `${option.value}`}
            onChange={(options) =>
              setFiltro({
                ...filtro,
                representada: options,
                representadaId: options.value
              })
            }
          />
        </div>

        {/* Mês ---------------------------------------------------------------------- */}
        <div className="col-lg-3 p-2">
          <label>Mês</label>
          <Select
            className="select-form-control"
            placeholder="Selecione..."
            value={lib.selectListMeses?.find((item) => item.value === filtro.mes)}
            options={lib.selectListMeses}
            isClearable={false}
            onChange={(item) => {
              setFiltro({ ...filtro, mes: item.value });
            }}
          />
        </div>

        {/* Ano ---------------------------------------------------------------------- */}
        <div className="col-lg-3 p-2">
          <label>Ano</label>
          <Select
            className="select-form-control"
            placeholder="Selecione..."
            value={lib.selectListAnos?.find((item) => item.value === filtro.ano)}
            options={lib.selectListAnos}
            isClearable={false}
            onChange={(item) => {
              setFiltro({ ...filtro, ano: item.value });
            }}
          />
        </div>

        {/* UF ---------------------------------------------------------------------- */}
        <div className="col-lg-2 p-2">
          <label>UF</label>
          <Select
            options={estadosList}
            className="select-form-control"
            placeholder="Selecione..."
            value={filtro.ufOption}
            getOptionLabel={(option) => `${option.sigla}`}
            getOptionValue={(option) => `${option.valor}`}
            onChange={(options) => setFiltro({ ...filtro, ufOption: options, uf: options.sigla })}
          />
        </div>

        {(userData.isSupervisor || userData.isContratante) && (
          <>
            {/* Equipe ---------------------------------------------------------------------- */}
            <div className="col-lg-4 p-2">
              <label>Equipe</label>
              <Select
                options={equipes}
                placeholder="Selecione..."
                value={filtro.equipe}
                className="select-form-control"
                getOptionLabel={(option) => `${option.nome}`}
                getOptionValue={(option) => `${option.equipeId}`}
                onChange={(options) => {
                  setFiltro({
                    ...filtro,
                    equipe: options,
                    equipeId: options.equipeId,
                    vendedor: null
                  });
                  filterVendedores([options]);
                }}
              />
            </div>

            {/* Vendedor ---------------------------------------------------------------------- */}
            <div className="col-lg-4 p-2">
              <label>Vendedor</label>
              <Select
                options={vendedores}
                placeholder="Selecione..."
                value={filtro.vendedor}
                className="select-form-control"
                getOptionLabel={(option) => `${option.nome}`}
                getOptionValue={(option) => `${option.usuarioId}`}
                onChange={(options) =>
                  setFiltro({ ...filtro, vendedor: options, vendedorId: options.usuarioId })
                }
              />
            </div>
          </>
        )}

        {/* Categoria ---------------------------------------------------------------------- */}
        <div className="col-lg-4 p-2">
          <label>Categoria</label>
          <Select
            options={categorias}
            placeholder="Selecione..."
            value={filtro.categoria}
            className="select-form-control"
            getOptionLabel={(option) => `${option.nome}`}
            getOptionValue={(option) => `${option.produtoCategoriaId}`}
            onChange={(options) => {
              setFiltro({
                ...filtro,
                categoria: options,
                categoriaId: options.produtoCategoriaId,
                produto: null
              });
              filterProdutos([options.produtoCategoriaId]);
            }}
          />
        </div>

        {/* Produto ---------------------------------------------------------------------- */}
        <div className="col-lg-4 p-2">
          <label>Produto</label>
          <Select
            options={produtos}
            placeholder="Selecione..."
            value={filtro.produto}
            className="select-form-control"
            getOptionLabel={(option) => `${option.nome}`}
            getOptionValue={(option) => `${option.produtoId}`}
            onChange={(options) =>
              setFiltro({ ...filtro, produto: options.produto, produtoId: options.produtoId })
            }
          />
        </div>

        {/* Rede ---------------------------------------------------------------------- */}
        <div className="col-lg-4 p-2">
          <label>Rede</label>
          <Select
            options={redes}
            placeholder="Selecione..."
            value={filtro.rede}
            className="select-form-control"
            getOptionLabel={(option) => `${option.nome}`}
            getOptionValue={(option) => `${option.redeId}`}
            onChange={(options) => setFiltro({ ...filtro, rede: options, redeId: options.redeId })}
          />
        </div>
        <div className="col-lg-4 p-2 row d-flex align-items-end justify-content-between">
          <div className="col-6 text-center">
            <button
              type="button"
              className="btn btn-secondary btn-action w-100"
              onClick={() => onClick()}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </button>
          </div>

          <div className="col-6 text-center">
            <button
              type="button"
              className="btn btn-light btn-action w-100"
              onClick={() => {
                cleanFiltro();
                resetLists();
              }}
            >
              <FontAwesomeIcon icon={faEraser} /> Limpar
            </button>
          </div>
        </div>
      </div>

      {/* <div className="row mt-3 justify-content-end">
        <div className="col-3 text-center">
          <button
            type="button"
            className="btn btn-secondary btn-action w-100"
            onClick={() => {
              cleanFiltro();
              resetLists();
            }}
          >
            <FontAwesomeIcon icon={faEraser} /> Limpar
          </button>
        </div>

        <div className="col-3 text-center">
          <button
            type="button"
            className="btn btn-secondary btn-action w-100"
            onClick={() => onClick()}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtrar
          </button>
        </div>
      </div> */}
      <hr />
    </div>
  );
};

export default Filters;
