import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import SelectMulti from '../../components/_base/multi-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEraser, faFilePdf } from '@fortawesome/free-solid-svg-icons';

import CardPanel2 from './components/big-number2';
import DasboardBarChart2 from './components/bar-chart2';
import DashboardDonutCarteira2 from './components/carteira';
import DashboardDonutPositivacao2 from './components/positivacao';
import DashboardBarAtivados2 from './components/Ativados2';
import TabelaRede2 from './components/tabela-rede2';

import DashboardService from '../../services/dashboard-service';
import RepresentadaService from '../../services/representada-service';
import PedidoService from '../../services/pedido-service';
import lib from '../../helpers/lib';

import './styles.scss';

export default function EquipeVendas() {
  const data = new Date();

  const [metaEquipe, setMetaEquipe] = useState({});
  const [positivacaoEquipe, setPositivacaoEquipe] = useState({});
  const [resumoMensalEquipeVendedor, setResumoMensalEquipeVendedor] = useState({});

  const [representadas, setRepresentadas] = useState();
  const [vendedores, setVendedores] = React.useState();

  const refFilter = useRef({});
  const [filter, setFilter] = useState({
    representadas: [],
    vendedores: [],
    ano: data.getFullYear(),
    mes: data.getMonth() + 1
  });

  useEffect(() => {
    getSelectListRepresentada();
    getSelectListVendedor();

    getRelatorio(false);
  }, []);

  async function getRelatorio(exibeloader) {
    if (exibeloader) showLoader();

    //atualizar os vendedores se houve alteração do periodo
    if (refFilter.current.ano !== filter.ano || refFilter.current.mes !== filter.mes) {
      getSelectListRepresentada();
      getSelectListVendedor();
    }

    refFilter.current = getFilterParams();

    await Promise.all([getMetaEquipe(), getPositivacaoEquipe(), getResumoMensalEquipeVendedor()]);

    hideLoader();
  }

  async function getSelectListRepresentada() {
    let representadas = await DashboardService.getSelectListRepresentada({
      ano: filter.ano,
      mes: filter.mes
    });
    if (representadas.data) setRepresentadas(representadas.data.data);
  }

  async function getSelectListVendedor() {
    let vendedores = await DashboardService.getSelectListVendedor({
      ano: filter.ano,
      mes: filter.mes
    });
    if (vendedores.data) setVendedores(vendedores.data.data);
  }

  async function getMetaEquipe() {
    let response = await DashboardService.getMetaEquipeAsync(refFilter.current);

    setMetaEquipe(response.data.data);
  }

  async function getPositivacaoEquipe() {
    let response = await DashboardService.getPositivacaoEquipeAsync(refFilter.current);

    setPositivacaoEquipe(response.data.data);
  }

  async function getResumoMensalEquipeVendedor() {
    let response = await DashboardService.getResumoMensalEquipeVendedorAsync(refFilter.current);

    setResumoMensalEquipeVendedor(response.data.data);
  }

  function getFilterParams() {
    return {
      representadaIds: filter.representadas.map((x) => x.value),
      vendedorIds: filter.vendedores.map((x) => x.value),
      mes: filter.mes,
      ano: filter.ano
    };
  }

  function limpar() {
    filter.representadas = [];
    filter.vendedores = [];
    filter.ano = data.getFullYear();
    filter.mes = data.getMonth() + 1;

    setFilter({ ...filter });

    getRelatorio(true);
  }

  return (
    <div className="dashboard-page">
      <div>
        <div className="row">
          {/* Representada ---------------------------------------------------------------------- */}
          <div className="col-lg-3" disabled>
            <label>Representada</label>
            <SelectMulti
              options={representadas}
              value={filter.representadas}
              onChange={(options) => setFilter({ ...filter, representadas: options })}
              //filterOptions={search}
            />
          </div>

          {/* Rede ---------------------------------------------------------------------- */}
          <div className="col-lg-3" disabled>
            <label>Vendedor</label>
            <SelectMulti
              options={vendedores}
              value={filter.vendedores}
              onChange={(options) => setFilter({ ...filter, vendedores: options })}
              //filterOptions={search}
            />
          </div>

          <div className="col-lg-3">
            <label>Mês</label>
            <Select
              className="select-form-control"
              value={lib.selectListMeses?.find((item) => item.value === filter.mes)}
              options={lib.selectListMeses}
              isClearable={false}
              onChange={(item) => {
                setFilter({ ...filter, mes: item.value });
              }}
            />
          </div>
          <div className="col-lg-3">
            <label>Ano</label>
            <Select
              className="select-form-control"
              value={lib.selectListAnos?.find((item) => item.value === filter.ano)}
              options={lib.selectListAnos}
              isClearable={false}
              onChange={(item) => {
                setFilter({ ...filter, ano: item.value });
              }}
            />
          </div>
        </div>
        <div className="row mt-3 justify-content-end">
          <div className="col-3 text-center">
            <button type="button" className="btn btn-secondary btn-action w-100" onClick={limpar}>
              <FontAwesomeIcon icon={faEraser} /> Limpar
            </button>
          </div>

          <div className="col-3 text-center">
            <button
              type="button"
              className="btn btn-secondary btn-action w-100"
              onClick={() => getRelatorio(true)}
            >
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <div className="row m-0">
          <div className="col-lg-12 shadow p-3 mb-5 bg-white rounded border-radius: 150px">
            <CardPanel2 source={metaEquipe} />
          </div>
        </div>

        <div className="row m-0">
          <div className="col-lg-12 px-5 shadow p-3 mb-5 bg-white rounded">
            <DasboardBarChart2 source={metaEquipe} />
          </div>
        </div>

        <div className="row m-0">
          <div className="col-lg-4 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
            <DashboardDonutCarteira2 source={positivacaoEquipe} />
          </div>
          <div className="col-lg-4 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
            <DashboardDonutPositivacao2 source={positivacaoEquipe} />
          </div>
          <div className="col-lg-4 pt-20 text-center shadow p-3 mb-5 bg-white rounded">
            <DashboardBarAtivados2 source={positivacaoEquipe} />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-lg-12">
            <TabelaRede2 source={resumoMensalEquipeVendedor} />
          </div>
        </div>
      </div>
    </div>
  );
}
