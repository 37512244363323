import React from 'react';
import './styles.scss';

export default function TabelaRede4({ source }) {
  // Exemplo de dados para as linhas da tabela
  if (!source?.representadas) return null;

  return (
    <div className="row m-0">
      <h1 className="h3 mb-2 text-gray-800">Detalhamento Faturamento</h1>
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary"></h6>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
            <thead>
              <tr>
                <th className="text-left">REPRESENTADA</th>
                <th className="text-left">CLIENTE</th>
                <th className="text-left">CATEGORIA</th>
                <th className="text-left">CÓDIGO</th>
                <th className="text-left">PRODUTO</th>
                <th className="text-left">PEDIDO</th>
                <th className="text-left">FATURADO</th>
                <th className="text-left">PARCIAL / AINDA NÃO FATURADO</th>
                <th className="text-left">RUPTURA</th>
                <th className="text-left">TOTAL NÃO FATURADO</th>
              </tr>
            </thead>
            <tbody>
              {source.representadas.map((representada, representadaIndex) => {
                return (
                  <>
                    {representada.clientes.map((cliente, clienteIndex) => {
                      return (
                        <>
                          {cliente.categorias.map((categoria, categoriaIndex) => {
                            return (
                              <>
                                {categoria.produtos.map((produto, produtoIndex) => {
                                  let categoriaRowSpan,
                                    clienteRowSpan,
                                    representadaRowSpan = 0;

                                  if (produtoIndex === 0) {
                                    categoriaRowSpan = categoria.produtos.length;

                                    if (categoriaIndex === 0) {
                                      clienteRowSpan =
                                        cliente.categorias.reduce(
                                          (acc, cat) => acc + cat.produtos.length,
                                          0
                                        ) + 1;

                                      if (clienteIndex === 0) {
                                        representadaRowSpan =
                                          representada.clientes.reduce(
                                            (acc, cli) =>
                                              acc +
                                              (cli.categorias.reduce(
                                                (acc, cat) => acc + cat.produtos.length,
                                                0
                                              ) +
                                                1),
                                            0
                                          ) + 1;
                                      }
                                    }
                                  }

                                  return (
                                    <tr key={produto.produtoId}>
                                      {representadaRowSpan > 0 ? (
                                        <td rowSpan={representadaRowSpan}>{representada.nome}</td>
                                      ) : null}
                                      {clienteRowSpan > 0 ? (
                                        <td rowSpan={clienteRowSpan}>{cliente.nome}</td>
                                      ) : null}
                                      {categoriaRowSpan > 0 ? (
                                        <td rowSpan={categoriaRowSpan}>{categoria.nome}</td>
                                      ) : null}
                                      <td>{produto.codigo}</td>
                                      <td>{produto.nome}</td>
                                      <td>
                                        {produto.total.valor.toLocaleString('pt-br', {
                                          style: 'currency',
                                          currency: 'BRL'
                                        })}
                                      </td>
                                      <td>
                                        {produto.total.valorFaturado.toLocaleString('pt-br', {
                                          style: 'currency',
                                          currency: 'BRL'
                                        })}
                                      </td>
                                      <td>
                                        {produto.total.valorNaoFaturado.toLocaleString('pt-br', {
                                          style: 'currency',
                                          currency: 'BRL'
                                        })}
                                      </td>
                                      <td>
                                        {produto.total.valorRuptura.toLocaleString('pt-br', {
                                          style: 'currency',
                                          currency: 'BRL'
                                        })}
                                      </td>
                                      <td>
                                        {produto.total.valorTotalNaoFaturado.toLocaleString(
                                          'pt-br',
                                          { style: 'currency', currency: 'BRL' }
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            );
                          })}

                          <tr>
                            <td colSpan={3}>TOTAL</td>
                            <td>
                              {cliente.total.valor.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL'
                              })}
                            </td>
                            <td>
                              {cliente.total.valorFaturado.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL'
                              })}
                            </td>
                            <td>
                              {cliente.total.valorNaoFaturado.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL'
                              })}
                            </td>
                            <td>
                              {cliente.total.valorRuptura.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL'
                              })}
                            </td>
                            <td>
                              {cliente.total.valorTotalNaoFaturado.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL'
                              })}
                            </td>
                          </tr>
                        </>
                      );
                    })}

                    <tr>
                      <td colSpan={4}>TOTAL</td>
                      <td>
                        {representada.total.valor.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </td>
                      <td>
                        {representada.total.valorFaturado.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </td>
                      <td>
                        {representada.total.valorNaoFaturado.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </td>
                      <td>
                        {representada.total.valorRuptura.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </td>
                      <td>
                        {representada.total.valorTotalNaoFaturado.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </td>
                    </tr>
                  </>
                );
              })}

              <tr>
                <td colSpan={5}>TOTAL</td>
                <td>
                  {source.total.valor.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </td>
                <td>
                  {source.total.valorFaturado.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </td>
                <td>
                  {source.total.valorNaoFaturado.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </td>
                <td>
                  {source.total.valorRuptura.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </td>
                <td>
                  {source.total.valorTotalNaoFaturado.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
